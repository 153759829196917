import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { CCard, CCardBody, CCardHeader, CLink, CButtonGroup, CSpinner } from '@coreui/react'

const axios = require("axios");

export class Devices extends Component {
    static displayName = Devices.name;

    constructor(props) {
        super(props);
        this.state = {
            pagemodels: [],
            isSessionExpired: false,
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        this.populateData();
    }

    componentDidMount() {
        this.populateData();
    }


    async populateData() {
        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        const url = 'api/devices/';

        axios.get(url, {
            headers: headers
        })
            .then((response) => {

                this.setState({
                    loading: false,
                    pagemodels: response.data
                });
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 401) {
                    window.history.replaceState(null, null, "/Identity/Account/Login");
                    window.location.replace("/Identity/Account/Login");
                } else {
                    console.log(error);
                }
            });

    }


    static renderTable(pagemodels) { 
        return (    
            <table className='table table-bordered table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr className="bg-dark">
                        <th>#</th>
                        <th>Serial No.</th>
                        <th>Model</th>
                        <th>Title</th>
                        <th>Site</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                    {pagemodels.map((item, index) =>
                        <tr className="text-white" key={item.sysId}>
                            <td className="text-white">{index + 1}</td>
                            <td className="text-white">
                                <CLink className="alink" to={"./devices/" + item.sysId}>
                                    {item.serialno}
                                </CLink>
                            </td>
                            <td className="text-white">{item.model}</td>
                            <td className="text-white">{item.title}</td>
                            <td className="text-white">
                                <CLink to={"./sites/" + item.siteId} className="">
                                    {item.site}
                                </CLink>
                            </td>
                            <td>
                                <CButtonGroup role="group" aria-label="Basic mixed styles example">
                                    <CLink className="btn btn-outline-danger" to={"/delete/device/" + item.sysId}> Delete </CLink>
                                </CButtonGroup>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        const { isSessionExpired } = this.state;
        let contents = this.state.loading
            ? <div><em>Loading...</em>  <CSpinner color="warning" size="xl" /></div>
            : Devices.renderTable(this.state.pagemodels);

        return (
            isSessionExpired ? <div>Session expired</div>
                :
            <div>
                <CCard className="">
                        <CCardHeader>
                            <h4 id="traffic" className="card-title mb-0">Devices  <CLink className="float-right btn btn-outline-warning" to="/create/device"> Add new device </CLink></h4>
                    </CCardHeader>
                    <CCardBody>
                        {contents}
                    </CCardBody>
                </CCard>
            </div>
        );
    }
}