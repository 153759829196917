import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCol,
    CRow,
    CImg,
    CForm,
    CFormGroup,
    CLabel,
    CCardHeader,
    CSwitch,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupPrepend,
    CInputGroupText,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CCollapse,
    CSelect, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CSpinner, CLink
} from "@coreui/react";
import RangeSlider from "react-bootstrap-range-slider";
import CIcon from '@coreui/icons-react'
import styles from "../styles/style.module.css";

import { GuageTemperature, GuageHumidity } from "../charts/AGauge";
/*import ScheduleProgram from "./components/ScheduleProgram"*/
import imgDewpoint from "../../assets/imgs/dewpoint_on.png";

import icon_h_high from "../../assets/imgs/icon_h_high.png";
import icon_h_low from "../../assets/imgs/icon_h_low.png";
import icon_t_high from "../../assets/imgs/icon_t_high.png";
import icon_t_low from "../../assets/imgs/icon_t_low.png";

/*import icon_onoff from "../../assets/imgs/icon_onoff.png";*/
import icon_magicwand from "../../assets/imgs/icon_magicwand.png";
import icon_pause from "../../assets/imgs/icon_pause.png";
import icon_play from "../../assets/imgs/icon_play.png";
import icon_reload from "../../assets/imgs/icon_reload.png";
import icon_wallclock from "../../assets/imgs/icon_wallclock.png";

import ButtonMistmaker from "./components/ButtonMistmaker";
import ButtonVentilator from "./components/ButtonVentilator";
import ButtonSmartPlug from "./components/ButtonSmartPlug";
import ButtonLighting from "./components/ButtonLighting";

import imgHeaterON from "../../assets/imgs/icon_heater.png";

import BottonChart from "../../assets/imgs/btnchart.png";


const axios = require("axios");
const BASE_URL = process.env.REACT_APP_API_SERVER;

const radius = 130;
const width = 600;
const guagewidth = width / 4.5;
const lowerBound = 0;
const upperBound = 50;
const stepbutton = 0.5;

function dewpoint(temp, humi) {
    let ans = Number(
        temp -
        (14.55 + 0.114 * temp) * (1 - 0.01 * humi) -
        Math.pow((2.5 + 0.007 * temp) * (1 - 0.01 * humi), 3) -
        (15.9 + 0.117 * temp) * Math.pow(1 - 0.01 * humi, 14)
    ).toFixed(1);
    if (ans < 0) {
        ans = 0;
    }
    return ans;
}

function padLZero(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

var mqtt = require("mqtt");

const MQTT_SERVER = process.env.REACT_APP_MQTT_SERVER;
let MQTT_TOPIC = "";
let client;


const pmodevalue = [
    { value: 0, label: "MODE 0" },
    { value: 1, label: "MODE 1" },
    { value: 2, label: "MODE 2" },
    { value: 3, label: "MODE 3" },
];

const phourvalue = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" }];

const timehourvalue = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
];

const timeminvalue = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
    { value: 32, label: "32" },
    { value: 33, label: "33" },
    { value: 34, label: "34" },
    { value: 35, label: "35" },
    { value: 36, label: "36" },
    { value: 37, label: "37" },
    { value: 38, label: "38" },
    { value: 39, label: "39" },
    { value: 40, label: "40" },
    { value: 41, label: "41" },
    { value: 42, label: "42" },
    { value: 43, label: "43" },
    { value: 44, label: "44" },
    { value: 45, label: "45" },
    { value: 46, label: "46" },
    { value: 47, label: "47" },
    { value: 48, label: "48" },
    { value: 49, label: "49" },
    { value: 50, label: "50" },
    { value: 51, label: "51" },
    { value: 52, label: "52" },
    { value: 53, label: "53" },
    { value: 54, label: "54" },
    { value: 55, label: "55" },
    { value: 56, label: "56" },
    { value: 57, label: "57" },
    { value: 58, label: "58" },
    { value: 59, label: "59" },
];

const timesecvalue = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
    { value: 32, label: "32" },
    { value: 33, label: "33" },
    { value: 34, label: "34" },
    { value: 35, label: "35" },
    { value: 36, label: "36" },
    { value: 37, label: "37" },
    { value: 38, label: "38" },
    { value: 39, label: "39" },
    { value: 40, label: "40" },
    { value: 41, label: "41" },
    { value: 42, label: "42" },
    { value: 43, label: "43" },
    { value: 44, label: "44" },
    { value: 45, label: "45" },
    { value: 46, label: "46" },
    { value: 47, label: "47" },
    { value: 48, label: "48" },
    { value: 49, label: "49" },
    { value: 50, label: "50" },
    { value: 51, label: "51" },
    { value: 52, label: "52" },
    { value: 53, label: "53" },
    { value: 54, label: "54" },
    { value: 55, label: "55" },
    { value: 56, label: "56" },
    { value: 57, label: "57" },
    { value: 58, label: "58" },
    { value: 59, label: "59" },
];




export class Site extends Component {
    static displayName = Site.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            isLoading: false,
            weathermodel: {
                coord: {},
                weather: [],
                base: "",
                main: {},
                wind: {},
                name: "",
            },
            tabselect: -1,
            connecting: true,
            response: false,
            response2: false,
            endpoint: "http://localhost:5432",
            floor: "",
            TEMP: 0.0,
            HUMI: 0.0,
            RL1_MODE: -1,
            RL1_T_SET: 0.0,
            RL1_T_LOW: 0.0,
            RL1_H_HI: 0.0,
            RL1_H_LOW: 0.0,
            RL1_TIME_ON: 0.0,
            RL1_TIME_OFF: 0.0,
            RL1_ST_RL: 0,
            RL2_MODE: -1,
            RL2_H_HI: 0.0,
            RL2_H_LOW: 0.0,
            RL2_TIME_ON: 0.0,
            RL2_TIME_OFF: 0.0,
            RL2_ST_RL: 0,
            RL3_MODE: -1,
            RL3_TIME_ON: 0.0,
            RL3_TIME_OFF: 0.0,
            RL3_ST_RL: 0,
            mode_auto: false,
            mode_loop: false,
            mode_schedule: true,

            RL1_T_LOW_SET: 0,
            RL1_T_SET_SET: 0,
            RL1_H_LOW_SET: 0,
            RL1_H_HI_SET: 0,

            RL1_TIME_ON_SET: 0,
            RL1_TIME_ON_SET_H: 0,
            RL1_TIME_ON_SET_M: 0,
            RL1_TIME_ON_SET_S: 0,
            RL1_TIME_OFF_SET: 0,
            RL1_TIME_OFF_SET_H: 0,
            RL1_TIME_OFF_SET_M: 0,
            RL1_TIME_OFF_SET_S: 0,

            RL2_H_HI_SET: 0,
            RL2_H_LOW_SET: 0,

            RL2_TIME_ON_SET: 0,
            RL2_TIME_ON_SET_H: 0,
            RL2_TIME_ON_SET_M: 0,
            RL2_TIME_ON_SET_S: 0,
            RL2_TIME_OFF_SET: 0,
            RL2_TIME_OFF_SET_H: 0,
            RL2_TIME_OFF_SET_M: 0,
            RL2_TIME_OFF_SET_S: 0,
            RL3_TIME_ON_SET: 0,
            RL3_TIME_ON_SET_H: 0,
            RL3_TIME_ON_SET_M: 0,
            RL3_TIME_ON_SET_S: 0,
            RL3_TIME_OFF_SET: 0,
            RL3_TIME_OFF_SET_H: 0,
            RL3_TIME_OFF_SET_M: 0,
            RL3_TIME_OFF_SET_S: 0,
            RL1_T_LOW_SET_VISIBLE: false,
            RL1_T_SET_SET_VISIBLE: false,
            RL1_H_LOW_SET_VISIBLE: false,
            RL1_H_HI_SET_VISIBLE: false,
            RL1_TIME_ON_SET_VISIBLE: false,
            RL1_TIME_OFF_SET_VISIBLE: false,
            RL2_H_HI_SET_VISIBLE: false,
            RL2_H_LOW_SET_VISIBLE: false,
            RL2_TIME_ON_SET_VISIBLE: false,
            RL2_TIME_OFF_SET_VISIBLE: false,
            RL3_TIME_ON_SET_VISIBLE: false,
            RL3_TIME_OFF_SET_VISIBLE: false,
            RL1_SCH: 0,
            RL2_SCH: 0,
            RL3_SCH: 0,
            DEVICES: [],
            DEVICE_SSID: "",
            DEVICE_NAME: "",
            DEVICE_MODEL: "",
            DEVICE_TOKEN: "",
            DEVICE_DESCRIPTION: "",
            DEVICE_R1_TITLE_PRIMARY: "",
            DEVICE_R1_TITLE_SECONDARY: "",
            DEVICE_R2_TITLE_PRIMARY: "",
            DEVICE_R2_TITLE_SECONDARY: "",
            DEVICE_R3_TITLE_PRIMARY: "",
            DEVICE_R3_TITLE_SECONDARY: "",
            siteinfo_title: "",
            siteinfo_id: "",
            siteinfo_location: "",
            siteinfo_lat: "",
            siteinfo_lon: "",


            RL1_P1_S_HH: 0,
            RL1_P1_S_MM: 0,
            RL1_P1_E_HH: 0,
            RL1_P1_E_MM: 0,

            RL1_P2_S_HH: 0,
            RL1_P2_S_MM: 0,
            RL1_P2_E_HH: 0,
            RL1_P2_E_MM: 0,

            RL1_P3_S_HH: 0,
            RL1_P3_S_MM: 0,
            RL1_P3_E_HH: 0,
            RL1_P3_E_MM: 0,

            RL1_P4_S_HH: 0,
            RL1_P4_S_MM: 0,
            RL1_P4_E_HH: 0,
            RL1_P4_E_MM: 0,

            RL1_P5_S_HH: 0,
            RL1_P5_S_MM: 0,
            RL1_P5_E_HH: 0,
            RL1_P5_E_MM: 0,

            RL1_P6_S_HH: 0,
            RL1_P6_S_MM: 0,
            RL1_P6_E_HH: 0,
            RL1_P6_E_MM: 0,

            RL1_P7_S_HH: 0,
            RL1_P7_S_MM: 0,
            RL1_P7_E_HH: 0,
            RL1_P7_E_MM: 0,

            RL1_P8_S_HH: 0,
            RL1_P8_S_MM: 0,
            RL1_P8_E_HH: 0,
            RL1_P8_E_MM: 0,

            RL1_P9_S_HH: 0,
            RL1_P9_S_MM: 0,
            RL1_P9_E_HH: 0,
            RL1_P9_E_MM: 0,

            RL1_P10_S_HH: 0,
            RL1_P10_S_MM: 0,
            RL1_P10_E_HH: 0,
            RL1_P10_E_MM: 0,

            RL1_P11_S_HH: 0,
            RL1_P11_S_MM: 0,
            RL1_P11_E_HH: 0,
            RL1_P11_E_MM: 0,

            RL1_P12_S_HH: 0,
            RL1_P12_S_MM: 0,
            RL1_P12_E_HH: 0,
            RL1_P12_E_MM: 0,

            RL1_P13_S_HH: 0,
            RL1_P13_S_MM: 0,
            RL1_P13_E_HH: 0,
            RL1_P13_E_MM: 0,

            RL1_P14_S_HH: 0,
            RL1_P14_S_MM: 0,
            RL1_P14_E_HH: 0,
            RL1_P14_E_MM: 0,

            RL1_P15_S_HH: 0,
            RL1_P15_S_MM: 0,
            RL1_P15_E_HH: 0,
            RL1_P15_E_MM: 0,


            RL2_P1_S_HH: 0,
            RL2_P1_S_MM: 0,
            RL2_P1_E_HH: 0,
            RL2_P1_E_MM: 0,

            RL2_P2_S_HH: 0,
            RL2_P2_S_MM: 0,
            RL2_P2_E_HH: 0,
            RL2_P2_E_MM: 0,

            RL2_P3_S_HH: 0,
            RL2_P3_S_MM: 0,
            RL2_P3_E_HH: 0,
            RL2_P3_E_MM: 0,

            RL2_P4_S_HH: 0,
            RL2_P4_S_MM: 0,
            RL2_P4_E_HH: 0,
            RL2_P4_E_MM: 0,

            RL2_P5_S_HH: 0,
            RL2_P5_S_MM: 0,
            RL2_P5_E_HH: 0,
            RL2_P5_E_MM: 0,

            RL2_P6_S_HH: 0,
            RL2_P6_S_MM: 0,
            RL2_P6_E_HH: 0,
            RL2_P6_E_MM: 0,

            RL2_P7_S_HH: 0,
            RL2_P7_S_MM: 0,
            RL2_P7_E_HH: 0,
            RL2_P7_E_MM: 0,

            RL2_P8_S_HH: 0,
            RL2_P8_S_MM: 0,
            RL2_P8_E_HH: 0,
            RL2_P8_E_MM: 0,

            RL2_P9_S_HH: 0,
            RL2_P9_S_MM: 0,
            RL2_P9_E_HH: 0,
            RL2_P9_E_MM: 0,

            RL2_P10_S_HH: 0,
            RL2_P10_S_MM: 0,
            RL2_P10_E_HH: 0,
            RL2_P10_E_MM: 0,

            RL2_P11_S_HH: 0,
            RL2_P11_S_MM: 0,
            RL2_P11_E_HH: 0,
            RL2_P11_E_MM: 0,

            RL2_P12_S_HH: 0,
            RL2_P12_S_MM: 0,
            RL2_P12_E_HH: 0,
            RL2_P12_E_MM: 0,

            RL2_P13_S_HH: 0,
            RL2_P13_S_MM: 0,
            RL2_P13_E_HH: 0,
            RL2_P13_E_MM: 0,

            RL2_P14_S_HH: 0,
            RL2_P14_S_MM: 0,
            RL2_P14_E_HH: 0,
            RL2_P14_E_MM: 0,

            RL2_P15_S_HH: 0,
            RL2_P15_S_MM: 0,
            RL2_P15_E_HH: 0,
            RL2_P15_E_MM: 0,


            RL3_P1_S_HH: 0,
            RL3_P1_S_MM: 0,
            RL3_P1_E_HH: 0,
            RL3_P1_E_MM: 0,

            RL3_P2_S_HH: 0,
            RL3_P2_S_MM: 0,
            RL3_P2_E_HH: 0,
            RL3_P2_E_MM: 0,

            RL3_P3_S_HH: 0,
            RL3_P3_S_MM: 0,
            RL3_P3_E_HH: 0,
            RL3_P3_E_MM: 0,

            RL3_P4_S_HH: 0,
            RL3_P4_S_MM: 0,
            RL3_P4_E_HH: 0,
            RL3_P4_E_MM: 0,

            RL3_P5_S_HH: 0,
            RL3_P5_S_MM: 0,
            RL3_P5_E_HH: 0,
            RL3_P5_E_MM: 0,

            RL3_P6_S_HH: 0,
            RL3_P6_S_MM: 0,
            RL3_P6_E_HH: 0,
            RL3_P6_E_MM: 0,

            RL3_P7_S_HH: 0,
            RL3_P7_S_MM: 0,
            RL3_P7_E_HH: 0,
            RL3_P7_E_MM: 0,

            RL3_P8_S_HH: 0,
            RL3_P8_S_MM: 0,
            RL3_P8_E_HH: 0,
            RL3_P8_E_MM: 0,

            RL3_P9_S_HH: 0,
            RL3_P9_S_MM: 0,
            RL3_P9_E_HH: 0,
            RL3_P9_E_MM: 0,

            RL3_P10_S_HH: 0,
            RL3_P10_S_MM: 0,
            RL3_P10_E_HH: 0,
            RL3_P10_E_MM: 0,

            RL3_P11_S_HH: 0,
            RL3_P11_S_MM: 0,
            RL3_P11_E_HH: 0,
            RL3_P11_E_MM: 0,

            RL3_P12_S_HH: 0,
            RL3_P12_S_MM: 0,
            RL3_P12_E_HH: 0,
            RL3_P12_E_MM: 0,

            RL3_P13_S_HH: 0,
            RL3_P13_S_MM: 0,
            RL3_P13_E_HH: 0,
            RL3_P13_E_MM: 0,

            RL3_P14_S_HH: 0,
            RL3_P14_S_MM: 0,
            RL3_P14_E_HH: 0,
            RL3_P14_E_MM: 0,

            RL3_P15_S_HH: 0,
            RL3_P15_S_MM: 0,
            RL3_P15_E_HH: 0,
            RL3_P15_E_MM: 0,

            PX_S_HH: 0,
            PX_S_MM: 0,
            PX_E_HH: 0,
            PX_E_MM: 0,

            RL1_P1_VISIBLE: false,
            RL1_P2_VISIBLE: false,
            RL1_P3_VISIBLE: false,
            RL1_P4_VISIBLE: false,
            RL1_P5_VISIBLE: false,
            RL1_P6_VISIBLE: false,
            RL1_P7_VISIBLE: false,
            RL1_P8_VISIBLE: false,
            RL1_P9_VISIBLE: false,
            RL1_P10_VISIBLE: false,
            RL1_P11_VISIBLE: false,
            RL1_P12_VISIBLE: false,
            RL1_P13_VISIBLE: false,
            RL1_P14_VISIBLE: false,
            RL1_P15_VISIBLE: false,

            RL2_P1_VISIBLE: false,
            RL2_P2_VISIBLE: false,
            RL2_P3_VISIBLE: false,
            RL2_P4_VISIBLE: false,
            RL2_P5_VISIBLE: false,
            RL2_P6_VISIBLE: false,
            RL2_P7_VISIBLE: false,
            RL2_P8_VISIBLE: false,
            RL2_P9_VISIBLE: false,
            RL2_P10_VISIBLE: false,
            RL2_P11_VISIBLE: false,
            RL2_P12_VISIBLE: false,
            RL2_P13_VISIBLE: false,
            RL2_P14_VISIBLE: false,
            RL2_P15_VISIBLE: false,

            RL3_P1_VISIBLE: false,
            RL3_P2_VISIBLE: false,
            RL3_P3_VISIBLE: false,
            RL3_P4_VISIBLE: false,
            RL3_P5_VISIBLE: false,
            RL3_P6_VISIBLE: false,
            RL3_P7_VISIBLE: false,
            RL3_P8_VISIBLE: false,
            RL3_P9_VISIBLE: false,
            RL3_P10_VISIBLE: false,
            RL3_P11_VISIBLE: false,
            RL3_P12_VISIBLE: false,
            RL3_P13_VISIBLE: false,
            RL3_P14_VISIBLE: false,
            RL3_P15_VISIBLE: false,


        };
    }


    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        clearInterval(this.interval);
        if (client) {
            client.end(() => {
            });
        }
    }

    async populateData() {
        const token = await authService.getAccessToken();

        const url = 'api/sites/' + this.props.match.params.id;

        const response = await fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        const data = await response.json();

        //console.log(data);

        await this.setState({
            loading: false,
            siteinfo_title: data.title,
            siteinfo_id: data.sys_id,
            siteinfo_location: data.description,
            siteinfo_lat: data.latitude,
            siteinfo_lon: data.longitude,
        },
            () => {

                axios({
                    method: "get",
                    url:
                        "https://api.openweathermap.org/data/2.5/weather?lat=" +
                        this.state.siteinfo_lat +
                        "&lon=" +
                        this.state.siteinfo_lon +
                        "&appid=18b2433c8783f6958a853092eac82750&units=metric&lang=en",
                })
                    .then((response) => {
                        // console.log("response: ", response.data);
                        this.setState({
                            weathermodel: {
                                main: response.data.main,
                                weather: response.data.weather[0],
                                coord: response.data.coord,
                                name: response.data.name,
                                wind: response.data.wind,
                            },
                        });
                        //console.log("viewmodel: ", this.state.viewmodel);
                    })
                    .catch((err) => {
                        console.error(err);
                    });


                //get device
                const urldevice = 'api/sites/device/' + this.props.match.params.id;
                //console.log(urldevice);


                axios({
                    method: "get",
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    url: urldevice,
                })
                    .then((resdevice) => {


                        this.setState(
                            {
                                DEVICES: resdevice.data,
                            },
                            () => {

                                if (this.state.DEVICES.length > 0) {

                                    this.setState({
                                        floor: this.state.DEVICES[0].sysId,
                                        DEVICE_SSID: this.state.DEVICES[0].sysId,
                                        DEVICE_NAME: this.state.DEVICES[0].title,
                                        DEVICE_MODEL: this.state.DEVICES[0].model,
                                        DEVICE_TOKEN: this.state.DEVICES[0].devicetoken,
                                        DEVICE_DESCRIPTION: this.state.DEVICES[0].description,

                                        DEVICE_R1_TITLE_PRIMARY: this.state.DEVICES[0].r1,
                                        DEVICE_R1_TITLE_SECONDARY: this.state.DEVICES[0].r1s,
                                        DEVICE_R2_TITLE_PRIMARY: this.state.DEVICES[0].r2,
                                        DEVICE_R2_TITLE_SECONDARY: this.state.DEVICES[0].r2s,
                                        DEVICE_R3_TITLE_PRIMARY: this.state.DEVICES[0].r3,
                                        DEVICE_R3_TITLE_SECONDARY: this.state.DEVICES[0].r3s,
                                    });

                                    if (MQTT_TOPIC === "") {
                                        MQTT_TOPIC =
                                            "SERVER/" +
                                            this.state.DEVICES[0].model +
                                            "/" +
                                            this.state.DEVICES[0].devicetoken;
                                    }

                                    client.subscribe(MQTT_TOPIC, { qos: 1 });
                                }
                            }
                        );
                    })
                    .catch((err) => {
                        console.error(err);
                    });




                //const responsedevice = fetch(urldevice, {
                //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                //});

                //const datadevice = responsedevice.json();

                //console.log(datadevice);


            }
        );
    }

    async componentDidMount() {
        // const { endpoint } = this.state;

        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        MQTT_TOPIC = "";

        this.populateData();
             
        client = mqtt.connect(MQTT_SERVER, {
            clientId:
                "mqttjs_" +
                Math.random()
                    .toString(16)
                    .substr(2, 8),
            protocolId: "MQIsdp",
            protocolVersion: 3,
            connectTimeout: 1000,
            debug: true,
            onSuccess: this.onConnect,
        });

        client.on("connect", function () {
            console.log("connected : " + client.connected);
        });

        client.on("error", function (error) {
            console.log("Can't connect : " + error);
            process.exit(1);
        });

        //handle incoming messages
        client.on("message", (topic, message, packet) => {
            //console.log(topic);

            if (message.includes('SENSOR')) {

                let r;
                try {
                    r = JSON.parse(message);
                    this.setState(
                        {
                            modal: false,
                            TEMP: r.SENSOR.TEMP,
                            HUMI: r.SENSOR.HUMI,
                            RL1_ST_RL: r.RL1.ST_RL,
                            RL1_MODE: r.RL1.MODE,
                            RL1_T_SET: r.RL1.T_SET,
                            RL1_T_LOW: r.RL1.T_LOW,
                            RL1_H_HI: r.RL1.H_HI,
                            RL1_H_LOW: r.RL1.H_LOW,
                            RL1_TIME_ON: r.RL1.TIME_ON,
                            RL1_TIME_OFF: r.RL1.TIME_OFF,
                            RL1_SCH: r.RL1.SCH,
                            RL2_ST_RL: r.RL2.ST_RL,
                            RL2_MODE: r.RL2.MODE,
                            RL2_T_HI: r.RL2.T_HI,
                            RL2_T_LOW: r.RL2.T_LOW,
                            RL2_H_HI: r.RL2.H_HI,
                            RL2_H_LOW: r.RL2.H_LOW,
                            RL2_TIME_ON: r.RL2.TIME_ON,
                            RL2_TIME_OFF: r.RL2.TIME_OFF,
                            RL2_SCH: r.RL2.SCH,
                            RL3_ST_RL: r.RL3.ST_RL,
                            RL3_MODE: r.RL3.MODE,
                            RL3_TIME_ON: r.RL3.TIME_ON,
                            RL3_TIME_OFF: r.RL3.TIME_OFF,
                            RL3_SCH: r.RL3.SCH,
                        },
                        () => {
                            //this.renderDataTable(res);
                        }
                    );

                    if (this.state.connecting) {
                        this.setState(
                            {
                                connecting: false,
                            },
                            () => {
                                //this.renderDataTable(res);
                            }
                        );
                    }
                } catch (e) {
                    console.log(e.toString());
                }
            } else if (message.includes('P1')) {
                let r;
                try {
                    r = JSON.parse(message);

                    if (r.RL == 1) {

                        this.setState(
                            {

                                RL1_P1_S_HH: r.P1[0],
                                RL1_P1_S_MM: r.P1[1],
                                RL1_P1_E_HH: r.P1[2],
                                RL1_P1_E_MM: r.P1[3],

                                RL1_P2_S_HH: r.P2[0],
                                RL1_P2_S_MM: r.P2[1],
                                RL1_P2_E_HH: r.P2[2],
                                RL1_P2_E_MM: r.P2[3],

                                RL1_P3_S_HH: r.P3[0],
                                RL1_P3_S_MM: r.P3[1],
                                RL1_P3_E_HH: r.P3[2],
                                RL1_P3_E_MM: r.P3[3],

                                RL1_P4_S_HH: r.P4[0],
                                RL1_P4_S_MM: r.P4[1],
                                RL1_P4_E_HH: r.P4[2],
                                RL1_P4_E_MM: r.P4[3],

                                RL1_P5_S_HH: r.P5[0],
                                RL1_P5_S_MM: r.P5[1],
                                RL1_P5_E_HH: r.P5[2],
                                RL1_P5_E_MM: r.P5[3],

                                RL1_P6_S_HH: r.P6[0],
                                RL1_P6_S_MM: r.P6[1],
                                RL1_P6_E_HH: r.P6[2],
                                RL1_P6_E_MM: r.P6[3],

                                RL1_P7_S_HH: r.P7[0],
                                RL1_P7_S_MM: r.P7[1],
                                RL1_P7_E_HH: r.P7[2],
                                RL1_P7_E_MM: r.P7[3],

                                RL1_P8_S_HH: r.P8[0],
                                RL1_P8_S_MM: r.P8[1],
                                RL1_P8_E_HH: r.P8[2],
                                RL1_P8_E_MM: r.P8[3],

                                RL1_P9_S_HH: r.P9[0],
                                RL1_P9_S_MM: r.P9[1],
                                RL1_P9_E_HH: r.P9[2],
                                RL1_P9_E_MM: r.P9[3],

                                RL1_P10_S_HH: r.P10[0],
                                RL1_P10_S_MM: r.P10[1],
                                RL1_P10_E_HH: r.P10[2],
                                RL1_P10_E_MM: r.P10[3],

                                RL1_P11_S_HH: r.P11[0],
                                RL1_P11_S_MM: r.P11[1],
                                RL1_P11_E_HH: r.P11[2],
                                RL1_P11_E_MM: r.P11[3],

                                RL1_P12_S_HH: r.P12[0],
                                RL1_P12_S_MM: r.P12[1],
                                RL1_P12_E_HH: r.P12[2],
                                RL1_P12_E_MM: r.P12[3],

                                RL1_P13_S_HH: r.P13[0],
                                RL1_P13_S_MM: r.P13[1],
                                RL1_P13_E_HH: r.P13[2],
                                RL1_P13_E_MM: r.P13[3],

                                RL1_P14_S_HH: r.P14[0],
                                RL1_P14_S_MM: r.P14[1],
                                RL1_P14_E_HH: r.P14[2],
                                RL1_P14_E_MM: r.P14[3],

                                RL1_P15_S_HH: r.P15[0],
                                RL1_P15_S_MM: r.P15[1],
                                RL1_P15_E_HH: r.P15[2],
                                RL1_P15_E_MM: r.P15[3],


                            },
                            () => {
                                //this.renderDataTable(res);
                            });

                    } else if (r.RL == 2) {

                        this.setState(
                            {

                                RL2_P1_S_HH: r.P1[0],
                                RL2_P1_S_MM: r.P1[1],
                                RL2_P1_E_HH: r.P1[2],
                                RL2_P1_E_MM: r.P1[3],

                                RL2_P2_S_HH: r.P2[0],
                                RL2_P2_S_MM: r.P2[1],
                                RL2_P2_E_HH: r.P2[2],
                                RL2_P2_E_MM: r.P2[3],

                                RL2_P3_S_HH: r.P3[0],
                                RL2_P3_S_MM: r.P3[1],
                                RL2_P3_E_HH: r.P3[2],
                                RL2_P3_E_MM: r.P3[3],

                                RL2_P4_S_HH: r.P4[0],
                                RL2_P4_S_MM: r.P4[1],
                                RL2_P4_E_HH: r.P4[2],
                                RL2_P4_E_MM: r.P4[3],

                                RL2_P5_S_HH: r.P5[0],
                                RL2_P5_S_MM: r.P5[1],
                                RL2_P5_E_HH: r.P5[2],
                                RL2_P5_E_MM: r.P5[3],

                                RL2_P6_S_HH: r.P6[0],
                                RL2_P6_S_MM: r.P6[1],
                                RL2_P6_E_HH: r.P6[2],
                                RL2_P6_E_MM: r.P6[3],

                                RL2_P7_S_HH: r.P7[0],
                                RL2_P7_S_MM: r.P7[1],
                                RL2_P7_E_HH: r.P7[2],
                                RL2_P7_E_MM: r.P7[3],

                                RL2_P8_S_HH: r.P8[0],
                                RL2_P8_S_MM: r.P8[1],
                                RL2_P8_E_HH: r.P8[2],
                                RL2_P8_E_MM: r.P8[3],

                                RL2_P9_S_HH: r.P9[0],
                                RL2_P9_S_MM: r.P9[1],
                                RL2_P9_E_HH: r.P9[2],
                                RL2_P9_E_MM: r.P9[3],

                                RL2_P10_S_HH: r.P10[0],
                                RL2_P10_S_MM: r.P10[1],
                                RL2_P10_E_HH: r.P10[2],
                                RL2_P10_E_MM: r.P10[3],

                                RL2_P11_S_HH: r.P11[0],
                                RL2_P11_S_MM: r.P11[1],
                                RL2_P11_E_HH: r.P11[2],
                                RL2_P11_E_MM: r.P11[3],

                                RL2_P12_S_HH: r.P12[0],
                                RL2_P12_S_MM: r.P12[1],
                                RL2_P12_E_HH: r.P12[2],
                                RL2_P12_E_MM: r.P12[3],

                                RL2_P13_S_HH: r.P13[0],
                                RL2_P13_S_MM: r.P13[1],
                                RL2_P13_E_HH: r.P13[2],
                                RL2_P13_E_MM: r.P13[3],

                                RL2_P14_S_HH: r.P14[0],
                                RL2_P14_S_MM: r.P14[1],
                                RL2_P14_E_HH: r.P14[2],
                                RL2_P14_E_MM: r.P14[3],

                                RL2_P15_S_HH: r.P15[0],
                                RL2_P15_S_MM: r.P15[1],
                                RL2_P15_E_HH: r.P15[2],
                                RL2_P15_E_MM: r.P15[3],


                            },
                            () => {
                                //this.renderDataTable(res);
                            });

                    } else if (r.RL == 3) {

                        /*console.log(r.P15[1]);*/

                        this.setState(
                            {



                                RL3_P1_S_HH: r.P1[0],
                                RL3_P1_S_MM: r.P1[1],
                                RL3_P1_E_HH: r.P1[2],
                                RL3_P1_E_MM: r.P1[3],

                                RL3_P2_S_HH: r.P2[0],
                                RL3_P2_S_MM: r.P2[1],
                                RL3_P2_E_HH: r.P2[2],
                                RL3_P2_E_MM: r.P2[3],

                                RL3_P3_S_HH: r.P3[0],
                                RL3_P3_S_MM: r.P3[1],
                                RL3_P3_E_HH: r.P3[2],
                                RL3_P3_E_MM: r.P3[3],

                                RL3_P4_S_HH: r.P4[0],
                                RL3_P4_S_MM: r.P4[1],
                                RL3_P4_E_HH: r.P4[2],
                                RL3_P4_E_MM: r.P4[3],

                                RL3_P5_S_HH: r.P5[0],
                                RL3_P5_S_MM: r.P5[1],
                                RL3_P5_E_HH: r.P5[2],
                                RL3_P5_E_MM: r.P5[3],

                                RL3_P6_S_HH: r.P6[0],
                                RL3_P6_S_MM: r.P6[1],
                                RL3_P6_E_HH: r.P6[2],
                                RL3_P6_E_MM: r.P6[3],

                                RL3_P7_S_HH: r.P7[0],
                                RL3_P7_S_MM: r.P7[1],
                                RL3_P7_E_HH: r.P7[2],
                                RL3_P7_E_MM: r.P7[3],

                                RL3_P8_S_HH: r.P8[0],
                                RL3_P8_S_MM: r.P8[1],
                                RL3_P8_E_HH: r.P8[2],
                                RL3_P8_E_MM: r.P8[3],

                                RL3_P9_S_HH: r.P9[0],
                                RL3_P9_S_MM: r.P9[1],
                                RL3_P9_E_HH: r.P9[2],
                                RL3_P9_E_MM: r.P9[3],

                                RL3_P10_S_HH: r.P10[0],
                                RL3_P10_S_MM: r.P10[1],
                                RL3_P10_E_HH: r.P10[2],
                                RL3_P10_E_MM: r.P10[3],

                                RL3_P11_S_HH: r.P11[0],
                                RL3_P11_S_MM: r.P11[1],
                                RL3_P11_E_HH: r.P11[2],
                                RL3_P11_E_MM: r.P11[3],

                                RL3_P12_S_HH: r.P12[0],
                                RL3_P12_S_MM: r.P12[1],
                                RL3_P12_E_HH: r.P12[2],
                                RL3_P12_E_MM: r.P12[3],

                                RL3_P13_S_HH: r.P13[0],
                                RL3_P13_S_MM: r.P13[1],
                                RL3_P13_E_HH: r.P13[2],
                                RL3_P13_E_MM: r.P13[3],

                                RL3_P14_S_HH: r.P14[0],
                                RL3_P14_S_MM: r.P14[1],
                                RL3_P14_E_HH: r.P14[2],
                                RL3_P14_E_MM: r.P14[3],

                                RL3_P15_S_HH: r.P15[0],
                                RL3_P15_S_MM: r.P15[1],
                                RL3_P15_E_HH: r.P15[2],
                                RL3_P15_E_MM: r.P15[3],
                            },
                            () => {
                                //this.renderDataTable(res);
                            });

                    }

                    if (this.state.connecting) {
                        this.setState(
                            {
                                connecting: false,
                            },
                            () => {
                                //this.renderDataTable(res);
                            }
                        );
                    }

                } catch (e) {
                    console.log(e.toString());
                }
            }
        });
    }

    listener = (topic, message) => {
        const data = JSON.parse(message.toString());
        if (topic.includes("alert")) {
            console.log(data);
        }
    };

    unSub = (topic) => {
        this.setState(
            {
                oldtopic: topic,
            },
            () => { }
        );
        client.unsubscribe(topic, { qos: 1 });
        client.off("message", this.listener);
        //console.log("unSub=" + topic);

        clearInterval(this.interval);
    };

    changeSub = (topic) => {
        clearInterval(this.interval);
        client.subscribe(topic, { qos: 1 });
        if (this.state.connecting) {
            this.setState(
                {
                    connecting: true,
                },
                () => {
                    //this.renderDataTable(res);
                }
            );
        }

        // this.interval = setInterval(this.getChartData, 10000);
        // this.getChartData();
        //console.log("changeSub=" + topic);
    };

    // //publish
    publish = (topic, msg, options) => {
        let json =
            '{ "COMM":1,' +
            '"RL1":{"MODE":' +
            this.state.RL1_MODE +
            ',"T_SET":' +
            this.state.RL1_T_SET +
            ',"T_LOW":' +
            this.state.RL1_T_LOW +
            ',"H_HI":' +
            this.state.RL1_H_HI +
            ',"H_LOW":' +
            this.state.RL1_H_LOW +
            ',"TIME_ON":' +
            this.state.RL1_TIME_ON +
            ',"TIME_OFF":' +
            this.state.RL1_TIME_OFF +
            ',"ST_RL":' +
            this.state.RL1_ST_RL +
            ',"SCH":' +
            this.state.RL1_SCH +
            '},"RL2":{"MODE":' +
            this.state.RL2_MODE +
            ',"H_HI":' +
            this.state.RL2_H_HI +
            ',"H_LOW":' +
            this.state.RL2_H_LOW +
            ',"TIME_ON":' +
            this.state.RL2_TIME_ON +
            ',"TIME_OFF":' +
            this.state.RL2_TIME_OFF +
            ',"SCH":' +
            this.state.RL2_SCH +
            '},"RL3":{"MODE":' +
            this.state.RL3_MODE +
            ',"TIME_ON":' +
            this.state.RL3_TIME_ON +
            ',"TIME_OFF":' +
            this.state.RL3_TIME_OFF +
            ',"SCH":' +
            this.state.RL3_SCH +
            '}}';
        console.log("publishing------------------topic", topic);
        console.log("publishing------------------json", json);
        if (client.connected === true) {
            client.publish(topic, json, options);
        }
    };


    // //publish
    publish_program = (topic, msg, options) => {
        let json = msg;
        console.log("publish_program=" + msg);
        if (client.connected === true) {
            client.publish(topic, json, options);
        }
    };


    formatSeconds = (seconds) => {
        var date = new Date(1970, 0, 1);
        date.setSeconds(seconds);
        return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    };

    redirectToVentilator = () => {
        const { history } = this.props;
        if (history) history.push("/Ventilator");
    };

    redirectToStat = (deviceid) => {
        // const { history } = this.props;
        // if (history) history.push("/stat/" + deviceid);

        const win = window.open("/devices/" + deviceid + "/stat", "_blank");
        win.focus();
    };

    redirectToSiteSetting = (deviceid) => {
        const { history } = this.props;
        if (history) history.push("/sitesetting/" + this.props.match.params.id);
    };

    createSelectDropdown = (max, value) => {
        let table = [];

        for (let i = 0; i < max; i++) {
            let children = [];

            for (let j = 0; j < 5; j++) {
                children.push(<td>{`Column ${j + 1}`}</td>);
            }
            table.push(<tr>{children}</tr>);
        }
        return table;
    };

    toggle_modal = () => {
        this.setState({ modal: !this.modal });
    };

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    set_sch_visible_hide = () => {
        this.setState({
            RL1_P1_VISIBLE: false,
            RL1_P2_VISIBLE: false,
            RL1_P3_VISIBLE: false,
            RL1_P4_VISIBLE: false,
            RL1_P5_VISIBLE: false,
            RL1_P6_VISIBLE: false,
            RL1_P7_VISIBLE: false,
            RL1_P8_VISIBLE: false,
            RL1_P9_VISIBLE: false,
            RL1_P10_VISIBLE: false,
            RL1_P11_VISIBLE: false,
            RL1_P12_VISIBLE: false,
            RL1_P13_VISIBLE: false,
            RL1_P14_VISIBLE: false,
            RL1_P15_VISIBLE: false,

            RL2_P1_VISIBLE: false,
            RL2_P2_VISIBLE: false,
            RL2_P3_VISIBLE: false,
            RL2_P4_VISIBLE: false,
            RL2_P5_VISIBLE: false,
            RL2_P6_VISIBLE: false,
            RL2_P7_VISIBLE: false,
            RL2_P8_VISIBLE: false,
            RL2_P9_VISIBLE: false,
            RL2_P10_VISIBLE: false,
            RL2_P11_VISIBLE: false,
            RL2_P12_VISIBLE: false,
            RL2_P13_VISIBLE: false,
            RL2_P14_VISIBLE: false,
            RL2_P15_VISIBLE: false,

            RL3_P1_VISIBLE: false,
            RL3_P2_VISIBLE: false,
            RL3_P3_VISIBLE: false,
            RL3_P4_VISIBLE: false,
            RL3_P5_VISIBLE: false,
            RL3_P6_VISIBLE: false,
            RL3_P7_VISIBLE: false,
            RL3_P8_VISIBLE: false,
            RL3_P9_VISIBLE: false,
            RL3_P10_VISIBLE: false,
            RL3_P11_VISIBLE: false,
            RL3_P12_VISIBLE: false,
            RL3_P13_VISIBLE: false,
            RL3_P14_VISIBLE: false,
            RL3_P15_VISIBLE: false,
        });
    };


    render() {
        const { isAuthenticated, userName, tabselect,
            modal,
            weathermodel,
            siteinfo,
            DEVICES,
            siteinfo_title,
            siteinfo_location,
            connecting } = this.state;
        return (
            <div>
                <CCard className={styles.mbfixtab}>
                    <CCardBody>
                        <CRow className="mb-1">
                            <CCol className="col-8">


                                <CRow className="mb-4">
                                    <CCol className="">
                                        <h4
                                            id="traffic"
                                            className={styles.texttitle + " card-title mb-0"}
                                        >
                                            {siteinfo_title}  <CLink className="btn btn-outline-warning" to={"/edit/site/" + this.props.match.params.id}> Edit </CLink>
                                        </h4>
                                        <h6> {siteinfo_location}</h6>
                                    </CCol>
                                </CRow>
                                <CRow className="mb-4">
                                    <CCol className="">
                                        <CDropdown dark="true">
                                            <CDropdownToggle color="secondary" size="lg">
                                                <>{this.state.DEVICE_NAME} {connecting}  {connecting ? <CSpinner color="warning" size="sm" /> : <> </>} </> </CDropdownToggle>
                                            <CDropdownMenu>
                                                <>
                                                    {DEVICES.map((device) => (
                                                        <CDropdownItem
                                                            color="outline-secondary"
                                                            key={device.sysId}
                                                            className="mx-0"
                                                            active={device.sysId === this.state.floor}
                                                            onClick={(e) => {
                                                                this.unSub(MQTT_TOPIC);

                                                                MQTT_TOPIC =
                                                                    "SERVER/" + device.model + "/" + device.devicetoken;

                                                                this.setState(
                                                                    (state) => ({
                                                                        floor: device.sysId,
                                                                        connecting: true,
                                                                        DEVICE_SSID: device.sysId,
                                                                        DEVICE_NAME: device.title,
                                                                        DEVICE_MODEL: device.model,
                                                                        DEVICE_TOKEN: device.devicetoken,
                                                                        DEVICE_DESCRIPTION: device.description,

                                                                        DEVICE_R1_TITLE_PRIMARY: device.r1,
                                                                        DEVICE_R1_TITLE_SECONDARY: device.r1s,
                                                                        DEVICE_R2_TITLE_PRIMARY: device.r2,
                                                                        DEVICE_R2_TITLE_SECONDARY: device.r2s,
                                                                        DEVICE_R3_TITLE_PRIMARY: device.r3,
                                                                        DEVICE_R3_TITLE_SECONDARY: device.r3s,

                                                                        TEMP: 0.00,
                                                                        HUMI: 0.00,
                                                                        RL1_MODE: -1,
                                                                        RL1_T_SET: 0.0,
                                                                        RL1_T_LOW: 0.0,
                                                                        RL1_H_HI: 0.0,
                                                                        RL1_H_LOW: 0.0,
                                                                        RL1_TIME_ON: 0.0,
                                                                        RL1_TIME_OFF: 0.0,
                                                                        RL1_ST_RL: 0,
                                                                        RL2_MODE: -1,
                                                                        RL2_H_HI: 0.0,
                                                                        RL2_H_LOW: 0.0,
                                                                        RL2_TIME_ON: 0.0,
                                                                        RL2_TIME_OFF: 0.0,
                                                                        RL2_ST_RL: 0,
                                                                        RL3_MODE: -1,
                                                                        RL3_TIME_ON: 0.0,
                                                                        RL3_TIME_OFF: 0.0,
                                                                        RL3_ST_RL: 0,

                                                                        tabselect: -1,
                                                                    }),
                                                                    () => {
                                                                        this.changeSub(MQTT_TOPIC);
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            {device.title}
                                                        </CDropdownItem>
                                                    ))}


                                                </>

                                            </CDropdownMenu>

                                        </CDropdown>
                                        <p> { " " } {this.state.DEVICE_DESCRIPTION}</p>
                                    </CCol>
                                </CRow>
                            </CCol>

                            <CCol className="col-4">
                                <div className="float-right divweather div-top-right">
                                    {!weathermodel ? (
                                        ""
                                    ) : (
                                        <CCard color="success" className={styles.divweather}>
                                            <CCardBody className="p-1">
                                                {/* <span className="icon"></span>*/}
                                                <div className="row">
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p className={styles.textweather}>
                                                                    {weathermodel.weather.icon ? (
                                                                        <CImg
                                                                            src={
                                                                                "https://openweathermap.org/img/wn/" +
                                                                                weathermodel.weather.icon +
                                                                                ".png"
                                                                            }
                                                                        ></CImg>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {weathermodel.weather.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p className={styles.textweather}>Temp</p>
                                                            </div>
                                                            <div className="col">
                                                                <p className={styles.textweather}>
                                                                    : {weathermodel.main.temp}{" "}
                                                                    <span className={styles.textweathersmall}>
                                                                        °C
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p className={styles.textweather}>Feels Like</p>
                                                            </div>
                                                            <div className="col">
                                                                <p className={styles.textweather}>
                                                                        : {weathermodel.main.feels_like}{" "}
                                                                    <span className={styles.textweathersmall}>
                                                                        °C
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p className={styles.textweather}>Humidity</p>
                                                            </div>
                                                            <div className="col">
                                                                <p className={styles.textweather}>
                                                                    : {weathermodel.main.humidity}
                                                                    <span className={styles.textweathersmall}>
                                                                        {" %RH"}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p className={styles.textweather}>Wind</p>
                                                            </div>
                                                            <div className="col">
                                                                <p className={styles.textweather}>
                                                                    : {weathermodel.wind.speed}
                                                                    <span className={styles.textweathersmall}>
                                                                        {" km/hr"}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CCardBody>
                                        </CCard>

                                    )}
                                </div>
                            </CCol>
                        </CRow>

                        {tabselect === -1 ? (
                            <div>
                                <CRow className="mt-4">
                                    <CCol className="text-center"></CCol>

                                </CRow>

                                <CRow className="mt-4 d-md-none">
                                    <CCol className="text-center">
                                        <h2 alt="Dewpoint">
                                            <CImg
                                                src={imgDewpoint}
                                                width="100%"
                                                fluid
                                                style={{ maxWidth: "2em" }}
                                                className="mb-2"
                                            />{" "}
                                            {dewpoint(this.state.TEMP, this.state.HUMI)}{" "}
                                            <span className={styles.textdewpoint}>Dewpoint</span>
                                        </h2>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol className="col-sm-6 col-md-4">
                                        <div style={{ height: "160px" }}>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                }}
                                            >
                                                <GuageTemperature
                                                    width={200}
                                                    upperBound={upperBound}
                                                    lowerBound={lowerBound}
                                                    radius={radius}
                                                    offsetBottom={20}
                                                    gaugeWidth={guagewidth}
                                                    value={this.state.TEMP ? this.state.TEMP : 0.0}
                                                    vlowlevel={
                                                        this.state.RL1_T_LOW ? this.state.RL1_T_LOW : 0.0
                                                    }
                                                    vlowalert={
                                                        this.state.RL1_T_LOW
                                                            ? this.state.RL1_T_LOW + 2
                                                            : 0.0
                                                    }
                                                    vnormal={
                                                        this.state.RL1_T_SET
                                                            ? this.state.RL1_T_SET - 1
                                                            : 49.0
                                                    }
                                                    vhighalert={
                                                        this.state.RL1_T_SET ? this.state.RL1_T_SET : 50.0
                                                    }
                                                    valueStep={1}
                                                ></GuageTemperature>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol className="align-middle text-center d-none d-md-inline">
                                        <h2 alt="Dewpoint" className="mt-3">
                                            <CImg
                                                src={imgDewpoint}
                                                width="100%"
                                                fluid
                                                style={{ maxWidth: "2em" }}
                                                className="mb-2"
                                            />{" "}
                                            {dewpoint(this.state.TEMP, this.state.HUMI)}{" "}
                                            <span className={styles.textdewpoint}>dewpoint</span>
                                        </h2>
                                    </CCol>
                                    <CCol className="col-sm-6 col-md-4">
                                        <div style={{ height: "160px" }}>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                }}
                                            >
                                                <GuageHumidity
                                                    width={200}
                                                    upperBound={100}
                                                    lowerBound={0}
                                                    radius={radius}
                                                    offsetBottom={20}
                                                    gaugeWidth={guagewidth}
                                                    value={this.state.HUMI ? this.state.HUMI : 0.0}
                                                    vlowlevel={
                                                        this.state.RL2_H_LOW
                                                            ? this.state.RL2_H_LOW - 10 < 0
                                                                ? 0.0
                                                                : this.state.RL2_H_LOW - 10
                                                            : 0.0
                                                    }
                                                    vlowalert={
                                                        this.state.RL2_H_LOW
                                                            ? this.state.RL2_H_LOW - 5
                                                            : 0.0
                                                    }
                                                    vnormal={
                                                        this.state.RL2_H_HI ? this.state.RL2_H_HI : 99.0
                                                    }
                                                    vhighalert={
                                                        this.state.RL1_H_HI
                                                            ? this.state.RL1_H_HI > 100
                                                                ? 100
                                                                : this.state.RL1_H_HI
                                                            : 100.0
                                                    }
                                                    valueStep={0.5}
                                                />
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>

                                <CRow>
                                    <CCol className="text-center">
                                        <CLink className="m-2" to={"/devices/" + this.state.DEVICE_SSID + "/stat"} target="_blank" >
                                            <CImg
                                                align="center"
                                                src={BottonChart}
                                                width={200}
                                                height={100}
                                            /> </CLink>
                                        {/*<CButton*/}
                                        {/*    className="m-2"*/}
                                        {/*    variant="ghost"*/}
                                        {/*    onClick={() => {*/}
                                        {/*        //console.log(this.state.DEVICE_SSID);*/}
                                        {/*        this.redirectToStat(this.state.DEVICE_SSID);*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <CImg*/}
                                        {/*        align="center"*/}
                                        {/*        src={BottonChart}*/}
                                        {/*        width={200}*/}
                                        {/*        height={100}*/}
                                        {/*    />*/}
                                        {/*</CButton>*/}
                                    </CCol>
                                </CRow>
                            </div>
                        ) : (
                            ""
                        )}

                        {tabselect === 0 ? (
                            <div>
                                <h3>Ventilator<br /><small className="text-success"> {Number(this.state.TEMP).toFixed(2) + "°C"} / {Number(this.state.HUMI).toFixed(2) + "%RH"} / {dewpoint(this.state.TEMP, this.state.HUMI) + "DP"}  </small> <CButton className="float-right btn-outline-danger " onClick={() => { this.setState({ tabselect: -1 }) }}> back </CButton></h3>
                                <CRow>
                                    <CCol>
                                        <div>
                                            <div id="accordion">
                                                <CCard className="mb-0">
                                                    <CCardHeader id="headingOne">
                                                        <CImg
                                                            src={icon_magicwand}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        AUTO
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={
                                                                        this.state.RL1_MODE === 1 ||
                                                                        this.state.RL1_MODE === 3
                                                                    }
                                                                    onClick={() => {
                                                                        if (this.state.RL1_MODE !== 1) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL1_MODE: 1,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL1_MODE: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse
                                                        show={
                                                            this.state.RL1_MODE === 1 ||
                                                            this.state.RL1_MODE === 3
                                                        }
                                                    >
                                                        <CCardBody>
                                                            <CRow>
                                                                <CCol>
                                                                    <div>
                                                                        <CForm
                                                                            action=""
                                                                            method="post"
                                                                            className="form-horizontal"
                                                                        >
                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6" className="pr-0">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_t_low}
                                                                                            fluid
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                        />
                                                                                        <span className="d-none d-sm-inline">Low Temperature</span>
                                                                                        <span className="d-inline d-sm-none">L Temp.</span>
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                    <CInputGroup size="">
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.state.RL1_T_LOW}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>
                                                                                        <CInputGroupAppend>

                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {

                                                                                                    if (this.state.RL1_T_LOW_SET_VISIBLE) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_T_LOW: this.state.RL1_T_LOW_SET,
                                                                                                                RL1_T_LOW_SET_VISIBLE: false,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state.DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state.DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );

                                                                                                    }
                                                                                                    else {

                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_T_LOW_SET_VISIBLE: true,
                                                                                                                RL1_T_LOW_SET: this.state.RL1_T_LOW,

                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }

                                                                                                }}
                                                                                            >

                                                                                                <CIcon name={this.state.RL1_T_LOW_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>

                                                                                            {this.state.RL1_T_LOW_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_T_LOW_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                > Cancel
                                                                                                </CButton>
                                                                                                : ""}


                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={this.state.RL1_T_LOW_SET_VISIBLE}
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol className="text-center"
                                                                                    >
                                                                                        <h4>{this.state.RL1_T_LOW_SET}</h4>
                                                                                        <RangeSlider
                                                                                            value={this.state.RL1_T_LOW_SET}
                                                                                            min={0}
                                                                                            max={50}
                                                                                            size="lg" 
                                                                                            tooltipPlacement="right"
                                                                                            step={stepbutton}
                                                                                            onChange={(e) => {
                                                                                                const { value } = e.target;
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL1_T_LOW_SET: value,
                                                                                                    }),
                                                                                                    () => {
                                                                                                        // this.publish(
                                                                                                        //   "DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN
                                                                                                        // );
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            onAfterChange={() => {
                                                                                                //this.setState(
                                                                                                //    (state) => ({
                                                                                                //        RL1_T_LOW:
                                                                                                //            state.RL1_T_LOW_SET,
                                                                                                //    }),
                                                                                                //    () => {
                                                                                                //        this.publish(
                                                                                                //            "DEVICE/" +
                                                                                                //            this.state
                                                                                                //                .DEVICE_MODEL +
                                                                                                //            "/" +
                                                                                                //            this.state.DEVICE_TOKEN
                                                                                                //        );
                                                                                                //    }
                                                                                                //);
                                                                                            }}
                                                                                        />
                                                                                       
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_t_high}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />

                                                                                        <span className="d-none d-sm-inline">High Temperature</span>
                                                                                        <span className="d-inline d-sm-none">H Temp.</span>
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.state.RL1_T_SET}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>

                                                                                        <CInputGroupAppend>

                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {

                                                                                                    if (this.state.RL1_T_SET_SET_VISIBLE) {

                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_T_SET_SET_VISIBLE: false,
                                                                                                                RL1_T_SET: this.state.RL1_T_SET_SET,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );

                                                                                                    }
                                                                                                    else {

                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_T_SET_SET_VISIBLE: true,
                                                                                                                RL1_T_SET_SET: this.state.RL1_T_SET,

                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );

                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL1_T_SET_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />

                                                                                            </CButton>

                                                                                            {this.state.RL1_T_SET_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_T_SET_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                >

                                                                                                    Cancel
                                                                                                </CButton>
                                                                                                : ""}
                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={this.state.RL1_T_SET_SET_VISIBLE}
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol
                                                                                        className="text-center"
                                                                                    >
                                                                                        <h4>{this.state.RL1_T_SET_SET}</h4>
                                                                                        <RangeSlider
                                                                                            value={this.state.RL1_T_SET_SET}
                                                                                            min={this.state.RL1_T_LOW}
                                                                                            size="lg"
                                                                                            tooltipPlacement="right"
                                                                                            max={50}
                                                                                            step={stepbutton}
                                                                                            onChange={(e) => {
                                                                                                const { value } = e.target;
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL1_T_SET_SET: value,
                                                                                                    }),
                                                                                                    () => {
                                                                                                        // this.publish(
                                                                                                        //   "DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN
                                                                                                        // );
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            onAfterChange={() => {
                                                                                                //this.setState(
                                                                                                //    (state) => ({
                                                                                                //        RL1_T_SET:
                                                                                                //            state.RL1_T_SET_SET,
                                                                                                //    }),
                                                                                                //    () => {
                                                                                                //        this.publish(
                                                                                                //            "DEVICE/" +
                                                                                                //            this.state
                                                                                                //                .DEVICE_MODEL +
                                                                                                //            "/" +
                                                                                                //            this.state.DEVICE_TOKEN
                                                                                                //        );
                                                                                                //    }
                                                                                                //);
                                                                                            }}
                                                                                        />
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>
                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel>
                                                                                        <CImg
                                                                                            src={icon_h_high}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />

                                                                                        <span className="d-none d-sm-inline">High Humidity</span>
                                                                                        <span className="d-inline d-sm-none">H Humi.</span>
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.state.RL1_H_HI}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>
                                                                                        <CInputGroupAppend>

                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {


                                                                                                    if (this.state.RL1_H_HI_SET_VISIBLE) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_H_HI: this.state.RL1_H_HI_SET,
                                                                                                                RL1_H_HI_SET_VISIBLE: false,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );

                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_H_HI_SET_VISIBLE: true,
                                                                                                                RL1_H_HI_SET: this.state.RL1_H_HI,
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL1_H_HI_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>
                                                                                            {this.state.RL1_H_HI_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_H_HI_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    Cancel
                                                                                                </CButton>
                                                                                                : ""}
                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={this.state.RL1_H_HI_SET_VISIBLE}
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol className="text-center"
                                                                                    >
                                                                                        <h4>{this.state.RL1_H_HI_SET}</h4>
                                                                                        <RangeSlider
                                                                                            value={this.state.RL1_H_HI_SET}
                                                                                            size="lg"
                                                                                            tooltipPlacement=""
                                                                                            min={this.state.RL1_H_LOW}
                                                                                            max={100}
                                                                                            step={stepbutton}
                                                                                            onChange={(e) => {
                                                                                                const { value } = e.target;
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL1_H_HI_SET: value,
                                                                                                    }),
                                                                                                    () => { }
                                                                                                );
                                                                                            }}
                                                                                            onAfterChange={() => {
                                                                                                //this.setState(
                                                                                                //    (state) => ({
                                                                                                //        RL1_H_HI:
                                                                                                //            state.RL1_H_HI_SET,
                                                                                                //    }),
                                                                                                //    () => {
                                                                                                //        this.publish(
                                                                                                //            "DEVICE/" +
                                                                                                //            this.state
                                                                                                //                .DEVICE_MODEL +
                                                                                                //            "/" +
                                                                                                //            this.state.DEVICE_TOKEN
                                                                                                //        );
                                                                                                //    }
                                                                                                //);
                                                                                            }}
                                                                                        />
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_h_low}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />

                                                                                        <span className="d-none d-sm-inline">Low Humidity</span>
                                                                                        <span className="d-inline d-sm-none">L Humi.</span>
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.state.RL1_H_LOW}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>

                                                                                        <CInputGroupAppend>
                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {

                                                                                                    if (this.state.RL1_H_LOW_SET_VISIBLE) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_H_LOW: this.state.RL1_H_LOW_SET,
                                                                                                                RL1_H_LOW_SET_VISIBLE: false,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state.DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state.DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );

                                                                                                    }
                                                                                                    else {


                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            RL1_H_LOW_SET_VISIBLE: true,
                                                                                                            RL1_H_LOW_SET: this.state.RL1_H_LOW,
                                                                                                        }),
                                                                                                        () => { }
                                                                                                    );



                                                                                                    }

                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL1_H_LOW_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>

                                                                                            {this.state.RL1_H_LOW_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_H_LOW_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                > Cancel
                                                                                                </CButton>
                                                                                                : ""}

                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                            <CCollapse
                                                                                show={this.state.RL1_H_LOW_SET_VISIBLE}
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol className="text-center"
                                                                                    >
                                                                                        <h4>{this.state.RL1_H_LOW_SET}</h4>
                                                                                        <RangeSlider
                                                                                            value={this.state.RL1_H_LOW_SET}
                                                                                            size="lg"
                                                                                            tooltipPlacement="top"
                                                                                            min={0}
                                                                                            max={this.state.RL1_H_HI}
                                                                                            step={stepbutton}
                                                                                            onChange={(e) => {
                                                                                                const { value } = e.target;
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL1_H_LOW_SET: value,
                                                                                                    }),
                                                                                                    () => {
                                                                                                        // this.publish(
                                                                                                        //   "DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN
                                                                                                        // );
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            onAfterChange={() => {
                                                                                                //this.setState(
                                                                                                //    (state) => ({
                                                                                                //        RL1_H_LOW:
                                                                                                //            state.RL1_H_LOW_SET,
                                                                                                //    }),
                                                                                                //    () => {
                                                                                                //        this.publish(
                                                                                                //            "DEVICE/" +
                                                                                                //            this.state
                                                                                                //                .DEVICE_MODEL +
                                                                                                //            "/" +
                                                                                                //            this.state.DEVICE_TOKEN
                                                                                                //        );
                                                                                                //    }
                                                                                                //);
                                                                                            }}
                                                                                        />
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_play}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />
                                                                                        Play
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.formatSeconds(
                                                                                                        this.state.RL1_TIME_ON
                                                                                                    )}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>

                                                                                        <CInputGroupAppend>
                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this.state
                                                                                                            .RL1_TIME_ON_SET_VISIBLE
                                                                                                    ) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_TIME_ON:
                                                                                                                    state.RL1_TIME_ON_SET,
                                                                                                                RL1_TIME_ON_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL1_TIME_ON_SET_VISIBLE,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );
                                                                                                    } else {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_TIME_ON_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL1_TIME_ON_SET_VISIBLE,
                                                                                                                RL1_TIME_ON_SET: this
                                                                                                                    .state.RL1_TIME_ON,
                                                                                                                RL1_TIME_ON_SET_H: Math.floor(
                                                                                                                    this.state
                                                                                                                        .RL1_TIME_ON / 3600
                                                                                                                ),
                                                                                                                RL1_TIME_ON_SET_M: Math.floor(
                                                                                                                    (this.state
                                                                                                                        .RL1_TIME_ON %
                                                                                                                        3600) /
                                                                                                                    60
                                                                                                                ),
                                                                                                                RL1_TIME_ON_SET_S:
                                                                                                                    this.state
                                                                                                                        .RL1_TIME_ON % 60,
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL1_TIME_ON_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />



                                                                                            </CButton>

                                                                                            {this.state.RL1_TIME_ON_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_TIME_ON_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                >

                                                                                                    Cancel
                                                                                                </CButton>
                                                                                                : ""}


                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={
                                                                                    this.state.RL1_TIME_ON_SET_VISIBLE
                                                                                }
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol xs="5" md="6"></CCol>
                                                                                    <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                        <CInputGroup>
                                                                                            <CInputGroupPrepend>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Hour"
                                                                                                    value={
                                                                                                        this.state.RL1_TIME_ON_SET_H
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            e.target.value * 3600 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_ON_SET_M *
                                                                                                            60 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_ON_SET_S;
                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL1_TIME_ON_SET_H: Math.floor(
                                                                                                                sum / 3600
                                                                                                            ),
                                                                                                            RL1_TIME_ON_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timehourvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timehourvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Minutes"
                                                                                                    value={
                                                                                                        this.state.RL1_TIME_ON_SET_M
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL1_TIME_ON_SET_H *
                                                                                                            3600 +
                                                                                                            e.target.value * 60 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_ON_SET_S;

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL1_TIME_ON_SET_M: Math.floor(
                                                                                                                (sum % 3600) / 60
                                                                                                            ),
                                                                                                            RL1_TIME_ON_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timeminvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timeminvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>

                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Sec"
                                                                                                    value={
                                                                                                        this.state.RL1_TIME_ON_SET_S
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL1_TIME_ON_SET_H *
                                                                                                            3600 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_ON_SET_M *
                                                                                                            60 +
                                                                                                            parseInt(e.target.value);

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL1_TIME_ON_SET_S:
                                                                                                                sum % 60,
                                                                                                            RL1_TIME_ON_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timesecvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timesecvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                            </CInputGroupPrepend>
                                                                                        </CInputGroup>
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_pause}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />
                                                                                        Pause
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.formatSeconds(
                                                                                                        this.state.RL1_TIME_OFF
                                                                                                    )}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>

                                                                                        <CInputGroupAppend>
                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this.state
                                                                                                            .RL1_TIME_OFF_SET_VISIBLE
                                                                                                    ) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_TIME_OFF:
                                                                                                                    state.RL1_TIME_OFF_SET,
                                                                                                                RL1_TIME_OFF_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL1_TIME_OFF_SET_VISIBLE,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );
                                                                                                    } else {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_TIME_OFF_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL1_TIME_OFF_SET_VISIBLE,
                                                                                                                RL1_TIME_OFF_SET: this
                                                                                                                    .state.RL1_TIME_OFF,
                                                                                                                RL1_TIME_OFF_SET_H: Math.floor(
                                                                                                                    this.state
                                                                                                                        .RL1_TIME_OFF / 3600
                                                                                                                ),
                                                                                                                RL1_TIME_OFF_SET_M: Math.floor(
                                                                                                                    (this.state
                                                                                                                        .RL1_TIME_OFF %
                                                                                                                        3600) /
                                                                                                                    60
                                                                                                                ),
                                                                                                                RL1_TIME_OFF_SET_S:
                                                                                                                    this.state
                                                                                                                        .RL1_TIME_OFF % 60,
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL1_TIME_OFF_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>

                                                                                            {this.state.RL1_TIME_OFF_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL1_TIME_OFF_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                >

                                                                                                    Cancel
                                                                                                </CButton>
                                                                                                : ""}
                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={
                                                                                    this.state.RL1_TIME_OFF_SET_VISIBLE
                                                                                }
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol xs="5" md="6"></CCol>
                                                                                    <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                        <CInputGroup>
                                                                                            <CInputGroupPrepend>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Hour"
                                                                                                    value={
                                                                                                        this.state
                                                                                                            .RL1_TIME_OFF_SET_H
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            e.target.value * 3600 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_OFF_SET_M *
                                                                                                            60 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_OFF_SET_S;
                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL1_TIME_OFF_SET_H: Math.floor(
                                                                                                                sum / 3600
                                                                                                            ),
                                                                                                            RL1_TIME_OFF_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timehourvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timehourvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Minutes"
                                                                                                    value={
                                                                                                        this.state
                                                                                                            .RL1_TIME_OFF_SET_M
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL1_TIME_OFF_SET_H *
                                                                                                            3600 +
                                                                                                            e.target.value * 60 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_OFF_SET_S;

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL1_TIME_OFF_SET_M: Math.floor(
                                                                                                                (sum % 3600) / 60
                                                                                                            ),
                                                                                                            RL1_TIME_OFF_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timeminvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timeminvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>

                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Sec"
                                                                                                    value={
                                                                                                        this.state
                                                                                                            .RL1_TIME_OFF_SET_S
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL1_TIME_OFF_SET_H *
                                                                                                            3600 +
                                                                                                            this.state
                                                                                                                .RL1_TIME_OFF_SET_M *
                                                                                                            60 +
                                                                                                            parseInt(e.target.value);

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL1_TIME_OFF_SET_S:
                                                                                                                sum % 60,
                                                                                                            RL1_TIME_OFF_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timesecvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timesecvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                            </CInputGroupPrepend>
                                                                                        </CInputGroup>

                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel>
                                                                                        <CImg
                                                                                            src={imgHeaterON}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />
                                                                                        Heater
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                                    <CSwitch
                                                                                        className={"mx-1"}
                                                                                        shape={"pill"}
                                                                                        color={"danger"}
                                                                                        labelOn={"ON"}
                                                                                        labelOff={"OFF"}
                                                                                        size="lg"
                                                                                        onChange={() => { }}
                                                                                        checked={this.state.RL1_MODE === 3}
                                                                                        onClick={() => {
                                                                                            if (this.state.RL1_MODE !== 3) {
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL1_MODE: 3,
                                                                                                    }),
                                                                                                    () => {
                                                                                                        this.publish(
                                                                                                            "DEVICE/" +
                                                                                                            this.state
                                                                                                                .DEVICE_MODEL +
                                                                                                            "/" +
                                                                                                            this.state.DEVICE_TOKEN
                                                                                                        );
                                                                                                    }
                                                                                                );
                                                                                            } else {
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL1_MODE: 1,
                                                                                                    }),
                                                                                                    () => {
                                                                                                        this.publish(
                                                                                                            "DEVICE/" +
                                                                                                            this.state
                                                                                                                .DEVICE_MODEL +
                                                                                                            "/" +
                                                                                                            this.state.DEVICE_TOKEN
                                                                                                        );
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </div>
                                                                </CCol>
                                                            </CRow>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                                <CCard className="mb-0">
                                                    <CCardHeader>
                                                        <CImg
                                                            src={icon_reload}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        LOOP
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL1_MODE === 2}
                                                                    onClick={() => {
                                                                        if (this.state.RL1_MODE !== 2) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL1_MODE: 2,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL1_MODE: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL1_MODE === 2}>
                                                        {/* this.state.RL1_MODE === 2 */}
                                                        <CCardBody>
                                                            <CForm
                                                                action=""
                                                                method="post"
                                                                className="form-horizontal"
                                                            >
                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Play
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL1_TIME_ON
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state.RL1_TIME_ON_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL1_TIME_ON:
                                                                                                        state.RL1_TIME_ON_SET,
                                                                                                    RL1_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL1_TIME_ON_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL1_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL1_TIME_ON_SET_VISIBLE,
                                                                                                    RL1_TIME_ON_SET: this.state
                                                                                                        .RL1_TIME_ON,
                                                                                                    RL1_TIME_ON_SET_H: Math.floor(
                                                                                                        this.state.RL1_TIME_ON /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL1_TIME_ON_SET_M: Math.floor(
                                                                                                        (this.state.RL1_TIME_ON %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL1_TIME_ON_SET_S:
                                                                                                        this.state.RL1_TIME_ON % 60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL1_TIME_ON_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>

                                                                                {this.state.RL1_TIME_ON_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL1_TIME_ON_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    >

                                                                                        Cancel
                                                                                    </CButton>
                                                                                    : ""}
                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL1_TIME_ON_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={this.state.RL1_TIME_ON_SET_H}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL1_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                this.state.RL1_TIME_ON_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL1_TIME_ON_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL1_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={this.state.RL1_TIME_ON_SET_M}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL1_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL1_TIME_ON_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL1_TIME_ON_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL1_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={this.state.RL1_TIME_ON_SET_S}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL1_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL1_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL1_TIME_ON_SET_S: sum % 60,
                                                                                                RL1_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>

                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_pause}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Pause
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="7" md="6" className="mr-0 pr-0">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL1_TIME_OFF
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state
                                                                                                .RL1_TIME_OFF_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL1_TIME_OFF:
                                                                                                        state.RL1_TIME_OFF_SET,
                                                                                                    RL1_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL1_TIME_OFF_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL1_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL1_TIME_OFF_SET_VISIBLE,
                                                                                                    RL1_TIME_OFF_SET: this.state
                                                                                                        .RL1_TIME_OFF,
                                                                                                    RL1_TIME_OFF_SET_H: Math.floor(
                                                                                                        this.state.RL1_TIME_OFF /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL1_TIME_OFF_SET_M: Math.floor(
                                                                                                        (this.state.RL1_TIME_OFF %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL1_TIME_OFF_SET_S:
                                                                                                        this.state.RL1_TIME_OFF %
                                                                                                        60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL1_TIME_OFF_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>

                                                                                {this.state.RL1_TIME_OFF_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL1_TIME_OFF_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    >

                                                                                        Cancel
                                                                                    </CButton>
                                                                                    : ""}
                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL1_TIME_OFF_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="6" md="5">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={
                                                                                            this.state.RL1_TIME_OFF_SET_H
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL1_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                this.state.RL1_TIME_OFF_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL1_TIME_OFF_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL1_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={
                                                                                            this.state.RL1_TIME_OFF_SET_M
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL1_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL1_TIME_OFF_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL1_TIME_OFF_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL1_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={
                                                                                            this.state.RL1_TIME_OFF_SET_S
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL1_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL1_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL1_TIME_OFF_SET_S: sum % 60,
                                                                                                RL1_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>
                                                            </CForm>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>

                                                <CCard className="mb-0">
                                                    <CCardHeader>
                                                        <CImg
                                                            src={icon_wallclock}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        SCHEDULE
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL1_SCH === 1 ? true : false}
                                                                    onClick={() => {
                                                                        if (this.state.RL1_SCH !== 0) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL1_SCH: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish("DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN);
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL1_SCH: 1,
                                                                                }),
                                                                                () => {
                                                                                    this.publish("DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN);
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL1_SCH === 1 ? true : false}>
                                                        <CCardBody>
                                                            <CForm
                                                                action=""
                                                                method="post"
                                                                className="form-horizontal"
                                                            >


                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P1
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P1_S_HH, 2)}:{padLZero(this.state.RL1_P1_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P1_E_HH, 2)}:{padLZero(this.state.RL1_P1_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P1_VISIBLE) {

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P1_VISIBLE: false,
                                                                                                RL1_P1_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P1_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P1_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P1_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":1' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P1_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P1_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P1_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P1_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P1_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL1_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P1_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P1_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P1_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>

                                                                    </CCol>
                                                                    <CCol>
                                                                        <CCollapse show={this.state.RL1_P1_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P2
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P2_S_HH, 2)}:{padLZero(this.state.RL1_P2_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P2_E_HH, 2)}:{padLZero(this.state.RL1_P2_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P2_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P2_VISIBLE: false,
                                                                                                RL1_P2_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P2_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P2_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P2_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":2' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P2_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P2_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P2_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P2_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P2_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P2_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P2_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P2_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P2_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P2_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P2_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P2_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P2_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>

                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P3
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P3_S_HH, 2)}:{padLZero(this.state.RL1_P3_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P3_E_HH, 2)}:{padLZero(this.state.RL1_P3_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P3_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P3_VISIBLE: false,
                                                                                                RL1_P3_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P3_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P3_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P3_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":3' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P3_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P3_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P3_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P3_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P3_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P3_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P3_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P3_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P3_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P3_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P3_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P3_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P3_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>



                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P4
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P4_S_HH, 2)}:{padLZero(this.state.RL1_P4_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P4_E_HH, 2)}:{padLZero(this.state.RL1_P4_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P4_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P4_VISIBLE: false,
                                                                                                RL1_P4_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P4_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P4_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P4_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":4' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P4_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P4_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P4_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P4_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P4_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P4_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P4_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P4_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P4_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P4_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P4_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P4_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P4_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P5
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P5_S_HH, 2)}:{padLZero(this.state.RL1_P5_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P5_E_HH, 2)}:{padLZero(this.state.RL1_P5_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P5_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P5_VISIBLE: false,
                                                                                                RL1_P5_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P5_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P5_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P5_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":5' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P5_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P5_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P5_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P5_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P5_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P5_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P5_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P5_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}




                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P5_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>


                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P6
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P6_S_HH, 2)}:{padLZero(this.state.RL1_P6_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P6_E_HH, 2)}:{padLZero(this.state.RL1_P6_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P6_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P6_VISIBLE: false,
                                                                                                RL1_P6_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P6_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P6_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P6_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":6' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P6_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P6_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P6_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P6_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P6_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P6_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P6_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P6_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P6_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P6_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>


                                                                            {this.state.RL1_P6_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P6_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}



                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P6_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P7
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P7_S_HH, 2)}:{padLZero(this.state.RL1_P7_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P7_E_HH, 2)}:{padLZero(this.state.RL1_P7_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P7_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P7_VISIBLE: false,
                                                                                                RL1_P7_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P7_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P7_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P7_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":7' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P7_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P7_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P7_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P7_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P7_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P7_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P7_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P7_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P7_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P7_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>


                                                                            {this.state.RL1_P7_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P7_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P7_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P8
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P8_S_HH, 2)}:{padLZero(this.state.RL1_P8_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P8_E_HH, 2)}:{padLZero(this.state.RL1_P8_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P8_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P8_VISIBLE: false,
                                                                                                RL1_P8_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P8_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P8_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P8_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":8' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P8_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P8_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P8_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P8_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P8_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P8_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P8_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P8_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P8_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P9
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P9_S_HH, 2)}:{padLZero(this.state.RL1_P9_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P9_E_HH, 2)}:{padLZero(this.state.RL1_P9_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P9_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P9_VISIBLE: false,
                                                                                                RL1_P9_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P9_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P9_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P9_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":9' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P9_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P9_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P9_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P9_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL1_P9_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL1_P9_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P9_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P9_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P9_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P9_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P9_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P9_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P9_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P10
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P10_S_HH, 2)}:{padLZero(this.state.RL1_P10_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P10_E_HH, 2)}:{padLZero(this.state.RL1_P10_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P10_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P10_VISIBLE: !this.state.RL1_P10_VISIBLE,
                                                                                                RL1_P10_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P10_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P10_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P10_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":10' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P10_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P10_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P10_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P10_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P10_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL1_P10_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P10_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P10_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P10_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P10_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P10_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P10_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P10_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P11
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P11_S_HH, 2)}:{padLZero(this.state.RL1_P11_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P11_E_HH, 2)}:{padLZero(this.state.RL1_P11_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P11_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P11_VISIBLE: !this.state.RL1_P11_VISIBLE,
                                                                                                RL1_P11_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P11_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P11_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P11_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":11' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P11_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P11_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P11_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P11_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P11_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL1_P11_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P11_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P11_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P11_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P11_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL1_P11_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P11_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P11_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P12
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P12_S_HH, 2)}:{padLZero(this.state.RL1_P12_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P12_E_HH, 2)}:{padLZero(this.state.RL1_P12_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P12_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P12_VISIBLE: !this.state.RL1_P12_VISIBLE,
                                                                                                RL1_P12_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P12_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P12_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P12_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":12' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P12_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P12_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P12_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P12_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P12_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL1_P12_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P12_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P12_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P12_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P12_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL1_P12_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P12_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P12_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P13
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P13_S_HH, 2)}:{padLZero(this.state.RL1_P13_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P13_E_HH, 2)}:{padLZero(this.state.RL1_P13_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P13_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P13_VISIBLE: !this.state.RL1_P13_VISIBLE,
                                                                                                RL1_P13_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P13_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P13_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P13_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":13' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P13_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P13_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P13_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P13_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P13_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL1_P13_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P13_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P13_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P13_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P13_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL1_P13_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P13_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P13_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P14
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P14_S_HH, 2)}:{padLZero(this.state.RL1_P14_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P14_E_HH, 2)}:{padLZero(this.state.RL1_P14_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P14_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P14_VISIBLE: !this.state.RL1_P14_VISIBLE,
                                                                                                RL1_P14_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P14_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P14_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P14_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":14' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P14_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P14_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P14_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P14_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P14_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL1_P14_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P14_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P14_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P14_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P14_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL1_P14_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P14_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P14_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P15
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL1_P15_S_HH, 2)}:{padLZero(this.state.RL1_P15_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL1_P15_E_HH, 2)}:{padLZero(this.state.RL1_P15_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL1_P15_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P15_VISIBLE: false,
                                                                                                RL1_P15_S_HH: this.state.PX_S_HH,
                                                                                                RL1_P15_S_MM: this.state.PX_S_MM,
                                                                                                RL1_P15_E_HH: this.state.PX_E_HH,
                                                                                                RL1_P15_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":1' +
                                                                                                    ',"P":15' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL1_P15_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P15_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P15_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL1_P15_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P15_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL1_P15_S_HH,
                                                                                                PX_S_MM: this.state.RL1_P15_S_MM,
                                                                                                PX_E_HH: this.state.RL1_P15_E_HH,
                                                                                                PX_E_MM: this.state.RL1_P15_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL1_P15_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL1_P15_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL1_P15_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL1_P15_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>


                                                            </CForm>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        ) : (
                            ""
                        )}
                        {tabselect === 1 ? (
                            <div>
                                <h3>Mist Maker<br /><small className="text-success"> {Number(this.state.TEMP).toFixed(2) + "°c"} / {Number(this.state.HUMI).toFixed(2) + "%h"} / {dewpoint(this.state.TEMP, this.state.HUMI) + "dp"}  </small> <CButton className="float-right btn-danger" onClick={() => { this.setState({ tabselect: -1 }) }}> back </CButton></h3>
                                <CRow>
                                    <CCol>
                                        <div>
                                            <div id="accordion">
                                                <CCard className="mb-0">
                                                    <CCardHeader id="headingOne">
                                                        <CImg
                                                            src={icon_magicwand}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        AUTO
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL2_MODE === 1}
                                                                    onClick={() => {
                                                                        if (this.state.RL2_MODE !== 1) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL2_MODE: 1,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL2_MODE: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL2_MODE === 1}>
                                                        <CCardBody>
                                                            <CRow>
                                                                <CCol>
                                                                    <div>
                                                                        <CForm
                                                                            action=""
                                                                            method="post"
                                                                            className="form-horizontal"
                                                                        >
                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel>
                                                                                        <CImg
                                                                                            src={icon_h_high}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />
                                                                                        High Humidity
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="6" md="5">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.state.RL2_H_HI}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>
                                                                                        <CInputGroupAppend>
                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {

                                                                                                    if (this.state.RL2_H_HI_SET_VISIBLE) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_H_HI: this.state.RL2_H_HI_SET,
                                                                                                                RL2_H_HI_SET_VISIBLE: false,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state.DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state.DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );

                                                                                                    }
                                                                                                    else {

                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_H_HI_SET_VISIBLE: true,
                                                                                                                RL2_H_HI_SET: this.state.RL2_H_HI,
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );

                                                                                                    }


                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL2_H_HI_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>

                                                                                            {this.state.RL2_H_HI_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_H_HI_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                > Cancel
                                                                                                </CButton>
                                                                                                : ""}

                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={this.state.RL2_H_HI_SET_VISIBLE}
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol className="text-center"
                                                                                    >
                                                                                        <h4>{this.state.RL2_H_HI_SET}</h4>
                                                                                        <RangeSlider
                                                                                            value={this.state.RL2_H_HI_SET}
                                                                                            size="lg"
                                                                                            tooltipPlacement=""
                                                                                            min={this.state.RL2_H_LOW}
                                                                                            max={100}
                                                                                            step={stepbutton}
                                                                                            onChange={(e) => {
                                                                                                const { value } = e.target;
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL2_H_HI_SET: value,
                                                                                                    }),
                                                                                                    () => { }
                                                                                                );
                                                                                            }}
                                                                                            onAfterChange={() => {
                                                                                                //this.setState(
                                                                                                //    (state) => ({
                                                                                                //        RL2_H_HI:
                                                                                                //            state.RL2_H_HI_SET,
                                                                                                //    }),
                                                                                                //    () => {
                                                                                                //        this.publish(
                                                                                                //            "DEVICE/" +
                                                                                                //            this.state
                                                                                                //                .DEVICE_MODEL +
                                                                                                //            "/" +
                                                                                                //            this.state.DEVICE_TOKEN
                                                                                                //        );
                                                                                                //    }
                                                                                                //);
                                                                                            }}
                                                                                        />
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_h_low}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />
                                                                                        Low Humidity
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="6" md="5">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.state.RL2_H_LOW}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>

                                                                                        <CInputGroupAppend>
                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {

                                                                                                    if (this.state.RL2_H_LOW_SET_VISIBLE) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_H_LOW: this.state.RL2_H_LOW_SET,
                                                                                                                RL2_H_LOW_SET_VISIBLE: false,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state.DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state.DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );

                                                                                                    }
                                                                                                    else {

                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_H_LOW_SET_VISIBLE: true,
                                                                                                                RL2_H_LOW_SET: this.state.RL2_H_LOW,
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );

                                                                                                    }

                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL2_H_LOW_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>

                                                                                            {this.state.RL2_H_LOW_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_H_LOW_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                > Cancel
                                                                                                </CButton>
                                                                                                : ""}


                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                            <CCollapse
                                                                                show={this.state.RL2_H_LOW_SET_VISIBLE}
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol className="text-center"
                                                                                    >
                                                                                        <h4>{this.state.RL2_H_LOW_SET}</h4>
                                                                                        <RangeSlider
                                                                                            value={this.state.RL2_H_LOW_SET}
                                                                                            size="lg"
                                                                                            tooltipPlacement="top"
                                                                                            min={0}
                                                                                            max={this.state.RL2_H_HI}
                                                                                            step={stepbutton}
                                                                                            onChange={(e) => {
                                                                                                const { value } = e.target;
                                                                                                this.setState(
                                                                                                    (state) => ({
                                                                                                        RL2_H_LOW_SET: value,
                                                                                                    }),
                                                                                                    () => {
                                                                                                        // this.publish(
                                                                                                        //   "DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN
                                                                                                        // );
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            onAfterChange={() => {
                                                                                                //this.setState(
                                                                                                //    (state) => ({
                                                                                                //        RL2_H_LOW:
                                                                                                //            state.RL2_H_LOW_SET,
                                                                                                //    }),
                                                                                                //    () => {
                                                                                                //        this.publish(
                                                                                                //            "DEVICE/" +
                                                                                                //            this.state
                                                                                                //                .DEVICE_MODEL +
                                                                                                //            "/" +
                                                                                                //            this.state.DEVICE_TOKEN
                                                                                                //        );
                                                                                                //    }
                                                                                                //);
                                                                                            }}
                                                                                        />
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_play}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />
                                                                                        Play
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="6" md="5">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.formatSeconds(
                                                                                                        this.state.RL2_TIME_ON
                                                                                                    )}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>

                                                                                        <CInputGroupAppend>
                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this.state
                                                                                                            .RL2_TIME_ON_SET_VISIBLE
                                                                                                    ) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_TIME_ON:
                                                                                                                    state.RL2_TIME_ON_SET,
                                                                                                                RL2_TIME_ON_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL2_TIME_ON_SET_VISIBLE,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );
                                                                                                    } else {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_TIME_ON_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL2_TIME_ON_SET_VISIBLE,
                                                                                                                RL2_TIME_ON_SET: this
                                                                                                                    .state.RL2_TIME_ON,
                                                                                                                RL2_TIME_ON_SET_H: Math.floor(
                                                                                                                    this.state
                                                                                                                        .RL2_TIME_ON / 3600
                                                                                                                ),
                                                                                                                RL2_TIME_ON_SET_M: Math.floor(
                                                                                                                    (this.state
                                                                                                                        .RL2_TIME_ON %
                                                                                                                        3600) /
                                                                                                                    60
                                                                                                                ),
                                                                                                                RL2_TIME_ON_SET_S:
                                                                                                                    this.state
                                                                                                                        .RL2_TIME_ON % 60,
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL2_TIME_ON_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>

                                                                                            {this.state.RL2_TIME_ON_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_TIME_ON_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                > Cancel
                                                                                                </CButton>
                                                                                                : ""}

                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={
                                                                                    this.state.RL2_TIME_ON_SET_VISIBLE
                                                                                }
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol xs="5" md="6"></CCol>
                                                                                    <CCol xs="6" md="5">
                                                                                        <CInputGroup>
                                                                                            <CInputGroupPrepend>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Hour"
                                                                                                    value={
                                                                                                        this.state.RL2_TIME_ON_SET_H
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            e.target.value * 3600 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_ON_SET_M *
                                                                                                            60 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_ON_SET_S;
                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL2_TIME_ON_SET_H: Math.floor(
                                                                                                                sum / 3600
                                                                                                            ),
                                                                                                            RL2_TIME_ON_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timehourvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timehourvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Minutes"
                                                                                                    value={
                                                                                                        this.state.RL2_TIME_ON_SET_M
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL2_TIME_ON_SET_H *
                                                                                                            3600 +
                                                                                                            e.target.value * 60 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_ON_SET_S;

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL2_TIME_ON_SET_M: Math.floor(
                                                                                                                (sum % 3600) / 60
                                                                                                            ),
                                                                                                            RL2_TIME_ON_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timeminvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timeminvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>

                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Sec"
                                                                                                    value={
                                                                                                        this.state.RL2_TIME_ON_SET_S
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL2_TIME_ON_SET_H *
                                                                                                            3600 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_ON_SET_M *
                                                                                                            60 +
                                                                                                            parseInt(e.target.value);

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL2_TIME_ON_SET_S:
                                                                                                                sum % 60,
                                                                                                            RL2_TIME_ON_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timesecvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timesecvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                            </CInputGroupPrepend>
                                                                                        </CInputGroup>
                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>

                                                                            <CFormGroup row>
                                                                                <CCol xs="5" md="6">
                                                                                    <CLabel htmlFor="hf-email">
                                                                                        <CImg
                                                                                            src={icon_pause}
                                                                                            className={
                                                                                                styles.iconbullet + " mr-2"
                                                                                            }
                                                                                            fluid
                                                                                        />
                                                                                        Pause
                                                                                    </CLabel>
                                                                                </CCol>
                                                                                <CCol xs="6" md="5">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>
                                                                                            <CInputGroupText>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                >
                                                                                                    {this.formatSeconds(
                                                                                                        this.state.RL2_TIME_OFF
                                                                                                    )}
                                                                                                </span>
                                                                                            </CInputGroupText>
                                                                                        </CInputGroupPrepend>

                                                                                        <CInputGroupAppend>
                                                                                            <CButton
                                                                                                type="button"
                                                                                                size="sm"
                                                                                                color="success"
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this.state
                                                                                                            .RL2_TIME_OFF_SET_VISIBLE
                                                                                                    ) {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_TIME_OFF:
                                                                                                                    state.RL2_TIME_OFF_SET,
                                                                                                                RL2_TIME_OFF_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL2_TIME_OFF_SET_VISIBLE,
                                                                                                            }),
                                                                                                            () => {
                                                                                                                this.publish(
                                                                                                                    "DEVICE/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_MODEL +
                                                                                                                    "/" +
                                                                                                                    this.state
                                                                                                                        .DEVICE_TOKEN
                                                                                                                );
                                                                                                            }
                                                                                                        );
                                                                                                    } else {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_TIME_OFF_SET_VISIBLE: !this
                                                                                                                    .state
                                                                                                                    .RL2_TIME_OFF_SET_VISIBLE,
                                                                                                                RL2_TIME_OFF_SET: this
                                                                                                                    .state.RL2_TIME_OFF,
                                                                                                                RL2_TIME_OFF_SET_H: Math.floor(
                                                                                                                    this.state
                                                                                                                        .RL2_TIME_OFF / 3600
                                                                                                                ),
                                                                                                                RL2_TIME_OFF_SET_M: Math.floor(
                                                                                                                    (this.state
                                                                                                                        .RL2_TIME_OFF %
                                                                                                                        3600) /
                                                                                                                    60
                                                                                                                ),
                                                                                                                RL2_TIME_OFF_SET_S:
                                                                                                                    this.state
                                                                                                                        .RL2_TIME_OFF % 60,
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <CIcon name={this.state.RL2_TIME_OFF_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                            </CButton>
                                                                                            {this.state.RL2_TIME_OFF_SET_VISIBLE != false ?
                                                                                                <CButton
                                                                                                    type="button"
                                                                                                    size="sm"
                                                                                                    color="danger"

                                                                                                    onClick={() => {
                                                                                                        this.setState(
                                                                                                            (state) => ({
                                                                                                                RL2_TIME_OFF_SET_VISIBLE: false
                                                                                                            }),
                                                                                                            () => { }
                                                                                                        );
                                                                                                    }}
                                                                                                > Cancel
                                                                                                </CButton>
                                                                                                : ""}
                                                                                        </CInputGroupAppend>
                                                                                    </CInputGroup>
                                                                                </CCol>
                                                                            </CFormGroup>

                                                                            <CCollapse
                                                                                show={
                                                                                    this.state.RL2_TIME_OFF_SET_VISIBLE
                                                                                }
                                                                            >
                                                                                <CFormGroup row>
                                                                                    <CCol xs="5" md="6"></CCol>
                                                                                    <CCol xs="6" md="5">
                                                                                        <CInputGroup>
                                                                                            <CInputGroupPrepend>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Hour"
                                                                                                    value={
                                                                                                        this.state
                                                                                                            .RL2_TIME_OFF_SET_H
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            e.target.value * 3600 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_OFF_SET_M *
                                                                                                            60 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_OFF_SET_S;
                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL2_TIME_OFF_SET_H: Math.floor(
                                                                                                                sum / 3600
                                                                                                            ),
                                                                                                            RL2_TIME_OFF_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timehourvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timehourvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Minutes"
                                                                                                    value={
                                                                                                        this.state
                                                                                                            .RL2_TIME_OFF_SET_M
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL2_TIME_OFF_SET_H *
                                                                                                            3600 +
                                                                                                            e.target.value * 60 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_OFF_SET_S;

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL2_TIME_OFF_SET_M: Math.floor(
                                                                                                                (sum % 3600) / 60
                                                                                                            ),
                                                                                                            RL2_TIME_OFF_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timeminvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timeminvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>

                                                                                                <CSelect
                                                                                                    className={
                                                                                                        styles.forminputnumber
                                                                                                    }
                                                                                                    aria-label="Sec"
                                                                                                    value={
                                                                                                        this.state
                                                                                                            .RL2_TIME_OFF_SET_S
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let sum =
                                                                                                            this.state
                                                                                                                .RL2_TIME_OFF_SET_H *
                                                                                                            3600 +
                                                                                                            this.state
                                                                                                                .RL2_TIME_OFF_SET_M *
                                                                                                            60 +
                                                                                                            parseInt(e.target.value);

                                                                                                        if (sum > 9999) {
                                                                                                            sum = 9999;
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            RL2_TIME_OFF_SET_S:
                                                                                                                sum % 60,
                                                                                                            RL2_TIME_OFF_SET: sum,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    {timesecvalue.map(
                                                                                                        (
                                                                                                            { value, label },
                                                                                                            index
                                                                                                        ) => (
                                                                                                            <option key={"timesecvalue_" + index} value={value}>
                                                                                                                {label}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </CSelect>
                                                                                            </CInputGroupPrepend>
                                                                                        </CInputGroup>


                                                                                    </CCol>
                                                                                </CFormGroup>
                                                                            </CCollapse>
                                                                        </CForm>
                                                                    </div>
                                                                </CCol>
                                                            </CRow>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                                <CCard className="mb-0">
                                                    <CCardHeader>
                                                        <CImg
                                                            src={icon_reload}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        LOOP
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL2_MODE === 2}
                                                                    onClick={() => {
                                                                        if (this.state.RL2_MODE !== 2) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL2_MODE: 2,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL2_MODE: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL2_MODE === 2}>
                                                        {/* this.state.RL1_MODE === 2 */}
                                                        <CCardBody>
                                                            <CForm
                                                                action=""
                                                                method="post"
                                                                className="form-horizontal"
                                                            >
                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Play
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="6" md="5">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL2_TIME_ON
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state.RL2_TIME_ON_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL2_TIME_ON:
                                                                                                        state.RL2_TIME_ON_SET,
                                                                                                    RL2_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL2_TIME_ON_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL2_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL2_TIME_ON_SET_VISIBLE,
                                                                                                    RL2_TIME_ON_SET: this.state
                                                                                                        .RL2_TIME_ON,
                                                                                                    RL2_TIME_ON_SET_H: Math.floor(
                                                                                                        this.state.RL2_TIME_ON /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL2_TIME_ON_SET_M: Math.floor(
                                                                                                        (this.state.RL2_TIME_ON %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL2_TIME_ON_SET_S:
                                                                                                        this.state.RL2_TIME_ON % 60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL2_TIME_ON_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>
                                                                                {this.state.RL2_TIME_ON_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL2_TIME_ON_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    > Cancel
                                                                                    </CButton>
                                                                                    : ""}
                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL2_TIME_ON_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="6" md="5">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={this.state.RL2_TIME_ON_SET_H}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL2_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                this.state.RL2_TIME_ON_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL2_TIME_ON_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL2_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={this.state.RL2_TIME_ON_SET_M}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL2_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL2_TIME_ON_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL2_TIME_ON_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL2_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={this.state.RL2_TIME_ON_SET_S}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL2_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL2_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL2_TIME_ON_SET_S: sum % 60,
                                                                                                RL2_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>

                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_pause}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Pause
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="6" md="5">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL2_TIME_OFF
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state
                                                                                                .RL2_TIME_OFF_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL2_TIME_OFF:
                                                                                                        state.RL2_TIME_OFF_SET,
                                                                                                    RL2_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL2_TIME_OFF_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL2_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL2_TIME_OFF_SET_VISIBLE,
                                                                                                    RL2_TIME_OFF_SET: this.state
                                                                                                        .RL2_TIME_OFF,
                                                                                                    RL2_TIME_OFF_SET_H: Math.floor(
                                                                                                        this.state.RL2_TIME_OFF /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL2_TIME_OFF_SET_M: Math.floor(
                                                                                                        (this.state.RL2_TIME_OFF %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL2_TIME_OFF_SET_S:
                                                                                                        this.state.RL2_TIME_OFF %
                                                                                                        60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL2_TIME_OFF_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>
                                                                                {this.state.RL2_TIME_OFF_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL2_TIME_OFF_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    > Cancel
                                                                                    </CButton>
                                                                                    : ""}

                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL2_TIME_OFF_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="6" md="5">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={
                                                                                            this.state.RL2_TIME_OFF_SET_H
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL2_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                this.state.RL2_TIME_OFF_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL2_TIME_OFF_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL2_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={
                                                                                            this.state.RL2_TIME_OFF_SET_M
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL2_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL2_TIME_OFF_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL2_TIME_OFF_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL2_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={
                                                                                            this.state.RL2_TIME_OFF_SET_S
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL2_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL2_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL2_TIME_OFF_SET_S: sum % 60,
                                                                                                RL2_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>
                                                            </CForm>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>

                                                <CCard className="mb-0">
                                                    <CCardHeader>
                                                        <CImg
                                                            src={icon_wallclock}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        SCHEDULE
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL2_SCH === 1 ? true : false}
                                                                    onClick={() => {
                                                                        if (this.state.RL2_SCH !== 0) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL2_SCH: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish("DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN);
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL2_SCH: 1,
                                                                                }),
                                                                                () => {
                                                                                    this.publish("DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN);
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL2_SCH === 1 ? true : false}>
                                                        <CCardBody>
                                                            <CForm
                                                                action=""
                                                                method="post"
                                                                className="form-horizontal"
                                                            >


                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P1
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P1_S_HH, 2)}:{padLZero(this.state.RL2_P1_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P1_E_HH, 2)}:{padLZero(this.state.RL2_P1_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P1_VISIBLE) {

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P1_VISIBLE: false,
                                                                                                RL2_P1_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P1_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P1_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P1_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":1' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P1_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P1_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P1_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P1_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P1_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL2_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P1_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P1_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P1_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>

                                                                    </CCol>
                                                                    <CCol>
                                                                        <CCollapse show={this.state.RL2_P1_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P2
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P2_S_HH, 2)}:{padLZero(this.state.RL2_P2_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P2_E_HH, 2)}:{padLZero(this.state.RL2_P2_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P2_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P2_VISIBLE: false,
                                                                                                RL2_P2_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P2_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P2_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P2_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":2' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P2_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P2_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P2_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P2_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P2_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P2_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P2_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P2_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P2_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P2_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P2_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P2_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P2_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>

                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P3
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P3_S_HH, 2)}:{padLZero(this.state.RL2_P3_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P3_E_HH, 2)}:{padLZero(this.state.RL2_P3_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P3_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P3_VISIBLE: false,
                                                                                                RL2_P3_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P3_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P3_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P3_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":3' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P3_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P3_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P3_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P3_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P3_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P3_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P3_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P3_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P3_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P3_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P3_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P3_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P3_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>



                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P4
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P4_S_HH, 2)}:{padLZero(this.state.RL2_P4_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P4_E_HH, 2)}:{padLZero(this.state.RL2_P4_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P4_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P4_VISIBLE: false,
                                                                                                RL2_P4_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P4_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P4_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P4_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":4' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P4_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P4_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P4_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P4_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P4_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P4_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P4_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P4_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P4_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P4_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P4_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P4_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P4_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P5
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P5_S_HH, 2)}:{padLZero(this.state.RL2_P5_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P5_E_HH, 2)}:{padLZero(this.state.RL2_P5_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P5_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P5_VISIBLE: false,
                                                                                                RL2_P5_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P5_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P5_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P5_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":5' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P5_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P5_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P5_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P5_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P5_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P5_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P5_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P5_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}




                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P5_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>


                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P6
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P6_S_HH, 2)}:{padLZero(this.state.RL2_P6_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P6_E_HH, 2)}:{padLZero(this.state.RL2_P6_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P6_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P6_VISIBLE: false,
                                                                                                RL2_P6_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P6_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P6_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P6_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":6' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P6_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P6_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P6_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P6_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P6_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P6_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P6_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P6_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P6_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P6_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>


                                                                            {this.state.RL2_P6_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P6_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}



                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P6_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P7
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P7_S_HH, 2)}:{padLZero(this.state.RL2_P7_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P7_E_HH, 2)}:{padLZero(this.state.RL2_P7_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P7_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P7_VISIBLE: false,
                                                                                                RL2_P7_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P7_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P7_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P7_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":7' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P7_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P7_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P7_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P7_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P7_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P7_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P7_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P7_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P7_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P7_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>


                                                                            {this.state.RL2_P7_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P7_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P7_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P8
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P8_S_HH, 2)}:{padLZero(this.state.RL2_P8_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P8_E_HH, 2)}:{padLZero(this.state.RL2_P8_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P8_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P8_VISIBLE: false,
                                                                                                RL2_P8_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P8_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P8_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P8_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":8' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P8_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P8_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P8_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P8_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P8_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P8_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P8_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P8_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P8_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P9
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P9_S_HH, 2)}:{padLZero(this.state.RL2_P9_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P9_E_HH, 2)}:{padLZero(this.state.RL2_P9_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P9_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P9_VISIBLE: false,
                                                                                                RL2_P9_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P9_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P9_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P9_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":9' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P9_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P9_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P9_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P9_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL2_P9_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL2_P9_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P9_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P9_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P9_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P9_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P9_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P9_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P9_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P10
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P10_S_HH, 2)}:{padLZero(this.state.RL2_P10_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P10_E_HH, 2)}:{padLZero(this.state.RL2_P10_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P10_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P10_VISIBLE: !this.state.RL2_P10_VISIBLE,
                                                                                                RL2_P10_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P10_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P10_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P10_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":10' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P10_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P10_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P10_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P10_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P10_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL2_P10_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P10_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P10_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P10_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P10_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P10_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P10_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P10_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P11
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P11_S_HH, 2)}:{padLZero(this.state.RL2_P11_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P11_E_HH, 2)}:{padLZero(this.state.RL2_P11_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P11_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P11_VISIBLE: !this.state.RL2_P11_VISIBLE,
                                                                                                RL2_P11_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P11_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P11_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P11_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":11' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P11_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P11_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P11_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P11_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P11_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL2_P11_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P11_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P11_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P11_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P11_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL2_P11_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P11_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P11_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P12
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P12_S_HH, 2)}:{padLZero(this.state.RL2_P12_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P12_E_HH, 2)}:{padLZero(this.state.RL2_P12_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P12_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P12_VISIBLE: !this.state.RL2_P12_VISIBLE,
                                                                                                RL2_P12_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P12_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P12_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P12_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":12' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P12_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P12_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P12_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P12_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P12_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL2_P12_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P12_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P12_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P12_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P12_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL2_P12_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P12_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P12_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P13
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P13_S_HH, 2)}:{padLZero(this.state.RL2_P13_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P13_E_HH, 2)}:{padLZero(this.state.RL2_P13_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P13_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P13_VISIBLE: !this.state.RL2_P13_VISIBLE,
                                                                                                RL2_P13_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P13_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P13_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P13_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":13' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P13_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P13_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P13_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P13_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P13_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL2_P13_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P13_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P13_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P13_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P13_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL2_P13_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P13_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P13_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P14
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P14_S_HH, 2)}:{padLZero(this.state.RL2_P14_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P14_E_HH, 2)}:{padLZero(this.state.RL2_P14_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P14_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P14_VISIBLE: !this.state.RL2_P14_VISIBLE,
                                                                                                RL2_P14_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P14_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P14_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P14_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":14' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P14_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P14_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P14_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P14_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P14_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL2_P14_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P14_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P14_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P14_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P14_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL2_P14_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P14_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P14_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P15
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL2_P15_S_HH, 2)}:{padLZero(this.state.RL2_P15_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL2_P15_E_HH, 2)}:{padLZero(this.state.RL2_P15_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL2_P15_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P15_VISIBLE: false,
                                                                                                RL2_P15_S_HH: this.state.PX_S_HH,
                                                                                                RL2_P15_S_MM: this.state.PX_S_MM,
                                                                                                RL2_P15_E_HH: this.state.PX_E_HH,
                                                                                                RL2_P15_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":2' +
                                                                                                    ',"P":15' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL2_P15_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P15_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P15_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL2_P15_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P15_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL2_P15_S_HH,
                                                                                                PX_S_MM: this.state.RL2_P15_S_MM,
                                                                                                PX_E_HH: this.state.RL2_P15_E_HH,
                                                                                                PX_E_MM: this.state.RL2_P15_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL2_P15_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL2_P15_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL2_P15_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL2_P15_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>


                                                            </CForm>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        ) : (
                            ""
                        )}
                        {tabselect === 2 ? (
                            <div>
                                <h3>Smart Plug<br /><small className="text-success"> {Number(this.state.TEMP).toFixed(2) + "°c"} / {Number(this.state.HUMI).toFixed(2) + "%h"} / {dewpoint(this.state.TEMP, this.state.HUMI) + "dp"}  </small> <CButton className="float-right btn-danger" onClick={() => { this.setState({ tabselect: -1 }) }}> back </CButton></h3>
                                <CRow>
                                    <CCol>
                                        <div>
                                            <div id="accordion">
                                                <CCard className="mb-0">
                                                    <CCardHeader id="headingOne">
                                                        <CImg
                                                            src={icon_magicwand}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        AUTO
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL3_MODE === 1}
                                                                    onClick={() => {
                                                                        if (this.state.RL3_MODE !== 1) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL3_MODE: 1,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL3_MODE: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL3_MODE === 1}>
                                                        {/* this.state.RL1_MODE === 2 */}
                                                        <CCardBody>
                                                            <CForm
                                                                action=""
                                                                method="post"
                                                                className="form-horizontal"
                                                            >
                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Play
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="6" md="5">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL3_TIME_ON
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state.RL3_TIME_ON_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_ON:
                                                                                                        state.RL3_TIME_ON_SET,
                                                                                                    RL3_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_ON_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_ON_SET_VISIBLE,
                                                                                                    RL3_TIME_ON_SET: this.state
                                                                                                        .RL3_TIME_ON,
                                                                                                    RL3_TIME_ON_SET_H: Math.floor(
                                                                                                        this.state.RL3_TIME_ON /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL3_TIME_ON_SET_M: Math.floor(
                                                                                                        (this.state.RL3_TIME_ON %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL3_TIME_ON_SET_S:
                                                                                                        this.state.RL3_TIME_ON % 60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL3_TIME_ON_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>
                                                                                {this.state.RL3_TIME_ON_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_ON_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    > Cancel
                                                                                    </CButton>
                                                                                    : ""}
                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL3_TIME_ON_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="6" md="5">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={this.state.RL3_TIME_ON_SET_H}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL3_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                this.state.RL3_TIME_ON_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_ON_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL3_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={this.state.RL3_TIME_ON_SET_M}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL3_TIME_ON_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_ON_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL3_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={this.state.RL3_TIME_ON_SET_S}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL3_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_ON_SET_S: sum % 60,
                                                                                                RL3_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>

                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_pause}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Pause
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="6" md="5">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL3_TIME_OFF
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state
                                                                                                .RL3_TIME_OFF_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_OFF:
                                                                                                        state.RL3_TIME_OFF_SET,
                                                                                                    RL3_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_OFF_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_OFF_SET_VISIBLE,
                                                                                                    RL3_TIME_OFF_SET: this.state
                                                                                                        .RL3_TIME_OFF,
                                                                                                    RL3_TIME_OFF_SET_H: Math.floor(
                                                                                                        this.state.RL3_TIME_OFF /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL3_TIME_OFF_SET_M: Math.floor(
                                                                                                        (this.state.RL3_TIME_OFF %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL3_TIME_OFF_SET_S:
                                                                                                        this.state.RL3_TIME_OFF %
                                                                                                        60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL3_TIME_OFF_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>

                                                                                {this.state.RL3_TIME_OFF_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_OFF_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    > Cancel
                                                                                    </CButton>
                                                                                    : ""}

                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL3_TIME_OFF_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="6" md="5">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={
                                                                                            this.state.RL3_TIME_OFF_SET_H
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL3_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                this.state.RL3_TIME_OFF_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_OFF_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL3_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={
                                                                                            this.state.RL3_TIME_OFF_SET_M
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL3_TIME_OFF_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_OFF_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL3_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={
                                                                                            this.state.RL3_TIME_OFF_SET_S
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL3_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_OFF_SET_S: sum % 60,
                                                                                                RL3_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>
                                                            </CForm>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                                <CCard className="mb-0">
                                                    <CCardHeader>
                                                        <CImg
                                                            src={icon_reload}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        LOOP
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL3_MODE === 2}
                                                                    onClick={() => {
                                                                        if (this.state.RL3_MODE !== 2) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL3_MODE: 2,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL3_MODE: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish(
                                                                                        "DEVICE/" +
                                                                                        this.state.DEVICE_MODEL +
                                                                                        "/" +
                                                                                        this.state.DEVICE_TOKEN
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL3_MODE === 2}>
                                                        {/* this.state.RL1_MODE === 2 */}
                                                        <CCardBody>
                                                            <CForm
                                                                action=""
                                                                method="post"
                                                                className="form-horizontal"
                                                            >
                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Play
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="6" md="5">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL3_TIME_ON
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state.RL3_TIME_ON_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_ON:
                                                                                                        state.RL3_TIME_ON_SET,
                                                                                                    RL3_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_ON_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_ON_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_ON_SET_VISIBLE,
                                                                                                    RL3_TIME_ON_SET: this.state
                                                                                                        .RL3_TIME_ON,
                                                                                                    RL3_TIME_ON_SET_H: Math.floor(
                                                                                                        this.state.RL3_TIME_ON /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL3_TIME_ON_SET_M: Math.floor(
                                                                                                        (this.state.RL3_TIME_ON %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL3_TIME_ON_SET_S:
                                                                                                        this.state.RL3_TIME_ON % 60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL3_TIME_ON_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>

                                                                                {this.state.RL3_TIME_ON_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_ON_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    > Cancel
                                                                                    </CButton>
                                                                                    : ""}

                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL3_TIME_ON_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="6" md="5">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={this.state.RL3_TIME_ON_SET_H}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL3_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                this.state.RL3_TIME_ON_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_ON_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL3_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={this.state.RL3_TIME_ON_SET_M}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL3_TIME_ON_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_ON_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL3_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={this.state.RL3_TIME_ON_SET_S}
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_ON_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL3_TIME_ON_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_ON_SET_S: sum % 60,
                                                                                                RL3_TIME_ON_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>

                                                                <CFormGroup row>
                                                                    <CCol xs="5" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_pause}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            Pause
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="6" md="5">
                                                                        <CInputGroup>
                                                                            <CInputGroupPrepend>
                                                                                <CInputGroupText>
                                                                                    <span
                                                                                        className={styles.forminputnumber}
                                                                                    >
                                                                                        {this.formatSeconds(
                                                                                            this.state.RL3_TIME_OFF
                                                                                        )}
                                                                                    </span>
                                                                                </CInputGroupText>
                                                                            </CInputGroupPrepend>

                                                                            <CInputGroupAppend>
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            this.state
                                                                                                .RL3_TIME_OFF_SET_VISIBLE
                                                                                        ) {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_OFF:
                                                                                                        state.RL3_TIME_OFF_SET,
                                                                                                    RL3_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_OFF_SET_VISIBLE,
                                                                                                }),
                                                                                                () => {
                                                                                                    this.publish(
                                                                                                        "DEVICE/" +
                                                                                                        this.state.DEVICE_MODEL +
                                                                                                        "/" +
                                                                                                        this.state.DEVICE_TOKEN
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        } else {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_OFF_SET_VISIBLE: !this
                                                                                                        .state
                                                                                                        .RL3_TIME_OFF_SET_VISIBLE,
                                                                                                    RL3_TIME_OFF_SET: this.state
                                                                                                        .RL3_TIME_OFF,
                                                                                                    RL3_TIME_OFF_SET_H: Math.floor(
                                                                                                        this.state.RL3_TIME_OFF /
                                                                                                        3600
                                                                                                    ),
                                                                                                    RL3_TIME_OFF_SET_M: Math.floor(
                                                                                                        (this.state.RL3_TIME_OFF %
                                                                                                            3600) /
                                                                                                        60
                                                                                                    ),
                                                                                                    RL3_TIME_OFF_SET_S:
                                                                                                        this.state.RL3_TIME_OFF %
                                                                                                        60,
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CIcon name={this.state.RL3_TIME_OFF_SET_VISIBLE == true ? "cil-save" : "cil-settings"} />
                                                                                </CButton>

                                                                                {this.state.RL3_TIME_OFF_SET_VISIBLE != false ?
                                                                                    <CButton
                                                                                        type="button"
                                                                                        size="sm"
                                                                                        color="danger"

                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                (state) => ({
                                                                                                    RL3_TIME_OFF_SET_VISIBLE: false
                                                                                                }),
                                                                                                () => { }
                                                                                            );
                                                                                        }}
                                                                                    > Cancel
                                                                                    </CButton>
                                                                                    : ""}


                                                                            </CInputGroupAppend>
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CCollapse
                                                                    show={this.state.RL3_TIME_OFF_SET_VISIBLE}
                                                                >
                                                                    <CFormGroup row>
                                                                        <CCol xs="5" md="6"></CCol>
                                                                        <CCol xs="6" md="5">
                                                                            <CInputGroup>
                                                                                <CInputGroupPrepend>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Hour"
                                                                                        value={
                                                                                            this.state.RL3_TIME_OFF_SET_H
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                e.target.value * 3600 +
                                                                                                this.state.RL3_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                this.state.RL3_TIME_OFF_SET_S;
                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_OFF_SET_H: Math.floor(
                                                                                                    sum / 3600
                                                                                                ),
                                                                                                RL3_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timehourvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timehourvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Minutes"
                                                                                        value={
                                                                                            this.state.RL3_TIME_OFF_SET_M
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                e.target.value * 60 +
                                                                                                this.state.RL3_TIME_OFF_SET_S;

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_OFF_SET_M: Math.floor(
                                                                                                    (sum % 3600) / 60
                                                                                                ),
                                                                                                RL3_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timeminvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timeminvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>

                                                                                    <CSelect
                                                                                        className={styles.forminputnumber}
                                                                                        aria-label="Sec"
                                                                                        value={
                                                                                            this.state.RL3_TIME_OFF_SET_S
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            let sum =
                                                                                                this.state.RL3_TIME_OFF_SET_H *
                                                                                                3600 +
                                                                                                this.state.RL3_TIME_OFF_SET_M *
                                                                                                60 +
                                                                                                parseInt(e.target.value);

                                                                                            if (sum > 9999) {
                                                                                                sum = 9999;
                                                                                            }

                                                                                            this.setState({
                                                                                                RL3_TIME_OFF_SET_S: sum % 60,
                                                                                                RL3_TIME_OFF_SET: sum,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {timesecvalue.map(
                                                                                            ({ value, label }, index) => (
                                                                                                <option key={"timesecvalue_" + index} value={value}>
                                                                                                    {label}
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </CSelect>
                                                                                </CInputGroupPrepend>
                                                                            </CInputGroup>
                                                                        </CCol>
                                                                    </CFormGroup>
                                                                </CCollapse>
                                                            </CForm>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>

                                                <CCard className="mb-0">
                                                    <CCardHeader>
                                                        <CImg
                                                            src={icon_wallclock}
                                                            fluid
                                                            className={styles.iconbullet + " mr-2"}
                                                        />{" "}
                                                        SCHEDULE
                                                        <div className="card-header-actions">
                                                            <div className="card-header-action">
                                                                <CSwitch
                                                                    className={"mx-1"}
                                                                    shape={"pill"}
                                                                    color={"success"}
                                                                    labelOn={"ON"}
                                                                    labelOff={"OFF"}
                                                                    size="lg"
                                                                    onChange={() => { }}
                                                                    checked={this.state.RL3_SCH === 1 ? true : false}
                                                                    onClick={() => {
                                                                        if (this.state.RL3_SCH !== 0) {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL3_SCH: 0,
                                                                                }),
                                                                                () => {
                                                                                    this.publish("DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN);
                                                                                }
                                                                            );
                                                                        } else {
                                                                            this.setState(
                                                                                (state) => ({
                                                                                    RL3_SCH: 1,
                                                                                }),
                                                                                () => {
                                                                                    this.publish("DEVICE/" + this.state.DEVICE_MODEL + "/" + this.state.DEVICE_TOKEN);
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </CCardHeader>
                                                    <CCollapse show={this.state.RL3_SCH === 1 ? true : false}>
                                                        <CCardBody>
                                                            <CForm
                                                                action=""
                                                                method="post"
                                                                className="form-horizontal"
                                                            >


                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P1
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P1_S_HH, 2)}:{padLZero(this.state.RL3_P1_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P1_E_HH, 2)}:{padLZero(this.state.RL3_P1_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P1_VISIBLE) {

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P1_VISIBLE: false,
                                                                                                RL3_P1_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P1_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P1_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P1_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":1' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P1_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P1_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P1_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P1_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P1_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL3_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P1_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P1_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P1_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>

                                                                    </CCol>
                                                                    <CCol>
                                                                        <CCollapse show={this.state.RL3_P1_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P2
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P2_S_HH, 2)}:{padLZero(this.state.RL3_P2_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P2_E_HH, 2)}:{padLZero(this.state.RL3_P2_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P2_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P2_VISIBLE: false,
                                                                                                RL3_P2_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P2_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P2_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P2_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":2' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P2_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P2_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P2_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P2_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P2_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P2_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P2_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P2_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P2_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P2_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P2_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P2_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P2_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>

                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P3
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P3_S_HH, 2)}:{padLZero(this.state.RL3_P3_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P3_E_HH, 2)}:{padLZero(this.state.RL3_P3_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P3_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P3_VISIBLE: false,
                                                                                                RL3_P3_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P3_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P3_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P3_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":3' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P3_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P3_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P3_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P3_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P3_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P3_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P3_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P3_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P3_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P3_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P3_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P3_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P3_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>



                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P4
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P4_S_HH, 2)}:{padLZero(this.state.RL3_P4_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P4_E_HH, 2)}:{padLZero(this.state.RL3_P4_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P4_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P4_VISIBLE: false,
                                                                                                RL3_P4_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P4_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P4_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P4_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":4' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P4_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P4_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P4_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P4_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P4_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P4_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P4_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P4_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P4_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P4_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P4_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P4_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P4_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P5
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P5_S_HH, 2)}:{padLZero(this.state.RL3_P5_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P5_E_HH, 2)}:{padLZero(this.state.RL3_P5_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P5_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P5_VISIBLE: false,
                                                                                                RL3_P5_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P5_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P5_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P5_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":5' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P5_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P5_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P5_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P5_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {
                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P5_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P5_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P5_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P5_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}




                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P5_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>


                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P6
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P6_S_HH, 2)}:{padLZero(this.state.RL3_P6_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P6_E_HH, 2)}:{padLZero(this.state.RL3_P6_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P6_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P6_VISIBLE: false,
                                                                                                RL3_P6_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P6_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P6_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P6_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":6' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P6_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P6_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P6_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P6_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P6_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P6_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P6_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P6_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P6_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P6_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>


                                                                            {this.state.RL3_P6_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P6_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}



                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P6_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P7
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P7_S_HH, 2)}:{padLZero(this.state.RL3_P7_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P7_E_HH, 2)}:{padLZero(this.state.RL3_P7_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P7_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P7_VISIBLE: false,
                                                                                                RL3_P7_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P7_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P7_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P7_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":7' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P7_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P7_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P7_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P7_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P7_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P7_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P7_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P7_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P7_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P7_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>


                                                                            {this.state.RL3_P7_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P7_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P7_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P8
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P8_S_HH, 2)}:{padLZero(this.state.RL3_P8_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P8_E_HH, 2)}:{padLZero(this.state.RL3_P8_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P8_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P8_VISIBLE: false,
                                                                                                RL3_P8_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P8_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P8_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P8_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":8' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P8_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P8_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P8_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P8_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P8_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P1_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P1_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P1_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P1_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P8_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P8_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P8_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P8_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P9
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P9_S_HH, 2)}:{padLZero(this.state.RL3_P9_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P9_E_HH, 2)}:{padLZero(this.state.RL3_P9_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P9_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P9_VISIBLE: false,
                                                                                                RL3_P9_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P9_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P9_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P9_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":9' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P9_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P9_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P9_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P9_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({

                                                                                                RL3_P9_VISIBLE: true,

                                                                                                PX_S_HH: this.state.RL3_P9_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P9_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P9_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P9_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P9_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P9_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P9_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P9_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P10
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P10_S_HH, 2)}:{padLZero(this.state.RL3_P10_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P10_E_HH, 2)}:{padLZero(this.state.RL3_P10_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P10_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P10_VISIBLE: !this.state.RL3_P10_VISIBLE,
                                                                                                RL3_P10_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P10_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P10_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P10_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":10' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P10_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P10_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P10_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P10_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P10_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL3_P10_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P10_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P10_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P10_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P10_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P10_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P10_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P10_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P11
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P11_S_HH, 2)}:{padLZero(this.state.RL3_P11_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P11_E_HH, 2)}:{padLZero(this.state.RL3_P11_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P11_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P11_VISIBLE: !this.state.RL3_P11_VISIBLE,
                                                                                                RL3_P11_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P11_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P11_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P11_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":11' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P11_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P11_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P11_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P11_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P11_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL3_P11_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P11_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P11_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P11_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P11_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL3_P11_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P11_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P11_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P12
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P12_S_HH, 2)}:{padLZero(this.state.RL3_P12_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P12_E_HH, 2)}:{padLZero(this.state.RL3_P12_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P12_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P12_VISIBLE: !this.state.RL3_P12_VISIBLE,
                                                                                                RL3_P12_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P12_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P12_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P12_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":12' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P12_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P12_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P12_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P12_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P12_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL3_P12_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P12_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P12_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P12_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P12_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>

                                                                            {this.state.RL3_P12_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P12_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}


                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P12_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P13
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P13_S_HH, 2)}:{padLZero(this.state.RL3_P13_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P13_E_HH, 2)}:{padLZero(this.state.RL3_P13_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P13_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P13_VISIBLE: !this.state.RL3_P13_VISIBLE,
                                                                                                RL3_P13_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P13_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P13_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P13_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":13' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P13_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P13_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P13_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P13_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P13_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL3_P13_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P13_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P13_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P13_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P13_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL3_P13_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P13_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P13_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P14
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P14_S_HH, 2)}:{padLZero(this.state.RL3_P14_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P14_E_HH, 2)}:{padLZero(this.state.RL3_P14_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P14_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P14_VISIBLE: !this.state.RL3_P14_VISIBLE,
                                                                                                RL3_P14_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P14_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P14_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P14_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":14' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P14_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P14_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P14_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P14_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P14_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL3_P14_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P14_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P14_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P14_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P14_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL3_P14_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P14_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}

                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P14_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>

                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>

                                                                <CFormGroup row>
                                                                    <CCol xs="4" md="6">
                                                                        <CLabel htmlFor="hf-email">
                                                                            <CImg
                                                                                src={icon_play}
                                                                                className={styles.iconbullet + " mr-2"}
                                                                                fluid
                                                                            />
                                                                            P15
                                                                        </CLabel>
                                                                    </CCol>
                                                                    <CCol xs="8" md="6">

                                                                        <CInputGroup className="mb-1">

                                                                            <CInputGroupText>{padLZero(this.state.RL3_P15_S_HH, 2)}:{padLZero(this.state.RL3_P15_S_MM, 2)}</CInputGroupText>
                                                                            <CInputGroupText>{padLZero(this.state.RL3_P15_E_HH, 2)}:{padLZero(this.state.RL3_P15_E_MM, 2)}</CInputGroupText>
                                                                            <CButton type="button" color="success"
                                                                                onClick={() => {
                                                                                    if (this.state.RL3_P15_VISIBLE) {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P15_VISIBLE: false,
                                                                                                RL3_P15_S_HH: this.state.PX_S_HH,
                                                                                                RL3_P15_S_MM: this.state.PX_S_MM,
                                                                                                RL3_P15_E_HH: this.state.PX_E_HH,
                                                                                                RL3_P15_E_MM: this.state.PX_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                                let msg =
                                                                                                    '{"COMM":2' +
                                                                                                    ',"RL":3' +
                                                                                                    ',"P":15' +
                                                                                                    ',"PROG":[' +
                                                                                                    this.state.RL3_P15_S_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P15_S_MM +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P15_E_HH +
                                                                                                    ',' +
                                                                                                    this.state.RL3_P15_E_MM +
                                                                                                    ']' +
                                                                                                    '}';

                                                                                                this.publish_program(
                                                                                                    "DEVICE/" +
                                                                                                    this.state.DEVICE_MODEL +
                                                                                                    "/" +
                                                                                                    this.state.DEVICE_TOKEN
                                                                                                    , msg);
                                                                                            }
                                                                                        );
                                                                                    } else {

                                                                                        this.set_sch_visible_hide();

                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P15_VISIBLE: true,
                                                                                                PX_S_HH: this.state.RL3_P15_S_HH,
                                                                                                PX_S_MM: this.state.RL3_P15_S_MM,
                                                                                                PX_E_HH: this.state.RL3_P15_E_HH,
                                                                                                PX_E_MM: this.state.RL3_P15_E_MM,
                                                                                            }),
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            ><CIcon name={this.state.RL3_P15_VISIBLE == true ? "cil-save" : "cil-settings"} /></CButton>
                                                                            {this.state.RL3_P15_VISIBLE != false ?
                                                                                <CButton
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="danger"

                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            (state) => ({
                                                                                                RL3_P15_VISIBLE: false
                                                                                            }),
                                                                                            () => { }
                                                                                        );
                                                                                    }}
                                                                                >

                                                                                    Cancel
                                                                                </CButton>
                                                                                : ""}
                                                                        </CInputGroup>
                                                                    </CCol>
                                                                    <CCol>

                                                                        <CCollapse show={this.state.RL3_P15_VISIBLE}>
                                                                            <CFormGroup row>
                                                                                <CCol xs="4" md="6" xl="6" className=""></CCol>
                                                                                <CCol xs="8" md="6" xl="6" className="offset-md-6">
                                                                                    <CInputGroup>
                                                                                        <CInputGroupPrepend>

                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_S_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_S_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_S_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_S_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <span> - </span>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Hour"
                                                                                                value={
                                                                                                    this.state.PX_E_HH
                                                                                                }
                                                                                                onChange={(e) => {

                                                                                                    this.setState({
                                                                                                        PX_E_HH: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {phourvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"phourvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>
                                                                                            <CSelect
                                                                                                className={styles.forminputnumber}
                                                                                                aria-label="Minutes"
                                                                                                value={
                                                                                                    this.state.PX_E_MM
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        PX_E_MM: e.target.value
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                {timeminvalue.map(
                                                                                                    ({ value, label }, index) => (
                                                                                                        <option key={"timeminvalue_" + index} value={value}>
                                                                                                            {label}
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </CSelect>

                                                                                            <CButton type="button" color="info"
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        (state) => ({
                                                                                                            PX_S_HH: 0,
                                                                                                            PX_S_MM: 0,
                                                                                                            PX_E_HH: 0,
                                                                                                            PX_E_MM: 0,
                                                                                                        }),
                                                                                                        () => {

                                                                                                        }
                                                                                                    );

                                                                                                }}
                                                                                            >SET 0</CButton>
                                                                                        </CInputGroupPrepend>
                                                                                    </CInputGroup>


                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CCollapse>

                                                                    </CCol>
                                                                </CFormGroup>


                                                            </CForm>
                                                        </CCardBody>
                                                    </CCollapse>
                                                </CCard>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        ) : (
                            ""
                        )}

                    </CCardBody>

                    <CCardFooter>
                        <CRow className="text-center">
                            <CCol sm="4" className="col-4">
                                <ButtonVentilator
                                    btnColor="blue"
                                    type="outline"
                                    mode={this.state.RL1_MODE}
                                    status={this.state.RL1_ST_RL}
                                    tabselect={tabselect}
                                    titleprimary={this.state.DEVICE_R1_TITLE_PRIMARY}
                                    titlesecondary={this.state.DEVICE_R1_TITLE_SECONDARY}
                                    onClick={(e) => {

                                        if (!connecting) {
                                            if (this.state.tabselect != 0) {
                                                this.setState((state) => ({
                                                    tabselect: 0,
                                                }));
                                            } else {
                                                this.setState((state) => ({
                                                    tabselect: -1,
                                                }));
                                            }
                                        } else {
                                            this.setState((state) => ({
                                                tabselect: -1,
                                            }));
                                        }

                                    }}
                                ></ButtonVentilator>
                            </CCol>
                            <CCol sm="4" className="col-4">
                                <ButtonMistmaker
                                    btnColor="blue"
                                    type="outline"
                                    tabselect={tabselect}
                                    status={this.state.RL2_ST_RL}
                                    titleprimary={this.state.DEVICE_R2_TITLE_PRIMARY}
                                    titlesecondary={this.state.DEVICE_R2_TITLE_SECONDARY}
                                    onClick={(e) => {

                                        if (!connecting) {
                                            if (this.state.tabselect != 1) {
                                                this.setState((state) => ({
                                                    tabselect: 1,
                                                }));
                                            } else {
                                                this.setState((state) => ({
                                                    tabselect: -1,
                                                }));
                                            }
                                        } else {
                                            this.setState((state) => ({
                                                tabselect: -1,
                                            }));
                                        }
                                    }}
                                ></ButtonMistmaker>
                            </CCol>
                            <CCol sm="4" className="col-4">
                                <ButtonSmartPlug
                                    btnColor="blue"
                                    type="outline"
                                    btnType="timer"
                                    mode={this.state.RL3_MODE}
                                    tabselect={tabselect}
                                    status={this.state.RL3_ST_RL}
                                    titleprimary={this.state.DEVICE_R3_TITLE_PRIMARY}
                                    titlesecondary={this.state.DEVICE_R3_TITLE_SECONDARY}
                                    onClick={(e) => {
                                        if (!connecting) {
                                            if (this.state.tabselect != 2) {
                                                this.setState((state) => ({
                                                    tabselect: 2,
                                                }));
                                            } else {
                                                this.setState((state) => ({
                                                    tabselect: -1,
                                                }));
                                            }
                                        } else {
                                            this.setState((state) => ({
                                                tabselect: -1,
                                            }));
                                        }
                                    }}
                                ></ButtonSmartPlug>

                                {/*<ButtonLighting*/}
                                {/*    btnColor="blue"*/}
                                {/*    type="outline"*/}
                                {/*    btnType="smartplug"*/}
                                {/*    mode={this.state.RL3_MODE}*/}
                                {/*    tabselect={tabselect}*/}
                                {/*    status={this.state.RL3_ST_RL}*/}
                                {/*    titleprimary={this.state.DEVICE_R3_TITLE_PRIMARY}*/}
                                {/*    titlesecondary={this.state.DEVICE_R3_TITLE_SECONDARY}*/}
                                {/*    onClick={(e) => {*/}
                                {/*        if (!connecting) {*/}
                                {/*            if (this.state.tabselect != 2) {*/}
                                {/*                this.setState((state) => ({*/}
                                {/*                    tabselect: 2,*/}
                                {/*                }));*/}
                                {/*            } else {*/}
                                {/*                this.setState((state) => ({*/}
                                {/*                    tabselect: -1,*/}
                                {/*                }));*/}
                                {/*            }*/}
                                {/*        } else {*/}
                                {/*            this.setState((state) => ({*/}
                                {/*                tabselect: -1,*/}
                                {/*            }));*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*></ButtonLighting>*/}
                            </CCol>
                        </CRow>
                    </CCardFooter>


                </CCard>

                <CModal
                    show={modal}
                    onClick={(e) => {
                        this.setState((state) => ({
                            modal: false,
                        }));
                    }}
                >
                    <CModalHeader closeButton>
                        <CModalTitle>Modal title</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="primary">Do Something</CButton>{" "}
                        <CButton
                            color="secondary"
                            onClick={(e) => {
                                this.setState((state) => ({
                                    modal: false,
                                }));
                            }}
                        >
                            Cancel
                        </CButton>
                    </CModalFooter>
                </CModal>

                {/*{isAuthenticated ? (*/}
                {/*    <p> {userName} is login</p>*/}
                {/*) : (*/}
                {/*    <p> is not login</p>*/}
                {/*)*/}
                {/*}*/}
            </div>
        );
    }
}