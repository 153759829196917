import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import {
    CCard, CCardBody, CCardHeader, CCol, CButton,
    CContainer, CForm, CLabel, CInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react'

const axios = require("axios");



export class SiteDelete extends Component {
    static displayName = SiteDelete.name;

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            userName: null,
            loading: false,
            sysid: "",
            title: "",
            description: "",
            latitude: Number(0.00),
            longitude: Number(0.00),
            modalState: false,
            modalTitle: "",
            modalMessage: ""
        };
    }


    async componentDidMount() {
        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        const url = 'api/sites/' + this.props.match.params.id;

        axios.get(url, {
            headers: headers
        })
            .then((response) => {

                this.setState({
                    loading: false,
                    sysid: response.data.sysId,
                    title: response.data.title,
                    description: response.data.description,
                    latitude: response.data.latitude,
                    longitude: response.data.longitude
                });

            })
            .catch((error) => {

            });
    }


    static renderTable(pagemodels) {
        return (
            <>
            </>
        );
    }


    async onSubmit(e) {

        e.preventDefault();

        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        const data = {
            sysid: this.props.match.params.id
        };

        axios.delete('api/sites/' + this.props.match.params.id, data, {
            headers: headers
        })
            .then((response) => {

                if (response.data.status) {
                    this.props.history.push('/sites');
                } else {
                    this.setState(
                        (state) => ({
                            modalTitle: response.data.result,
                            modalMessage: response.data.message
                        }),
                        () => {
                            this.setState(
                                (state) => ({
                                    modalState: true,
                                }),
                                () => {

                                }
                            );
                        }
                    );

                }

            })
            .catch((error) => {

            });

    }

    render() {
        const { modalState, modalTitle, modalMessage } = this.state;
        return (
            <div>
                <CCard
                    
                    className="">
                    <CCardHeader
                        color="danger"
                        textColor="white"
                    >
                        Delete Site : {this.state.title}
                    </CCardHeader>
                    <CCardBody>
                        <CContainer fluid>
                            <CForm id="main"
                                onSubmit={this.onSubmit}
                                className="row g-3" autoComplete="off">
                                <CCol md={12}>
                                    <CLabel htmlFor="inputTitle">Title</CLabel>
                                    <CInput type="text" id="title" name="title" placeholder="" autoComplete="off" value={this.state.title}
                                        readOnly />
                                </CCol>
                                <CCol xs={12}>
                                    <CLabel htmlFor="description">Description</CLabel>
                                    <CInput id="description" name="description" placeholder="" autoComplete="off" value={this.state.description}

                                        readOnly maxLength="200" />
                                </CCol>
                                <CCol xs={12} md={6}>
                                    <CLabel htmlFor="longitude">Latitude (13.7192672)</CLabel>
                                    <CInput id="latitude" name="latitude" placeholder="" autoComplete="off" type="number" keyboardtype={'numeric'} step="0.0000001" value={this.state.latitude}

                                        readOnly maxLength="20" />
                                </CCol>
                                <CCol xs={12} md={6}>
                                    <CLabel htmlFor="longitude">Longitude (100.5186293)</CLabel>
                                    <CInput id="longitude" name="longitude" placeholder="" autoComplete="off" type="number" keyboardtype={'numeric'} step="0.0000001" value={this.state.longitude}

                                        readOnly maxLength="20" />
                                </CCol>
                                <CCol xs={12}>
                                    <CButton type="submit" className="btn btn-outline-danger">Delete</CButton>
                                    <CButton type="button" className="btn btn-outline-light ml-2" onClick={(e) => { this.props.history.push('/sites'); }}>Cancel</CButton>
                                </CCol>
                            </CForm>
                        </CContainer>
                    </CCardBody>
                </CCard>

                <CModal
                    show={modalState}
                    onClick={(e) => {
                        this.setState((state) => ({
                            modalState: false,
                        }));
                    }}
                >
                    <CModalHeader closeButton>
                        <CModalTitle>Site Deletetion {modalTitle}</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        {modalMessage}
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="primary"
                            onClick={(e) => {
                                this.setState((state) => ({
                                    modalState: false,
                                }));

                                this.props.history.push('/sites');
                            }}
                        >
                            OK
                        </CButton>
                    </CModalFooter>
                </CModal>
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/devices', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ pagemodels: data, loading: false });
    }
}
