import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import StatChart from "../charts/StatChart.js";
import {
    CButton,
    CButtonGroup,
} from "@coreui/react";


const axios = require("axios");
const BASE_URL = process.env.REACT_APP_API_SERVER;

export class DeviceStat extends Component {
    static displayName = DeviceStat.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            pagemodels: [],
            device: {
                sysid: "",
                title: "",
                description: "",
                serialno: "",
                devicetoken: "",
            },
            selectrange: "1D",
            userName: null,
            loading: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        this.interval = setInterval(this.populateData(), 5000);
        this.populateData();
/*        this.getData();*/
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
        clearInterval(this.interval);
    }


    //getData = () => {
    //    let url = BASE_URL + "/log/01/" + this.state.selectrange;

    //    console.log("url: ", url);
    //    axios({
    //        method: "get",
    //        url: url,
    //    })
    //        .then((response) => {
    //            this.setState({
    //                logmodel: response.data.data,
    //            });
    //        })
    //        .catch((err) => {
    //            console.error(err);
    //        });
    //};

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/log?id=' + this.props.match.params.id + '&ra=' + this.state.selectrange, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({
            pagemodels: data.data,
            device: data.data.device,
            loading: false
        });
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName, device, selectrange } = this.state;
        return (
            <div>

                <CCard className="">
                    <CCardHeader>
                        DEVICE STAT: {device.title}
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="5">
                                <h4 id="traffic" className="card-title mb-0">
                                    Temp. / Hum. / Dew.{" "}
                                </h4>
                                <div className="small text-muted">August 2020</div>
                            </CCol>
                            <CCol sm="7" className="d-none d-md-block">
                                <CButtonGroup className="float-right mr-3">
                                    {["6H", "1D", "1W", "1M", "3M", "6M", "1Y"].map((value) => (
                                        <CButton
                                            color="outline-secondary"
                                            key={value}
                                            className="mx-0"
                                            active={value === selectrange}
                                            onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        selectrange: value,
                                                    }),
                                                    () => {
                                                        this.populateData();
                                                    }
                                                );
                                            }}
                                        >
                                            {value}
                                        </CButton>
                                    ))}
                                </CButtonGroup>
                            </CCol>
                        </CRow>

                        <StatChart
                            style={{ height: "600px", marginTop: "40px" }}
                            data={this.state.pagemodels}
                        />
                    </CCardBody>
                </CCard>
                {isAuthenticated ? (
                    <p> {userName} is login</p>
                ) : (
                    <p> is not login</p>
                )
                }
            </div>
        );
    }
}

