import React, { Component, Fragment } from 'react';
/*import { NavItem, NavLink } from 'reactstrap';*/
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import {
    CSidebarNavItem,
} from "@coreui/react";

export class CLoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            roles : []
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            roles: user && user.role
        });
    }

    render() {
        const { isAuthenticated, userName, roles } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {

            if (roles && roles.includes("Administrator")) {

                const profilePath = `${ApplicationPaths.Profile}`;
                const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                return this.authenticatedAdminView(userName, profilePath, logoutPath, roles);

            } else {

                const profilePath = `${ApplicationPaths.Profile}`;
                const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                return this.authenticatedView(userName, profilePath, logoutPath, roles);

            }


        }
    }



    authenticatedAdminView(userName, profilePath, logoutPath, roles) {
        return (<Fragment>

            <CSidebarNavItem className="d-inline d-md-none d-lg-none d-xl-none" name={userName} to={profilePath} icon="cil-router" tag={Link} />
            <CSidebarNavItem name="Logout" to={logoutPath} icon="cil-account-logout" tag={Link} />
        </Fragment>);

    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>

            <CSidebarNavItem className="d-inline d-md-none d-lg-none d-xl-none" name={userName} to={profilePath} icon="cil-router" tag={Link} />
            <CSidebarNavItem name="Logout" to={logoutPath} icon="cil-account-logout" tag={Link} />

            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>

          {/*  <CSidebarNavItem name="Register" to={registerPath} icon="cil-router" tag={Link} />*/}
            <CSidebarNavItem name="Login" to={loginPath} icon="cil-router" tag={Link}/>

            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);
    }
}
