import React, { Component, useState } from 'react';
import {
    CModal, CModalHeader, CModalBody, CModalTitle, CModalFooter, CButton, CImg
} from "@coreui/react";

import icon_hero from "../assets/imgs/hero-img.png";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <section id="hero" className="hero d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column">
                                <h1 data-aos="fade-up">Add Device</h1>
                                <h2 data-aos="fade-up" data-aos-delay="0" className="text-warning">เพิ่มอุปกรณ์เข้าสู่ระบบ เพื่อเริ่มต้นการควบคุมสภาพแวดล้อมภายในบ้าน</h2>
                                <div data-aos="fade-up" data-aos-delay="0">
                                    <div className="text-center">
                                        <a href="create/device" className="btn btn-lg btn-outline-success  scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-2">
                                            <span>Add new device / เพิ่มอุปกรณ์</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex flex-column">
                                <h1 data-aos="fade-up">Add Site</h1>
                                <h2 data-aos="fade-up" data-aos-delay="0" className="text-warning">เพิ่มบ้านเข้าสู่ระบบ เพื่อจัดกลุ่มอุปกรณ์ให้ดูแลง่าย</h2>
                                <div data-aos="fade-up" data-aos-delay="0">
                                    <div className="text-center">
                                        <a href="create/site" className="btn btn-lg btn-outline-success scrollto d-inline-flex align-items-center justify-content-center align-self-center mt-2">
                                            <span>Add new site / เพิ่มบ้าน</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
