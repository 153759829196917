import React, { Component, Fragment } from 'react';
/*import { NavItem, NavLink } from 'reactstrap';*/
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import {
    CSidebarNavItem, CHeaderNavItem, CHeaderNavLink
} from "@coreui/react";

export class CUserMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            roles : []
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            roles: user.role
        });
    }

    render() {
        const { isAuthenticated, userName, roles } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath, roles);
        }
    }


    authenticatedView(userName, profilePath, logoutPath, roles) {
        return (<Fragment>

            <CHeaderNavItem className="px-3 d-md-down-none">
                <CHeaderNavLink to={profilePath}>{userName}</CHeaderNavLink>
            </CHeaderNavItem>

            <CHeaderNavItem className="px-3 d-md-down-none">
                <CHeaderNavLink to={logoutPath}>Logout</CHeaderNavLink>
            </CHeaderNavItem>

            {/*<CHeaderNavLink name={"Hello " + userName} to={profilePath} icon="cil-router" tag={Link} />*/}
            {/*<CHeaderNavLink name="Logout" to={logoutPath} icon="cil-account-logout" tag={Link} />*/}
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>

            {/*<CHeaderNavItem className="px-3">*/}
            {/*    <CHeaderNavLink to={profilePath}>{userName}</CHeaderNavLink>*/}
            {/*</CHeaderNavItem>*/}

            <CHeaderNavItem className="px-3 ">
                <CHeaderNavLink to={loginPath}>Login</CHeaderNavLink>
            </CHeaderNavItem>


          {/*  <CHeaderNavItem name="Register" to={registerPath} icon="cil-router" tag={Link} />*/}
          {/*  <CHeaderNavItem name="Login" to={loginPath} icon="cil-router" tag={Link}/>*/}
        </Fragment>);
    }
}
