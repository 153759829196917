import React, { Component } from "react";
import PropTypes from "prop-types";
import { range } from "lodash";
import { Group, Text } from "react-konva";
import {
  GaugeBackground,
  AnimatedGauge,
  GaugeMarks,
  LabeledMark,
  Gauge,
} from "../../AGauge";

export default class GuageHumidity extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    upperBound: PropTypes.number.isRequired,
    lowerBound: PropTypes.number.isRequired,
    numDigits: PropTypes.number,
    radius: PropTypes.number.isRequired,
    offsetBottom: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.element),
    touchRange: PropTypes.arrayOf(PropTypes.number),
    gaugeWidth: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    vlowlevel: PropTypes.number.isRequired,
    vlowalert: PropTypes.number.isRequired,
    vnormal: PropTypes.number.isRequired,
    vhighalert: PropTypes.number.isRequired,
  };

  render() {
    return (
      <Gauge
        width={this.props.width}
        height={this.props.width / 1.8}
        upperBound={this.props.upperBound}
        lowerBound={this.props.lowerBound}
        radius={this.props.width / 3.5}
        offsetBottom={this.props.width / 1.8 / 12}
        gaugeWidth={this.props.width / 17}
      >
        <Group>
          <GaugeBackground
            color="#333333"
            lowerBound={this.props.lowerBound}
            upperBound={this.props.upperBound}
            vlowlevel={this.props.vlowlevel}
            vlowalert={this.props.vlowalert}
            vnormal={this.props.vnormal}
            vhighalert={this.props.vhighalert}
          />
          <GaugeMarks
            count={(this.props.upperBound - this.props.lowerBound) / 10}
            offset={this.props.width * 0.03}
            length={this.props.width * 0.03}
            width={2}
            outerRadius={this.props.width / 3.5 + this.props.width * 0.03}
            color="#ff0000"
          >
            {range(360, 180 - 1, -this.props.valueStep).map((i) => (
              <LabeledMark
                key={i}
                angle={i}
                text={`${this.props.lowerBound +
                  (360 - i) / this.props.valueStep / 3.6}`}
                width={this.props.width / 100}
                color="#666666"
                textColor="#aaa"
                fontSize={8}
              />
            ))}
          </GaugeMarks>
        </Group>
        <AnimatedGauge
          value={this.props.value ? this.props.value : 0.0}
          color={
            this.props.value
              ? this.props.value >= this.props.vlowlevel
                ? this.props.value >= this.props.vlowalert
                  ? this.props.value >= this.props.vnormal
                    ? this.props.value >= this.props.vhighalert
                      ? "#880000"
                      : "#888800"
                    : "#008800"
                  : "#888800"
                : "#880000"
              : "#333333"
          }
          duration={500}
          adjustable
          // onChange={this.valueChange}
        >
          {({ value }) => (
            <Text
              stroke="black"
              strokeWidth="0.7"
              x={this.props.width / 2 - this.props.width / 4}
              y={this.props.width / 1.8 - this.props.width / 1.8 / 3.2}
              width={this.props.width / 2}
              text={`${value.toFixed(2)}%RH`}
              fontSize={this.props.width / 10}
              align="center"
              fill="#05dfff"
              fontStyle="bold"
            />
          )}
        </AnimatedGauge>
      </Gauge>
    );
  }
}
