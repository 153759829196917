import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    CCreateElement,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarNavDivider,
    CSidebarNavTitle,
    CSidebarMinimizer,
    CSidebarNavDropdown,
    CSidebarNavItem,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    CImg,
} from "@coreui/react";

import authService from '../components/api-authorization/AuthorizeService';
import CIcon from "@coreui/icons-react";
import { useHistory } from "react-router-dom";
import { CLoginMenu } from "../components/api-authorization/CLoginMenu"
import imgLogo from "../assets/imgs/logoheader.png";

import navigation from "./_nav";

const TheSidebar = (props) => {
    const dispatch = useDispatch();
    const show = useSelector((state) => state.sidebarShow);

    const [state, setState] = useState(0);
    const [modal, setModal] = useState(false);
    const history = useHistory();


    const [isAuthenticated, setisAuthenticated] = useState(0);
    const [role, setRole] = useState(null);


    const loadAsyncData = async () => {

    }


    const populateState = async () => {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser()
        ])
        setisAuthenticated(isAuthenticated);
        setRole(user && user.role);
    }


    useEffect(() => {
        loadAsyncData();
        authService.subscribe(() => this.populateState());
        populateState();
    }, []);

    useLayoutEffect(() => {
        return () => {

        }
    }, [])


    const handleLogout = () => {
        setModal(!modal);
    };

    const handleLogin = () => {

    };

    const confirmLogout = () => {
        const _state = { ...state };
        setState({ ..._state, isLoggedIn: false });
        history.push("/login");
        setModal(!modal);
    };

    const toggle = () => {
        setModal(!modal);
    };

    return (
        <>
            <CSidebar
                show={show}
                unfoldable
                onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
            >
                <CSidebarBrand className="d-md-down-none" to="/">
                    <div> <CImg src={imgLogo} className="d-block" height="40px"></CImg></div>
                    <CIcon
                        className="c-sidebar-brand-minimized"
                        name="sygnet"
                        height={35}
                    />
                </CSidebarBrand>
                <CSidebarNav>
                    <CCreateElement
                        items={navigation}
                        components={{
                            CSidebarNavDivider,
                            CSidebarNavDropdown,
                            CSidebarNavItem,
                            CSidebarNavTitle,
                        }}
                    />
                    {(isAuthenticated) ?
                    role && role.includes("Administrators") ? (

                        <CSidebarNavDropdown
                            name="Administrators"
                            className="CSidebarNavDropdown"
                            icon="cil-settings"
                            color="ff0000"
                        >

                        <CSidebarNavItem
                            name="Devices Master"
                            className="CSidebarNavItem"
                                icon="cil-router"
                                color="ff0000"
                                to="/master/devices"
                                />

                                <CSidebarNavItem
                                    name="Users"
                                    className="CSidebarNavItem"
                                    icon="cil-user"
                                    color="ff0000"
                                    to="/administrators/users"
                                />
                        </CSidebarNavDropdown>

                        ) : (<></>)

                    : (<></>)}
                    <CLoginMenu>
                    </CLoginMenu>
                </CSidebarNav>
                <CSidebarMinimizer className="c-d-md-down-none" />
            </CSidebar>
            <CModal show={modal} onClose={toggle}>
                <CModalHeader closeButton>Confirm Logout</CModalHeader>
                <CModalBody>Are you sure you want to log out?</CModalBody>
                <CModalFooter>
                    <CButton color="primary" onClick={confirmLogout}>
                      Yes, Logout
                    </CButton>{" "}
                    <CButton color="secondary" onClick={toggle}>
                        Cancel
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default React.memo(TheSidebar);
