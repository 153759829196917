import React, { Component, Suspense } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'
import {
    TheSidebar,
    TheAside,
    TheFooter,
    TheHeader
} from '../containers/index'
import { CContainer } from "@coreui/react";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse">Loading</div>
    </div>
);

export class LayoutBlank extends Component {
    static displayName = LayoutBlank.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
        }
    }

    //async componentDidMount() {
    //    this._subscription = authService.subscribe(() => this.populateState());
    //    this.populateState();

    //}

    //componentWillUnmount() {
    //    authService.unsubscribe(this._subscription);
    //}

    //async populateState() {
    //    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    //    this.setState({
    //        isAuthenticated,
    //        userName: user && user.name
    //    });

    //    if (!isAuthenticated) {

    //    }
    //}





    render() {
        const { isAuthenticated, userName } = this.state;

        const classes = classNames(
            'c-app c-default-layout',
            'c-dark-theme'
        )
        return (
                <div className={classes}>
                    <div className="c-body">
                        <main className="c-main">
                            <CContainer fluid>
                                <Suspense fallback={loading}>
                                    {this.props.children}
                                </Suspense>
                            </CContainer>
                        </main>
                    </div>
                </div>
        );
    }
}
