import React, { Component} from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    CCard, CCardBody, CCardHeader, CRow, CCol, CButton,
    CContainer, CForm,  CLabel, CInput, CSelect
} from "@coreui/react";


const axios = require("axios");

export class Device extends Component {
    static displayName = Device.name;

    constructor(props) {
        super(props);


        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            userName: null,
            loading: false,
            sysid: "",
            title: "",
            description: "",
            serialno: "",
            devicetoken: "",
            r1: "",
            r1s: "",
            r2: "",
            r2s: "",
            r3: "",
            r3s : "",
            model: "",
            siteid: "00000000-0000-0000-0000-000000000000",
            sitemodels: [],
            modalState: false,
            modalTitle: "",
            modalMessage: ""
        };
    }


    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        this.populateData();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        const url = 'api/devices/' + this.props.match.params.id;

        axios.get(url, {
            headers: headers
        })
            .then((response) => {

                console.log(response.data)

                this.setState({
                    loading: false,
                    sysid: response.data.sysId,
                    title: response.data.title,
                    description: response.data.description,
                    serialno: response.data.serialno.toUpperCase(),
                    devicetoken: response.data.devicetoken.toUpperCase(),
                    siteid: response.data.siteId,
                    model: response.data.model,
                    r1: response.data.r1,
                    r1s: response.data.r1s,
                    r2: response.data.r2,
                    r2s: response.data.r2s,
                    r3: response.data.r3,
                    r3s: response.data.r3s,
                });

                axios.get("api/sites/getall", {
                    headers: headers
                })
                    .then((responsesite) => {
                        this.setState({
                            sitemodels: responsesite.data
                        });
                    })
                    .catch((errorsite) => {

                    });

            })
            .catch((error) => {

            });


    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }


    async onSubmit(e) {
        //debugger;

        e.preventDefault();

        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }



        const data = {
            sysid: this.state.sysid,
            title: this.state.title,
            description: this.state.description,
            serialno: this.state.serialno,
            devicetoken: this.state.devicetoken,
            model: this.state.model,
            siteid: this.state.siteid,
            r1: this.state.r1,
            r1s: this.state.r1s,
            r2: this.state.r2,
            r2s: this.state.r2s,
            r3: this.state.r3,
            r3s: this.state.r3s
        };

        console.log(data);

        debugger;

        axios.post('api/devices/post', data, {
            headers: headers
        })
            .then((response) => {
                debugger;
                this.props.history.push('/devices')
            })
            .catch((error) => {
                debugger;
                console.log(error);
            })

    }

    render() {
        const { sitemodels, modalState, modalTitle, modalMessage } = this.state;

        return (
            <>
                <CCard>
                    <CCardHeader>
                        <h4 id="traffic" className="card-title mb-0">
                            Device : {this.state.title}
                        </h4>
                    </CCardHeader>
                    <CCardBody>
                        <CContainer fluid>


                            <CForm id="main"
                                onSubmit={this.onSubmit}
                                className="row g-3" autoComplete="off">
                                <CCol md={12}>
                                    <CLabel htmlFor="title">Title</CLabel>
                                    <CInput type="text" id="title" name="title" placeholder="(ชั้น, ห้อง, โซน)" autoComplete="off" value={this.state.title}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    title: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required maxLength="60" />
                                </CCol>
                                <CCol xs={12}>
                                    <CLabel htmlFor="description">Description</CLabel>
                                    <CInput id="description" name="description" placeholder="(ทำใบ, ทำดอก)" autoComplete="off" value={this.state.description}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    description: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        maxLength="200" />
                                </CCol>
                                <CCol md={4}>
                                    <CLabel htmlFor="model">Model</CLabel>
                                    <CSelect id="model" name="model" value={this.state.model} readOnly
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    model: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}

                                    >
                                        <option value="AR_L3">AR L3</option>
                                    </CSelect>
                                </CCol>
                                <CCol md={4}>
                                    <CLabel htmlFor="serialno">Serial No</CLabel>
                                    <CInput id="serialno" name="serialno" placeholder="Serial Number ด้านหลังกล่อง (ตัวอย่าง: ARLXXXXX)" autoComplete="off" value={this.state.serialno}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    serialno: value.replace(/\s/g, '').toUpperCase(),
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required readOnly />
                                </CCol>
                                <CCol md={4}>
                                    <CLabel htmlFor="devicetoken">Device Token</CLabel>
                                    <CInput id="devicetoken" name="devicetoken" placeholder="Device Token ด้านหลังกล่อง (ตัวอย่าง: XXXXXX9999YY777)" autoComplete="off" value={this.state.devicetoken}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    devicetoken: value.replace(/\s/g, '').toUpperCase(),
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required readOnly />
                                </CCol>
                                
                                <CCol md={4}>
                                    <CLabel htmlFor="r1">R1 Title (ไม่ระบุจะแสดงเป็น 'Ventilator')</CLabel>
                                    <CInput id="r1" name="r1" placeholder="" autoComplete="off" value={this.state.r1}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    r1: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                         />
                                    </CCol>

                                <CCol md={4}>
                                    <CLabel htmlFor="r2">R2 Title (ไม่ระบุจะแสดงเป็น 'Mist Maker')</CLabel>
                                    <CInput id="r2" name="r2" placeholder="" autoComplete="off" value={this.state.r2}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    r2: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                    />
                                </CCol>

                                <CCol md={4}>
                                    <CLabel htmlFor="r3">R3 Title (ไม่ระบุจะแสดงเป็น 'Smart Plug')</CLabel>
                                    <CInput id="r3" name="r3" placeholder="" autoComplete="off" value={this.state.r3}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    r3: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                    />
                                </CCol>

                               

                                <CCol md={4}>
                                    <CLabel htmlFor="siteid">Site</CLabel>
                                    <CSelect id="siteid" name="siteid" value={this.state.siteid}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    siteid: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}

                                    >
                                        <option key={"sitemodel_0"} value="00000000-0000-0000-0000-000000000000">
                                            - Site -
                                        </option>
                                        {sitemodels.map((item, index) =>
                                            <option key={"sitemodel_" + index} value={item.sysId}>
                                                {item.title}
                                            </option>
                                        )}
                                    </CSelect>
                                </CCol>
                                <CCol xs={12}>
                                    <CButton type="submit" className="btn btn-outline-primary">Submit</CButton>
                                    <CButton type="button" className="btn btn-outline-light ml-2" onClick={(e) => { this.props.history.push('/devices'); }}>Cancel</CButton>
                                </CCol>
                            </CForm>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </>
        );
    }
}