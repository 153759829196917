import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import {
    CCard, CCardBody, CCardHeader, CCol, CRow, CLink, CButton, CButtonGroup, CSpinner,
    CContainer, CForm, CFormGroup, CLabel, CInput, CFormText, CSelect, CInputCheckbox
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
const axios = require("axios");

export class MasterDeviceEdit extends Component {
    static displayName = MasterDeviceEdit.name;

    constructor(props) {
        super(props);
        this.state = { pagemodels: [], loading: true };
    }

    componentDidMount() {
        this.populateData();
    }


    onSubmit(e) {
        debugger;
        e.preventDefault();
        const obj = {
            Id: this.props.match.params.id,
            Name: this.state.Name,
            RollNo: this.state.RollNo,
            Class: this.state.Class,
            Address: this.state.Address

        };
        axios.post('http://localhost:52564/Api/Student/AddotrUpdatestudent/', obj)
            .then(res => console.log(res.data));
        debugger;
        this.props.history.push('/Studentlist')
    }

    static renderTable(pagemodels) {
        return (
            <>

            </>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em>  <CSpinner color="warning" size="xl" /></p>
            : MasterDeviceEdit.renderTable(this.state.pagemodels);

        return (
            <div>
                <CCard className="">
                    <CCardHeader>
                        CREATE SITE
                    </CCardHeader>
                    <CCardBody>
                        <CContainer fluid>
                            <CForm className="row g-3" autoComplete="off" onSubmit={this.onSubmit}>
                                <CCol md={12}>
                                    <CLabel htmlFor="inputTitle">Title</CLabel>
                                    <CInput type="text" id="inputTitle" autoComplete="off" />
                                </CCol>
                                <CCol xs={12}>
                                    <CLabel htmlFor="inputDescription">Description</CLabel>
                                    <CInput id="inputDescription" placeholder="" autoComplete="off" />
                                </CCol>
                                <CCol xs={12}>
                                    <CLabel htmlFor="inputAddress2">Address 2</CLabel>
                                    <CInput id="inputAddress2" placeholder="Apartment, studio, or floor" autoComplete="off" />
                                </CCol>
                                <CCol md={6}>
                                    <CLabel htmlFor="inputCity">City</CLabel>
                                    <CInput id="inputCity" autoComplete="off" />
                                </CCol>
                                <CCol md={4}>
                                    <CLabel htmlFor="inputState">State</CLabel>
                                    <CSelect id="inputState">
                                        <option>Choose...</option>
                                        <option>AR_L3</option>
                                        <option>AR_L4</option>
                                        <option>AR_L5</option>
                                    </CSelect>
                                </CCol>
                                <CCol md={2}>
                                    <CLabel htmlFor="inputZip">Zip</CLabel>
                                    <CInput id="inputZip" autoComplete="off" />
                                </CCol>
                                <CCol xs={12}>
                                    <CButton type="submit" className="btn btn-primary">Submit</CButton>
                                </CCol>
                            </CForm>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/devices', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ pagemodels: data, loading: false });
    }
}
