import React, { useState, useEffect } from "react";

import { render } from "react-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnicaTheme from "highcharts/themes/dark-unica";

Highcharts.theme = DarkUnicaTheme.options;

// var viewdata = [];
// var dataDATE = [];
// var dataTEMP = [];
// var dataHUMI = [];
// var dataDEWPOINT = [];
// var dataRL1 = [];
// var dataRL2 = [];
// var dataRL3 = [];

Highcharts.theme = {
  colors: [
    "#ff0066",
    "#00AA00",
    "#1E90FF",
    "#CC6600",
    "#aaeeee",
    "#AA0066",
    "#eeaaee",
    "#55BF3B",
    "#DF5353",
    "#7798BF",
    "#aaeeee",
  ],
  chart: {
    backgroundColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      },
      stops: [
        [0, "#152b3a"],
        [1, "#232D2E"],
      ],
    },
    style: {
      fontFamily: "'Unica One', sans-serif, Tahoma",
    },
    plotBorderColor: "#606063",
  },
  title: {
    style: {
      color: "#E0E0E3",
      // textTransform: "uppercase",
      fontSize: "20px",
    },
  },
  subtitle: {
    style: {
      color: "#E0E0E3",
      // textTransform: "uppercase",
    },
  },
  xAxis: {
    minRange: 1,
    gridLineColor: "#707073",
    crosshair: true,
    labels: {
      step: 0,
      style: {
        color: "#E0E0E3",
      },
    },
    top: "-10%",
    lineColor: "#707073",
    minorGridLineColor: "#505053",
    tickColor: "#707073",
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  yAxis: {
    min: null,
    gridLineColor: "#707073",
    labels: {
      style: {
        color: "#E0E0E3",
      },
    },
    lineColor: "#707073",
    minorGridLineColor: "#505053",
    tickColor: "#707073",
    tickWidth: 1,
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    style: {
      color: "#F0F0F0",
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: "#F0F0F3",
        style: {
          fontSize: "13px",
        },
      },
      marker: {
        enabled: false,
        lineColor: "#333",
      },
    },
    boxplot: {
      fillColor: "#505053",
    },
    candlestick: {
      lineColor: "white",
    },
    errorbar: {
      color: "white",
    },
  },
  legend: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    itemStyle: {
      color: "#E0E0E3",
    },
    itemHoverStyle: {
      color: "#FFF",
    },
    itemHiddenStyle: {
      color: "#606063",
    },
    title: {
      style: {
        color: "#C0C0C0",
      },
    },
  },
  credits: {
    style: {
      color: "#666",
    },
  },
  labels: {
    style: {
      color: "#707073",
    },
  },

  drilldown: {
    activeAxisLabelStyle: {
      color: "#F0F0F3",
    },
    activeDataLabelStyle: {
      color: "#F0F0F3",
    },
  },

  navigation: {
    buttonOptions: {
      symbolStroke: "#DDDDDD",
      theme: {
        fill: "#505053",
      },
    },
  },

  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: "#505053",
      stroke: "#000000",
      style: {
        color: "#CCC",
      },
      states: {
        hover: {
          fill: "#707073",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
        select: {
          fill: "#000003",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
      },
    },
    inputBoxBorderColor: "#505053",
    inputStyle: {
      backgroundColor: "#333",
      color: "silver",
    },
    labelStyle: {
      color: "silver",
    },
  },

  navigator: {
    handles: {
      backgroundColor: "#666",
      borderColor: "#AAA",
    },
    outlineColor: "#CCC",
    maskFill: "rgba(255,255,255,0.1)",
    series: {
      color: "#7798BF",
      lineColor: "#A6C7ED",
    },
    xAxis: {
      gridLineColor: "#505053",
    },
  },

  scrollbar: {
    barBackgroundColor: "#808083",
    barBorderColor: "#808083",
    buttonArrowColor: "#CCC",
    buttonBackgroundColor: "#606063",
    buttonBorderColor: "#606063",
    rifleColor: "#FFF",
    trackBackgroundColor: "#404043",
    trackBorderColor: "#404043",
  },
};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);

const StatChart = ({
  data,
  children,
  onClick,
  labelColor,
  disabled,
  status,
  imgBtn,
  tabselect,
  style,
  mode,
  ...props
}) => {
  return (
    <div style={{ height: "600px" }}>
      <HighchartsReact
        highcharts={Highcharts}
        // constructorType={"stockChart"}
        options={{
          chart: {
            type: "areaspline",
            marginBottom: 80,
            animation: false,
            height: 600,
          },

          title: {
            text: "Temperature & Humidity Monitoring",
            textcolor: [0, 170, 88, 88],
          },
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            x: 0,
            y: 10,
            floating: true,
            borderWidth: 1,
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
          },
          rangeSelector: {
            enabled: false,
            // allButtonsEnabled: true,
            // inputEnabled: true,
            // Animation: false,
            // buttons: [
            //   {
            //     type: "hour",
            //     count: 1,
            //     text: " 1H ",
            //   },
            //   {
            //     type: "hour",
            //     count: 6,
            //     text: " 6H ",
            //   },
            //   {
            //     type: "day",
            //     count: 1,
            //     text: " 1D ",
            //   },
            //   {
            //     type: "day",
            //     count: 7,
            //     text: " 1W ",
            //   },
            //   {
            //     type: "month",
            //     count: 1,
            //     text: " 1M ",
            //   },
            //   {
            //     type: "month",
            //     count: 3,
            //     text: " 3M ",
            //   },
            //   {
            //     type: "month",
            //     count: 6,
            //     text: " 6M ",
            //   },
            //   {
            //     type: "all",
            //     text: " 1Y ",
            //   },
            // ],
            // selected: 0,
          },
          xAxis: {
            //minRange: 720,
            categories: data.log_dt,
            plotBands: [
              {
                // visualize the weekend
                from: 4.5,
                to: 6.5,
                color: "rgba(68, 170, 213, .2)",
              },
            ],
          },
          yAxis: [
            {
              // Primary yAxis
              min: null,
              labels: {
                format: "{value}°C",

                style: {
                  color: Highcharts.getOptions().colors[0],
                },
              },
              title: {
                text: "Temperature",
                style: {
                  color: Highcharts.getOptions().colors[0],
                },
              },
              opposite: false,
              height: "60%",
              lineWidth: 2,
            },

            {
              // Second yAxis
              min: null,
              labels: {
                format: "{value}%RH",
                style: {
                  color: Highcharts.getOptions().colors[1],
                },
              },
              title: {
                text: "Humidity",
                style: {
                  color: Highcharts.getOptions().colors[1],
                },
              },
              opposite: true,
              height: "60%",
              lineWidth: 2,
            },
            {
              min: null,
              labels: {
                format: "{value}",

                style: {
                  color: Highcharts.getOptions().colors[2],
                },
              },
              title: {
                text: "Dewpoint",
                style: {
                  color: Highcharts.getOptions().colors[2],
                },
              },
              opposite: false,
              height: "60%",
              lineWidth: 2,
            },
            {
              min: 0,
              title: {
                text: "R1",
              },
              labels: {
                formatter: function() {
                  if (this.value !== 1) {
                    return "OFF";
                  } else {
                    return "ON";
                  }
                },
                style: {
                  color: Highcharts.getOptions().colors[3],
                },
              },
              top: "64%",
              height: "6%",
              offset: 0,
              lineWidth: 2,
              step: "left",
              max: 1,
            },
            {
              min: 0,
              title: {
                text: "R2",
              },
              labels: {
                formatter: function() {
                  if (this.value !== 1) {
                    return "OFF";
                  } else {
                    return "ON";
                  }
                },
                style: {
                  color: Highcharts.getOptions().colors[3],
                },
              },
              top: "74%",
              height: "6%",
              offset: 0,
              lineWidth: 2,
              step: "left",
              max: 1,
            },
            {
              min: 0,
              title: {
                text: "R3",
              },
              labels: {
                formatter: function() {
                  if (this.value !== 1) {
                    return "OFF";
                  } else {
                    return "ON";
                  }
                },
                style: {
                  color: Highcharts.getOptions().colors[3],
                },
              },
              top: "84%",
              height: "6%",
              offset: 0,
              lineWidth: 2,
              step: "left",
              max: 1,
            },
          ],
          tooltip: {
            shared: true,
            valueSuffix: "",
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            areaspline: {
              fillOpacity: 0.25,
            },
          },
          series: [
            {
              name: "Temperature",
              data: data.temp,
            },
            {
              name: "Humidity",
              data: data.humi,
              yAxis: 1,
            },
            {
              name: "Dewpoint",
              data: data.dewpoint,
            },
            {
              type: "line",
              name: "R1",
              data: data.rl1,
              yAxis: 3,
              step: "left",
              marker: {
                enabled: false,
                radius: 4,
              },
              dataLabels: {
                enabled: false,
                formatter: function() {
                  if (this.y > 0) {
                    return this.y;
                  }
                },
              },
              plotOptions: {
                series: {
                  boostThreshold: 700000,
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      if (this.y > 0) {
                        return this.y;
                      }
                    },
                  },
                },
              },
            },
            {
              type: "line",
              name: "R2",
              data: data.rl2,
              yAxis: 4,
              step: "left",
              marker: {
                enabled: false,
                radius: 4,
              },
              dataLabels: {
                enabled: false,
                formatter: function() {
                  if (this.y > 0) {
                    return this.y;
                  }
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      if (this.y > 0) {
                        return this.y;
                      }
                    },
                  },
                },
              },
            },
            {
              type: "line",
              name: "R3",
              data: data.rl3,
              yAxis: 5,
              step: "left",
              marker: {
                enabled: false,
                radius: 4,
              },
              dataLabels: {
                enabled: false,
                formatter: function() {
                  if (this.y > 0) {
                    return this.y;
                  }
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      if (this.y > 0) {
                        return this.y;
                      }
                    },
                  },
                },
              },
            },
          ],
        }}
        theme={"dark-unica"}
      />
    </div>
  );
};

export default StatChart;
