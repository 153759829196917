import React from "react";
import styles from "./styles/button.module.css";
import { CButton, CImg } from "@coreui/react";

import imgPLUGON from "../../../assets/imgs/plug_on.png";
import imgPLUGOFF from "../../../assets/imgs/plug_off.png";

import imgTIMERONFRONT from "../../../assets/imgs/timer_on_front.png";
import imgTIMERONBACK from "../../../assets/imgs/timer_on_back.png";
import imgTIMEROFFFRONT from "../../../assets/imgs/timer_off_front.png";
import imgTIMEROFFBACK from "../../../assets/imgs/timer_off_back.png";

import imgLIGHTINGON from "../../../assets/imgs/lamp_on.png";
import imgLIGHTINGOFF from "../../../assets/imgs/lamp.png";

/*import imgTIMERON from "../../../assets/imgs/timer_on.gif";*/
import imgTIMEROFF from "../../../assets/imgs/timer_off.png";

const BottonSmartPlug = ({
    children,
    onClick,
    labelColor,
    disabled,
    btnType,
    status,
    imgBtn,
    tabselect,
    style,
    mode,
    titleprimary,
    titlesecondary,
    ...props
}) => {
    return (
        <CButton className="m-2" variant="ghost" onClick={onClick}>
            <div className={styles.imgFrame}>
                <CImg
                    src={
                        btnType === "timer"
                            ? mode !== 0
                                ? status === 1
                                    ? imgTIMERONFRONT
                                    : imgTIMEROFFFRONT
                                : imgTIMEROFF
                            : status === 1
                                ? imgPLUGON
                                : imgPLUGOFF
                    }
                    width="100%"
                    fluid
                    style={{ maxWidth: "100px" }}
                    className={"mb-2 " + styles.timerfront}
                />

                <CImg
                    src={
                        btnType === "timer"
                            ? status === 1
                                ? imgTIMERONBACK
                                : imgTIMEROFFBACK
                            : status === 1
                                ? imgPLUGON
                                : imgPLUGOFF
                    }
                    width="100%"
                    fluid
                    style={{ maxWidth: "100px" }}
                    className={
                        (status === 1 ? styles.statustimeractive : styles.statustimer) +
                        " mb-2 " +
                        styles.timerback
                    }

                />

            </div>
            <strong
                className={tabselect === 2 ? styles.textactive : styles.textinactive}
            >
                {titleprimary != "" ? titleprimary : "Smart Plug"}             
            </strong>
        </CButton>
    );
};
{/*hidden={mode === 0}*/ }
export default BottonSmartPlug;
