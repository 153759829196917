import React, { Component } from 'react';
/*import { Route } from 'react-router';*/
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Layout } from './components/Layout';
import { LayoutBlank } from './components/LayoutBlank';
import { Home } from './components/Home';
//import { FetchData } from './components/FetchData';
//import { Counter } from './components/Counter';
import authService from './components/api-authorization/AuthorizeService';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { Sites } from './components/sites/Sites';
import { Site } from './components/sites/Site';
import { SiteCreate } from './components/sites/Create';
import { SiteEdit } from './components/sites/Edit';
import { SiteDelete } from './components/sites/Delete';


import { Devices } from './components/devices/Devices';
import { Device } from './components/devices/Device';
import { DeviceStat } from './components/devices/DeviceStat';
import { DeviceCreate } from './components/devices/Create';
import { DeviceEdit } from './components/devices/Edit';
import { DeviceDelete } from './components/devices/Delete';



import { MasterDevices } from './components/master/Devices';
import { MasterDevice } from './components/master/Device';
import { MasterDeviceCreate } from './components/master/Create';
import { MasterDeviceEdit } from './components/master/Edit';
import { MasterDeviceDelete } from './components/master/Delete';

import { UsersUsers } from './components/users/Users';
import { UsersUser } from './components/users/User';

import "./scss/style.scss";
import './custom.css'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
        }
    }

    async componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });

        if (!isAuthenticated) {

        }
    }

    render() {
        const { isAuthenticated, userName } = this.state;


        return (
            !isAuthenticated ?
                <LayoutBlank>
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <AuthorizeRoute exact path='/' component={Home} />
                </LayoutBlank>
                :
                <Layout>
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <AuthorizeRoute exact path='/' component={Home} />
                    <AuthorizeRoute exact path='/create/site' component={SiteCreate} />
                    <AuthorizeRoute exact path='/edit/site/:id' component={SiteEdit} />
                    <AuthorizeRoute exact path='/delete/site/:id' component={SiteDelete} />
                    <AuthorizeRoute exact path='/sites' component={Sites} />
                    <AuthorizeRoute exact path='/sites/:id' component={Site} />
                    <AuthorizeRoute exact path='/sites/:id/:did' component={Site} />

                    <AuthorizeRoute exact path='/create/device' component={DeviceCreate} />
                    <AuthorizeRoute exact path='/delete/device/:id' component={DeviceDelete} />
                    <AuthorizeRoute exact path='/edit/device/:id' component={DeviceEdit} />
                    <AuthorizeRoute exact path='/devices' component={Devices} />
                    <AuthorizeRoute exact path='/devices/:id/stat' component={DeviceStat} />
                    <AuthorizeRoute exact path='/devices/:id' component={Device} />


                    <AuthorizeRoute exact path='/master/create/device' component={MasterDeviceCreate} />
                    <AuthorizeRoute exact path='/master/delete/device/:id' component={MasterDeviceDelete} />
                    <AuthorizeRoute exact path='/master/edit/device/:id' component={MasterDeviceEdit} />
                    <AuthorizeRoute exact path='/master/devices' component={MasterDevices} />
                    <AuthorizeRoute exact path='/master/devices/:id' component={MasterDevice} />

                    <AuthorizeRoute exact path='/administrators/users' component={UsersUsers} />
                   {/* <AuthorizeRoute exact path='/administrators/users/:id' component={UsersUser} />*/}
                </Layout>
        );
    }
}


//<Layout>

//    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
//    <AuthorizeRoute exact path='/' component={Home} />
//    <AuthorizeRoute exact path='/create/site' component={SiteCreate} />
//    <AuthorizeRoute exact path='/edit/site/:id' component={SiteEdit} />
//    <AuthorizeRoute exact path='/sites' component={Sites} />
//    <AuthorizeRoute exact path='/sites/:id' component={Site} />
//    <AuthorizeRoute exact path='/sites/:id/:did' component={Site} />

//    <AuthorizeRoute exact path='/create/device' component={DeviceCreate} />
//    <AuthorizeRoute exact path='/edit/device/:id' component={DeviceEdit} />
//    <AuthorizeRoute exact path='/devices' component={Devices} />
//    <AuthorizeRoute exact path='/devices/:id/stat' component={DeviceStat} />
//    <AuthorizeRoute exact path='/devices/:id' component={Device} />

//</Layout>
