import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { CCard, CCardBody, CCardHeader, CLink, CButtonGroup, CSpinner } from '@coreui/react'

const axios = require("axios");

export class Sites extends Component {
    static displayName = Sites.name;

    constructor(props) {
        super(props);
        this.state = {
            pagemodels: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps) {
        this.populateData();
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        const url = 'api/sites/getall';

        axios.get(url, {
            headers: headers
        })
            .then((response) => {

                this.setState({
                    loading: false,
                    pagemodels: response.data
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    pagemodels: []
                });
            });
    }

    static renderTable(pagemodels) {
        return (

            <table className='table table-bordered table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr className="bg-dark">
                        <th>#</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Location</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                    {pagemodels != null ? pagemodels.map((item, index) =>
                        <tr key={item.sysId}>
                            <td className="text-white">{index + 1}</td>
                            <td className="text-white">
                                <CLink to={"./sites/" + item.sysId} className="">
                                    {item.title}
                                </CLink>
                            </td>
                            <td className="text-white">
                                {item.description}
                            </td>
                            <td className="text-white">
                                <CLink href={"https://www.google.com/maps/search/" + item.latitude + "," + item.longitude + "/@" + item.latitude + "," + item.longitude + ",17z?hl=en-TH"}
                                    target="_blank">
                                    {item.latitude},{item.longitude}
                                </CLink>
                            </td>
                            <td>
                                <CButtonGroup role="group" aria-label="Basic mixed styles example">
                                    <CLink className="btn btn-outline-warning" to={"/edit/site/" + item.sysId}> Edit </CLink>
                                    <CLink className="btn btn-outline-danger" to={"/delete/site/" + item.sysId}> Delete </CLink>
                                </CButtonGroup>
                            </td>
                        </tr>
                    ) : <></>}
                </tbody>
            </table>
        );
    }

    render() {
        const { pagemodels } = this.state;


        let contents = this.state.loading
            ? <div><em>Loading...</em>  <CSpinner color="warning" size="xl" /></div>
            : Sites.renderTable(pagemodels);

        return (
            <div>
                <CCard className="">
                    <CCardHeader>
                        <h4 id="traffic" className="card-title mb-0">Sites   <CLink className="float-right btn btn-outline-warning" to="/create/site"> Create Site </CLink> </h4>
                    </CCardHeader>
                    <CCardBody>
                        {contents}
                    </CCardBody>
                </CCard >
            </div>
        );
    }


}
