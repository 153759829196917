import 'bootstrap/dist/css/bootstrap.css';
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { icons } from "./assets/icons";
import store from "./store";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

React.icons = icons;

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </Provider>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

