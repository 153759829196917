import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import {
    CCard, CCardBody, CCardHeader, CCol, CRow, CLink, CButton, CButtonGroup, CSpinner,
    CContainer, CForm, CFormGroup, CLabel, CInput, CFormText, CSelect, CInputCheckbox
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
const axios = require("axios");

export class SiteCreate extends Component {
    static displayName = SiteCreate.name;

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            pagemodels: [],
            loading: true,
            userName: null,
            sysid: "",
            title: "",
            description: "",
            latitude: Number(0.00),
            longitude: Number(0.00)
        };
    }


    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        /*        this.populateData();*/
    }


    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }



    static renderTable(pagemodels) {
        return (
            <>
            </>
        );
    }

    //async populateData() {
    //    const token = await authService.getAccessToken();
    //    const response = await fetch('api/devices', {
    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //    });
    //    const data = await response.json();
    //    this.setState({ pagemodels: data, loading: false });
    //}


    async onSubmit(e) {
        debugger;

        e.preventDefault();

        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        debugger;
        const data = {
            sysid: null,
            title: this.state.title,
            description: this.state.description,
            latitude: this.state.latitude,
            longitude: this.state.longitude
        };
        debugger;
        axios.post('api/sites/post', data, {
            headers: headers
        })
            .then((response) => {
                debugger;
            })
            .catch((error) => {
                debugger;
            })

        this.props.history.push('/sites')

        debugger;
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em>  <CSpinner color="warning" size="xl" /></p>
            : SiteCreate.renderTable(this.state.pagemodels);

        return (
            <div>
                <CCard className="">
                    <CCardHeader>
                        <h4 id="traffic" className="card-title mb-0"> Create Site</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CContainer fluid>
                            <CForm id="main"
                                onSubmit={this.onSubmit}
                                className="row g-3" autoComplete="off">
                                <CCol md={12}>
                                    <CLabel htmlFor="inputTitle">Title</CLabel>
                                    <CInput type="text" id="title" name="title" placeholder="" autoComplete="off" value={this.state.title}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    title: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required />
                                </CCol>
                                <CCol xs={12}>
                                    <CLabel htmlFor="description">Description</CLabel>
                                    <CInput id="description" name="description" placeholder="" autoComplete="off" value={this.state.description}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    description: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}

                                        maxLength="200" />
                                </CCol>
                                <CCol xs={12} md={6}>
                                    <CLabel htmlFor="longitude">Latitude (13.7192672)</CLabel>
                                    <CInput id="latitude" name="latitude" placeholder="" autoComplete="off" type="number" keyboardType={'numeric'} step="0.0000001"  value={this.state.latitude}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}

                                        onChange={(e) => {
                                            const { value } = e.target;

                                            if (value.match(/\./g)) {
                                                const [, decimal] = value.split('.');

                                                if (decimal?.length > 7) {
                                                    return;
                                                }
                                            }

                                            this.setState(
                                                (state) => ({
                                                    latitude: value.replace(/\s/g, ''),
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required maxLength="20" />
                                </CCol>
                                <CCol xs={12} md={6}>
                                    <CLabel htmlFor="longitude">Longitude (100.5186293)</CLabel>
                                    <CInput id="longitude" name="longitude" placeholder="" autoComplete="off" type="number" keyboardType={'numeric'} step="0.0000001"  value={this.state.longitude}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}

                                        onChange={(e) => {
                                            const { value } = e.target;

                                            if (value.match(/\./g)) {
                                                const [, decimal] = value.split('.');

                                                if (decimal?.length > 7) {
                                                    return;
                                                }
                                            }


                                            this.setState(
                                                (state) => ({
                                                    longitude: value.replace(/\s/g, ''),
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required maxLength="20" />
                                </CCol>
                                <CCol xs={12}>
                                    <CButton type="submit" className="btn btn-primary">Submit</CButton>
                                    <CButton type="button" className="btn btn-outline-light ml-2" onClick={(e) => { this.props.history.push('/sites'); }}>Cancel</CButton>
                                </CCol>
                            </CForm>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </div>
        );
    }


}
