import React from "react";
import styles from "./styles/button.module.css";
import { CButton, CImg } from "@coreui/react";

import imgVentilateON from "../../../assets/imgs/ventilation_on.png";
import imgVentilateOFF from "../../../assets/imgs/ventilation_off.png";

import imgHeaterON from "../../../assets/imgs/heater_on.png";
import imgHeaterOFF from "../../../assets/imgs/heater_off.png";

const ButtonVentilator = ({
    children,
    onClick,
    labelColor,
    disabled,
    status,
    imgBtn,
    tabselect,
    style,
    mode,
    titleprimary,
    titlesecondary,
    ...props
}) => {
    return (
        <CButton className="m-2" variant="ghost" onClick={onClick}>
            <div>
                {mode === 3 ? (
                    <CImg
                        src={status === 1 ? imgHeaterON : imgHeaterOFF}
                        width="100%"
                        fluid
                        style={{ maxWidth: "100px" }}
                        className="mb-2"
                    />
                ) : (
                    <CImg
                        src={status === 1 ? imgVentilateON : imgVentilateOFF}
                        width="100%"
                        fluid
                        style={{ maxWidth: "100px" }}
                        className={
                            status === 1
                                ? styles.statusonventilator + " mb-2"
                                : styles.statusoffventilator + " mb-2"
                        }
                    />
                )}
            </div>

            <strong
                className={tabselect === 0 ? styles.textactive : styles.textinactive}
            >
                {mode === 3 ? (titlesecondary != "" ? titlesecondary : "Heater") : (titleprimary != "" ? titleprimary : "Ventilator")}
            </strong>
        </CButton>
    );
};

export default ButtonVentilator;
