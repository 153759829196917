import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    CHeader,
    CHeaderBrand,
    CHeaderNav,
    CHeaderNavItem,
    CHeaderNavLink,
    CSubheader,
    CToggler,
    CBreadcrumbRouter,
    CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { CUserMenu } from "../components/api-authorization/CUserMenu"
import imgLogo from "../assets/imgs/logoheader.png";

// routes config
/*import routes from "../routes";*/
/*import { logout } from "../middlewares/auth";*/

const TheHeader = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const asideShow = useSelector((state) => state.asideShow);
    const sidebarShow = useSelector((state) => state.sidebarShow);
    const [state, setState] = useState(0);

    //const iLogin = isLogin();

    const toggleSidebar = () => {
        const val = [true, "responsive"].includes(sidebarShow)
            ? false
            : "responsive";
        dispatch({ type: "set", sidebarShow: val });
    };

    const toggleSidebarMobile = () => {
        const val = [false, "responsive"].includes(sidebarShow)
            ? true
            : "responsive";
        dispatch({ type: "set", sidebarShow: val });
    };

    //const handleLogout = () => {
    //  logout();
    //  const _state = { ...state };
    //  //let isLoggedIn = _state.isLoggedIn;
    //  setState({ ..._state, isLoggedIn: false });
    //  history.push("/login");
    //};

    return (
        <CHeader withSubheader>
            <CToggler
                inHeader
                className="ml-md-3 d-lg-none"
                onClick={toggleSidebarMobile}
            />
            <CToggler
                inHeader
                className="ml-3 d-md-down-none"
                onClick={toggleSidebar}
            />
            <CHeaderBrand className="mx-auto d-lg-none" to="/">
                <div>
                    <CImg src={imgLogo} className="d-block" height="40px"></CImg>
                </div>
                {/* <CIcon name="logo" height="48" alt="Logo"/> */}
            </CHeaderBrand>

            {/* <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav> */}

            <CHeaderNav className="d-md-down-none mr-auto">
                {/* <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem> */}
                <CHeaderNavItem className="px-3">
                    <CHeaderNavLink to="/devices">Devices</CHeaderNavLink>
                </CHeaderNavItem>
                <CHeaderNavItem className="px-3">
                    <CHeaderNavLink to="/sites">Sites</CHeaderNavLink>
                </CHeaderNavItem>

                {/* <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/administrators/users">Users</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/settings">Settings</CHeaderNavLink>
        </CHeaderNavItem> */}
                {/* {iLogin ? 
                   <CHeaderNavItem className="px-3">
                   <CHeaderNavLink  onClick={handleLogout} to="/settings">
                   </CHeaderNavLink>
        </CHeaderNavItem>
                    : ""
                } */}
            </CHeaderNav>

            <CHeaderNav className="px-3">
                <CUserMenu>
                </CUserMenu>

                {/*<CToggler*/}
                {/*    inHeader*/}
                {/*    className="ml-3 d-md-down-none"*/}
                {/*    onClick={() => dispatch({ type: 'set', darkMode: !darkMode })}*/}
                {/*    title="Toggle Light/Dark Mode"*/}
                {/*>*/}
                {/*    <CIcon name="cil-moon" className="c-d-dark-none" alt="CoreUI Icons Moon" />*/}
                {/*    <CIcon name="cil-sun" className="c-d-default-none" alt="CoreUI Icons Sun" />*/}
                {/*</CToggler>*/}
                {/*<CToggler*/}
                {/*    inHeader*/}
                {/*    className="d-md-down-none"*/}
                {/*    onClick={() => dispatch({ type: "set", asideShow: !asideShow })}*/}
                {/*>*/}
                {/*    <CIcon className="mr-2" size="lg" name="cil-applications-settings" />*/}
                {/*</CToggler>*/}
            </CHeaderNav>
            <CSubheader className="px-3 justify-content-between">
                {/*<CBreadcrumbRouter*/}
                {/*  className="border-0 c-subheader-nav m-0 px-0 px-md-3"*/}
                {/*  routes={routes}*/}
                {/*/>*/}
                {/* <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink className="c-subheader-nav-link"href="#">
              <CIcon name="cil-speech" alt="Settings" />
            </CLink>   
            <CLink 
              className="c-subheader-nav-link" 
              aria-current="page" 
              to="/dashboard"
            >
              <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
            
            <CLink className="c-subheader-nav-link" href="#">
              <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
            </CLink>
          </div> */}
            </CSubheader>
        </CHeader>
    );
};

export default TheHeader;
