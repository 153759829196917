import React from "react";
import PropTypes from "prop-types";
import { Arc } from "react-konva";
import { ValueArc } from ".";

export default function GaugeBackground({
  color,
  width,
  valUtils,
  gaugeRadius,
  center,
  lowerBound,
  upperBound,
  vlowlevel,
  vlowalert,
  vnormal,
  vhighalert,
}) {
  const ll = (180 / (upperBound - lowerBound)) * (vlowlevel - lowerBound);
  const la = (180 / (upperBound - lowerBound)) * (vlowalert - vlowlevel);
  const nr = (180 / (upperBound - lowerBound)) * (vnormal - vlowalert);
  const ha = (180 / (upperBound - lowerBound)) * (vhighalert - vnormal);
  const hh = (180 / (upperBound - lowerBound)) * (upperBound - vhighalert);
  const w = 1.5;

  return (
    <>
      <ValueArc
        color={color}
        width={width}
        valUtils={valUtils}
        gaugeRadius={gaugeRadius}
        center={center}
        value={valUtils.upperBound}
      />
      <Arc
        {...center}
        innerRadius={gaugeRadius + width / w}
        outerRadius={gaugeRadius + width}
        fill={"#883333"}
        stroke={"#883333"}
        strokeWidth={0}
        rotation={180}
        angle={ll}
      />
      <Arc
        {...center}
        innerRadius={gaugeRadius + width / w}
        outerRadius={gaugeRadius + width}
        fill={"#888833"}
        stroke={"#888833"}
        strokeWidth={0}
        rotation={180 + ll}
        angle={la}
      />
      <Arc
        {...center}
        innerRadius={gaugeRadius + width / w}
        outerRadius={gaugeRadius + width}
        fill={"green"}
        stroke={"green"}
        strokeWidth={0}
        rotation={180 + ll + la}
        angle={nr}
      />
      <Arc
        {...center}
        innerRadius={gaugeRadius + width / w}
        outerRadius={gaugeRadius + width}
        fill={"#888833"}
        stroke={"#888833"}
        strokeWidth={0}
        rotation={180 + ll + la + nr}
        angle={ha}
      />
      <Arc
        {...center}
        innerRadius={gaugeRadius + width / w}
        outerRadius={gaugeRadius + width}
        fill={"#883333"}
        stroke={"#883333"}
        strokeWidth={0}
        rotation={180 + ll + la + nr + ha}
        angle={hh}
      />
    </>
  );
}

GaugeBackground.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number,
  gaugeRadius: PropTypes.number,
  center: PropTypes.object,
  valUtils: PropTypes.object,
};
