import React, { Component, useState, form } from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    CCard, CCardBody, CCardHeader, CCol, CRow, CLink, CButton, CButtonGroup, CSpinner,
    CContainer, CForm, CFormGroup, CLabel, CInput, CFormText, CSelect, CInputCheckbox,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";


const axios = require("axios");
const BASE_URL = process.env.REACT_APP_API_SERVER;

export class MasterDeviceDelete extends Component {
    static displayName = MasterDeviceDelete.name;

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            userName: null,
            loading: false,
            sysid: "",
            title: "",
            description: "",
            serialno: "",
            devicetoken: "",
            model: "",
            siteid: "00000000-0000-0000-0000-000000000000",
            sitemodels: [],
            modalState: false,
            modalTitle: "",
            modalMessage: ""
        };
    }


    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        this.populateData();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        const url = 'api/masterdevices/' + this.props.match.params.id;

        axios.get(url, {
            headers: headers
        })
            .then((response) => {

                this.setState({
                    loading: false,
                    sysid: response.data.sysId,
                    title: response.data.title,
                    description: response.data.description,
                    serialno: response.data.serialno.toUpperCase(),
                    devicetoken: response.data.devicetoken.toUpperCase(),
                    siteid: response.data.siteId,
                    model: response.data.model,
                });

            })
            .catch((error) => {

            });


    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }


    async onSubmit(e) {
        //debugger;

        e.preventDefault();

        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        const data = {
            sysid: this.state.sysid
        };

        console.log(data);

        axios.delete('api/masterdevices/' + this.props.match.params.id, data, {
            headers: headers
        })
            .then((response) => {
                
                if (response.data.status) {
                    this.props.history.push('/master/devices')
                } else {
                    this.setState(
                        (state) => ({
                            modalTitle: response.data.result,
                            modalMessage: response.data.message
                        }),
                        () => {
                            this.setState(
                                (state) => ({
                                    modalState: true,
                                }),
                                () => {

                                }
                            );
                        }
                    );

                }

            })
            .catch((error) => {
                console.log(error);
            })

    }

    render() {
        const { sitemodels, modalState, modalTitle, modalMessage } = this.state;

        return (
            <>
                <CCard
                   
                >
                    <CCardHeader
                        color="danger"
                        textColor="white"
                    >
                        <h4 id="traffic" className="card-title mb-0">
                            Delete Master Device : {this.state.title}
                        </h4>
                    </CCardHeader>
                    <CCardBody>
                        <CContainer fluid>
                            <CForm id="main"
                                onSubmit={this.onSubmit}
                                className="row g-3" autoComplete="off">
                                <CCol md={12}>
                                    <CLabel htmlFor="title">Title</CLabel>
                                    <CInput type="text" id="title" name="title" placeholder="(ชั้น, ห้อง, โซน)" autoComplete="off" value={this.state.title}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    title: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required maxLength="60" readOnly />
                                </CCol>
                                <CCol md={4}>
                                    <CLabel htmlFor="model">Model</CLabel>
                                    <CSelect id="model" name="model" value={this.state.model} readOnly
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    model: value,
                                                }),
                                                () => {

                                                }
                                            );
                                        }}

                                        readOnly
                                    >
                                        <option value="AR_L3">AR L3</option>
                                    </CSelect>
                                </CCol>
                                <CCol md={4}>
                                    <CLabel htmlFor="serialno">Serial No</CLabel>
                                    <CInput id="serialno" name="serialno" placeholder="Serial Number ด้านหลังกล่อง (ตัวอย่าง: ARLXXXXX)" autoComplete="off" value={this.state.serialno}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    serialno: value.replace(/\s/g, '').toUpperCase(),
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required readOnly />
                                </CCol>
                                <CCol md={4}>
                                    <CLabel htmlFor="devicetoken">Device Token</CLabel>
                                    <CInput id="devicetoken" name="devicetoken" placeholder="Device Token ด้านหลังกล่อง (ตัวอย่าง: XXXXXX9999YY777)" autoComplete="off" value={this.state.devicetoken}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            this.setState(
                                                (state) => ({
                                                    devicetoken: value.replace(/\s/g, '').toUpperCase(),
                                                }),
                                                () => {

                                                }
                                            );
                                        }}
                                        required readOnly />
                                </CCol>
                              
                                <CCol xs={12}>
                                    <CButton type="submit" className="btn btn-outline-danger">Delete</CButton>
                                    <CButton type="button" className="btn btn-outline-light ml-2" onClick={(e) => { this.props.history.push('/master/devices'); }}>Cancel</CButton>
                                </CCol>
                            </CForm>
                        </CContainer>
                    </CCardBody>
                </CCard>

                <CModal
                    show={modalState}
                    onClick={(e) => {
                        this.setState((state) => ({
                            modalState: false,
                        }));
                    }}
                >
                    <CModalHeader closeButton>
                        <CModalTitle>{modalTitle}</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        {modalMessage}
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="primary"
                            onClick={(e) => {
                                this.setState((state) => ({
                                    modalState: false,
                                }));
                            }}
                        >
                            OK
                        </CButton>
                    </CModalFooter>
                </CModal>
            </>
        );
    }
}