import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import { CCard, CCardBody, CCardHeader, CLink,  CSpinner } from '@coreui/react'


const axios = require("axios");

export class UsersUsers extends Component {
    static displayName = UsersUsers.name;

    constructor(props) {
        super(props);
        this.state = {
            pagemodels: [],
            isSessionExpired: false,
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        this.populateData();
    }

    componentDidMount() {
        this.populateData();
    }


    async populateData() {
        const token = await authService.getAccessToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        const url = 'api/users/';

        axios.get(url, {
            headers: headers
        })
            .then((response) => {

                this.setState({
                    loading: false,
                    pagemodels: response.data
                });
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 401) {
                    window.history.replaceState(null, null, "/Identity/Account/Login");
                    window.location.replace("/Identity/Account/Login");
                } else {
                    console.log(error);
                }
            });

    }


    static renderTable(pagemodels) { 
        return (    
            <table className='table table-bordered table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr className="bg-dark">
                        <th>#</th>
                        <th>Username</th>
       
                        <th>PhoneNumber</th>
                        <th>Devices</th>
                        <th>Sites</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {pagemodels.map((item, index) =>
                        <tr className="text-white" key={item.id}>
                            <td className="text-white">{index + 1}</td>
                            <td className="text-white">
                            
                                    <span className="">{item.userName}</span>
                           
                            </td>
                          
                            <td className="text-white">{item.phoneNumber}</td>
                            <td className="text-white">

                                <ul>
                                {item.devices.map((item, index) =>
                                    <li>
                                        <CLink className="alink" to={"/master/devices/" + item.id} target="_blank">
                                        <span className="">{item.serialNo}</span>
                                    </CLink>
                                    </li>
                                )}
                                </ul>

                        

                            </td>
                            <td className="text-white">

                                <ul>
                                    {item.sites.map((item, index) =>
                                        <li>
                                            <CLink className="alink" to={"/sites/" + item.id} target="_blank">
                                                <span className="">{item.title}</span>
                                            </CLink>
                                        </li>
                                    )}
                                </ul>

                            </td>
                            <td className="text-white">
                                {item.isAdministrator ? (
                                    <span className="badge bg-danger">Administrator</span>
                                ) : (<></>)}
                            </td>
                           
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        const { isSessionExpired } = this.state;
        let contents = this.state.loading
            ? <div><em>Loading...</em>  <CSpinner color="warning" size="xl" /></div>
            : UsersUsers.renderTable(this.state.pagemodels);

        return (
            isSessionExpired ? <div>Session expired</div>
                :
            <div>
                <CCard className="">
                        <CCardHeader color="success">
                            <h4 id="traffic" className="card-title mb-0">Users
                            </h4>
                    </CCardHeader>
                    <CCardBody>
                        {contents}
                    </CCardBody>
                </CCard>
            </div>
        );
    }
}