import React from "react";
import styles from "./styles/button.module.css";
import { CButton, CImg } from "@coreui/react";
import imgON from "../../../assets/imgs/mistmaker_on.gif";
import imgOFF from "../../../assets/imgs/mistmaker_off.png";

const ButtonMistmaker = ({
    children,
    onClick,
    labelColor,
    disabled,
    status,
    tabselect,
    style,
    mode,
    titleprimary,
    titlesecondary,
    ...props
}) => {
    return (
        <CButton className="m-2" variant="ghost" onClick={onClick}>
            <div>
                <CImg
                    src={status === 1 ? imgON : imgOFF}
                    width="100%"
                    fluid
                    style={{ maxWidth: "100px" }}
                    className={
                        status === 1
                            ? styles.statusonmistmaker + " mb-2"
                            : styles.statusoffmistmaker + " mb-2"
                    }
                />
            </div>

            <strong
                className={tabselect === 1 ? styles.textactive : styles.textinactive}
            >
                {titleprimary != "" ? titleprimary : "Mist Maker"}
                
            </strong>
        </CButton>
    );
};

export default ButtonMistmaker;
