export default [
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: "cil-speedometer",
  //   // badge: {
  //   //   color: 'info',
  //   //   text: 'NEW',
  //   // }
  // },
    {
        _tag: "CSidebarNavItem",
        name: "Home",
        to: "/",
        icon: "cil-home",
        // badge: {
        //   color: 'info',
        //   text: 'NEW',
        // },
    },
    {
        _tag: "CSidebarNavItem",
        name: "Devices",
        to: "/devices",
        icon: "cil-router",
        // badge: {
        //   color: 'info',
        //   text: 'NEW',
        // },
    },
    {
        _tag: "CSidebarNavItem",
        name: "Sites",
        to: "/sites",
        icon: "cil-sitemap",
        // badge: {
        //   color: 'info',
        //   text: 'NEW',
        // },
    },
    //{
    //    _tag: "CSidebarNavItem",
    //    name: "Counter",
    //    to: "/counter",
    //    icon: "cil-sitemap",
    //     badge: {
    //       color: 'info',
    //       text: 'NEW',
    //     },
    //},

  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Sites',
  //   to: '/sites/12314234',
  //   icon: 'cil-sitemap',
  //   // badge: {
  //   //   color: 'info',
  //   //   text: 'NEW',
  //   // },
  // },

  //{
  //  _tag: "CSidebarNavDropdown",
  //  name: "Settings",
  //  route: "/settings",
  //  icon: "cil-settings",
  //  _children: [
  //    {
  //      _tag: "CSidebarNavItem",
  //      name: "Users",
  //      to: "/administrators/users",
  //    },
  //    {
  //      _tag: "CSidebarNavItem",
  //      name: "Devices",
  //      to: "/administrators/devices",
  //    },
  //    // {
  //    //   _tag: 'CSidebarNavItem',
  //    //   name: 'Cards',
  //    //   to: '/base/cards',
  //    // },
  //    // {
  //    //   _tag: 'CSidebarNavItem',
  //    //   name: 'Carousel',
  //    //   to: '/base/carousels',
  //    // },
  //    // {
  //    //   _tag: 'CSidebarNavItem',
  //    //   name: 'Collapse',
  //    //   to: '/base/collapses',
  //    // },
  //  ],
  //},
];
